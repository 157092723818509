import agent from './agent';
import { AxiosResponse } from 'axios';
import { PhoneNumber, GameStats, MyResponse, Pagination, UserEntity, Game, Spot, Business, ShippingOrder, CouponOwnership, QRCodeUsageHistory, QRCodeEntityStats, Translations, Image, SendNotificationToUserResponse, Location, GameBanner, CouponBanner, MajorBanner, SpotBanner, RewardBundle, CreateCouponParameter, CouponForAdmin, QRCodeEntity, Coupon, SpotRelatedIds, ArticleInput, Article, PrizeBanner, Prize, Tag, Website, CreditReward, RuleCondition, ScanRule, ClientTask, QRPeriod } from './models';
import { AMResp } from './helpermodels';
import { PaginateReq, searchReq } from '../commontypes';
import { MixedBanner } from '.';

export const patchQRPeriod = async(id: number|string, args: any): Promise<AMResp<QRPeriod>> => {
    return agent.patch(`/admin/qr-period/${id}`, {
        ...args
    })
}


export const patchScanRule = async(id: number|string, args: any): Promise<AMResp<ScanRule>> => {
    return agent.patch(`/admin/scan-rule/${id}`, {
        ...args
    })
}

export const patchMixedBanner = async(id: number|string, args: any): Promise<AMResp<MixedBanner>> => {
    return agent.patch(`/admin/mixed-banner/${id}`, {
        ...args
    })
}

export const patchRuleCondition = async(id: number|string, args: any): Promise<AMResp<RuleCondition>> => {
    return agent.patch(`/admin/rule-condition/${id}`, {
        ...args
    })
}

export const createPrize = async(args: any): Promise<AMResp<Prize>> => {
    return agent.post(`/admin/prize`, {
        ...args
    })
}

export const createScanRule = async(args: any): Promise<AMResp<ScanRule>> => {
    return agent.post(`/admin/scan-rule`, {
        ...args
    })
}

export const createMixedBanner = async(args: any): Promise<AMResp<MixedBanner>> => {
    return agent.post(`/admin/mixed-banner`, {
        ...args
    })
}

export const createRuleCondition = async(args: any): Promise<AMResp<RuleCondition>> => {
    return agent.post(`/admin/rule-condition`, {
        ...args
    })
}

export const createTag = async(args: {value: String}): Promise<AMResp<Tag>> => {
    return agent.post(`/admin/tag`, {
        ...args
    })
}

export const createArticle = async(args: ArticleInput): Promise<AMResp<Article>> => {
    return agent.post(`/admin/article`, {
        ...args
    })
}

export const getSpotRelatedIds = async(args: {spotId: string | number}): Promise<AMResp<SpotRelatedIds>> => {
    return agent.get(`/admin/spot-related-ids`, {
        params: {
            spotId: args.spotId
        }
    })
}

export const patchQRCodeEntity = async(qrCodeEntityId: number | string, args: any): Promise<AMResp<QRCodeEntity>> => {
    return agent.patch(`/admin/qr-code-entity/${qrCodeEntityId}`, {
        ...args
    })
}

export const patchGameBanner = async(gameBannerId: any, args: any): Promise<AMResp<GameBanner>> => {
    return agent.patch(`/admin/game-banner/${gameBannerId}`, {
        ...args
    })
}

export const patchPrizeBanner = async(prizeBannerId: any, args: any): Promise<AMResp<PrizeBanner>> => {
    return agent.patch(`/admin/prize-banner/${prizeBannerId}`, {
        ...args
    })
}

export const patchCouponBanner = async(couponBannerId: any, args: any): Promise<AMResp<CouponBanner>> => {
    console.log('Coupon banner', couponBannerId, args)
    return agent.patch(`/admin/coupon-banner/${couponBannerId}`, {
        ...args
    })
}

export const patchSpotBanner = async(spotBannerId: any, args: any): Promise<AMResp<SpotBanner>> =>  {
    console.log('patch spot banner', spotBannerId, args)
    return agent.patch(`/admin/spot-banner/${spotBannerId}`, {
        ...args
    })
}

export const patchMajorBanner = async(majorBannerId: any, args: any): Promise<AMResp<MajorBanner>> => {
    console.log('patch major banner', majorBannerId, args)
    return agent.patch(`/admin/major-banner/${majorBannerId}`, {
        ...args
    })
}

export const patchCoupon = async(couponId: any, args: any): Promise<AMResp<Coupon>> => {
    console.log('patch coupon', args);
    return agent.patch(`/admin/coupon/${couponId}`, {
        coupon: { ...args }
    })
}

export const patchSpot = async(args: any): Promise<AMResp<Spot>> => {
    console.log('patch Spot', args)
    return agent.patch(`/admin/spot/${args.spotId}`, {
        ...args
    })
}

export const removeTagsFromQRCodeEntity = async(args: {id: number|string, tagIds: Array<number>|Array<string> }): Promise<AMResp<QRCodeEntity>> => {
    return agent.delete(`/admin/qr-code-entity/tag`, {
        data: {
            id: args.id,
            tagIds: args.tagIds
        }
    })
}

export const addTagToQRCodeEntity = async(args: {qrCodeEntityId: number|string, tagIds: Array<number>|Array<string>, tagValues: Array<string>}): Promise<AMResp<Spot>> => {
    return agent.post(`/admin/add-tag-to-qr-code-entity`, {
        qrCodeEntityId: args.qrCodeEntityId,
        tagIds: args.tagIds,
        tagValues: args.tagValues
    })
}

export const removeTagsFromSpot = async(args: {spotId: number|string, tagIds: Array<number>|Array<string>}): Promise<AMResp<Spot>> => {
    return agent.delete(`/admin/spot/tag`, {
        data: {
            spotId: args.spotId,
            tagIds: args.tagIds
        }
    })
}

export const addTagToSpot = async(args: {spotId: number|string, tagIds: Array<number>|Array<string>, tagValues: Array<string>}): Promise<AMResp<Spot>> => {
    return agent.post(`/admin/add-tag-to-spot`, {
        spotId: args.spotId,
        tagIds: args.tagIds,
        tagValues: args.tagValues
    })
}

export const getQRCodeEntityById = async(args: {qrCodeEntityId: string | number}): Promise<AMResp<QRCodeEntity>> => {
    return agent.get(`/admin/qr-code-entity/${args.qrCodeEntityId}`, {

    })
}

export const fuzzySearchBusiness = async(args: searchReq): Promise<AMResp<Pagination<Business>>> => {
    return agent.get('/admin/business/fuzzy-search', {
        params: {
            ...args
        }
    })
}

export const fuzzySearchSpot = async(args: searchReq): Promise<AMResp<Pagination<Spot>>> => {
    return agent.get('/admin/spot/fuzzy-search', {
        params: {
            ...args
        }
    })
}

export const fuzzySearchUser = async(args: searchReq): Promise<AMResp<Pagination<UserEntity>>> => {
    return agent.get('/admin/user/fuzzy-search', {
        params: {
            ...args
        }
    })
}


export const getMixedBannerPage = async(args: PaginateReq): Promise<AMResp<Pagination<MixedBanner>>> => {
    return agent.get('/admin/paginated-mixed-banner', {
        params: {
            ...args
        }
    })
}

export const getClientTaskPage = async(args: PaginateReq): Promise<AMResp<Pagination<ClientTask>>> => {
    return agent.post('/admin/client-task-in-page', {
        ...args
    });
}

export const getPaginatedCreditReward = async(args: PaginateReq): Promise<AMResp<Pagination<CreditReward>>> => {
    return agent.get('/admin/paginated-credit-reward', {
        params: args
    })
}

export const getPaginatedPrize = async(args: PaginateReq): Promise<AMResp<Pagination<Prize>>> => {
    return agent.get('/admin/paginated-prize', {
        params: args
    })
}

export const getPaginatedPrizeBanner = async(args: PaginateReq): Promise<AMResp<Pagination<PrizeBanner>>> => {
    return agent.get('/admin/paginated-prize-banner', {
        params: args
    })
}

export const getPaginatedQRCodeEntity = async(args: PaginateReq): Promise<AMResp<Pagination<QRCodeEntity>>> => {
    return agent.get('/admin/paginated-qr-code-entity', {
        params: args
    })
}

export const getPaginatedCoupon = async(args: PaginateReq): Promise<AMResp<Pagination<Coupon>>> => {
    return agent.get('/admin/paginated-coupon', {
        params: args
    })
}

export const getPaginatedRewardBundle = async(args: PaginateReq): Promise<AMResp<Pagination<RewardBundle>>> => {
    return agent.get('/admin/paginated-reward-bundle', {
        params: args
    })
}

export const getPaginatedSpotBanner = async(args: PaginateReq): Promise<AMResp<Pagination<SpotBanner>>> => {
    return agent.get('/admin/paginated-spot-banner', {
        params: args
    })
}

export const getPaginatedMajorBanner = async(args: PaginateReq): Promise<AMResp<Pagination<MajorBanner>>> => {
    return agent.get('/admin/paginated-major-banner', {
        params: args
    })
}

export const getPaginatedCouponBanner = async(args: PaginateReq): Promise<AMResp<Pagination<CouponBanner>>> => {
    return agent.get('/admin/paginated-coupon-banner', {
        params: args
    })
}

export const getPaginatedGameBanner = async(args: PaginateReq): Promise<AMResp<Pagination<GameBanner>>> => {
    return agent.get('/admin/paginated-game-banner', {
        params: args
    })
}

export const getPaginatedPhoneNumber = async(args: PaginateReq): Promise<AMResp<Pagination<PhoneNumber>>> => {
    return agent.get('/admin/paginated-phone-number', {
        params: args
    })
}

export const getPaginatedTag = async(args: PaginateReq): Promise<AMResp<Pagination<Tag>>> => {
    return agent.get('/admin/paginated-tag', {
        params: args
    })
}

export const createQRCodeEntity = async(args: {
    qrData? : string,
    rewardBundleId: number,
    startTime: string,
    endTime: string,
    active: boolean,
    spotId: number,
    tagIds: Array<string> | Array<number>
}): Promise<AMResp<QRCodeEntity>> => {
    return agent.post('/admin/qr-code-entity', {
        sQRPeriods: [
            {
                startTime: args.startTime,
                endTime: args.endTime
            }
        ],
        qrData: args.qrData,
        rewardBundleId: args.rewardBundleId,
        startTime: args.startTime,
        endTime: args.endTime,
        active: args.active,
        spotId: args.spotId,
        tagIds: args.tagIds
    });
}

export const createCoupon = async(arg: CreateCouponParameter): Promise<AMResp<CouponForAdmin>> => {
     return agent.post('/admin/coupon', {
         coupon: arg
     })
}

export const createRewardBundle = async(creditRewardId: number|undefined, couponIds: number[], actionUrl: string|undefined, toBeCollected: boolean): Promise<AMResp<RewardBundle>> => {
    return agent.post('/admin/reward-bundle-admin-portal', {
        creditRewardId,
        couponIds,
        actionUrl,
        toBeCollected
    })
}

export const createBusiness = async(names: Translations, descriptions: Translations, businessIconId: number|undefined, alias: string|undefined, websites: Array<Website>): Promise<AMResp<Business>> => {
    return agent.post('/admin/business', {
        names,
        descriptions,
        businessIconId,
        alias,
        websites
    })
}

export const createPrizeBanner = async(args: {prizeId: number| String, imageId: number|string, tag: string}): Promise<AMResp<PrizeBanner>> => {
    return agent.post('/admin/prize-banner', {
        ...args
    })
}

export const createSpotBanner = async(spotId: number, imageId: number, isCurrent: boolean): Promise<AMResp<SpotBanner>> => {
    return agent.post('/admin/spot-banner', {
        spotId,
        imageId,
        isCurrent
    })
}

export const createMajorBanner = async(imageId: number, active: boolean, url?: string): Promise<AMResp<MajorBanner>> => {
    return agent.post('/admin/major-banner', {
        imageId,
        active,
        url
    })
}

export const createCouponBanner = async(couponId: number, imageId: number, isCurrent: boolean): Promise<AMResp<CouponBanner>> => {
    return agent.post('/admin/coupon-banner', {
        couponId,
        imageId,
        isCurrent
    })
}

export const createGameBanner = async(published: boolean, gameId: number, imageId: number, url: string): Promise<AMResp<GameBanner>> => {
    return agent.post('/admin/game-banner', {
        published,
        gameId,
        imageId,
        url
    })
}

export const createPhoneNumber = async(countryCode: string, areaCode: string | undefined, subscriberNumber: string): Promise<AMResp<PhoneNumber>> => {
    return agent.post('/admin/phone-number', {
        countryCode,
        areaCode,
        subscriberNumber
    })
}

export const createLocation = async(latitude: string, longitude: string, names: Translations | undefined, descriptions: Translations | undefined): Promise<AMResp<Location>> => {
    return agent.post('/admin/location', {
        latitude,
        longitude,
        names,
        descriptions
    })
}

export const sendNotificationToUsers = async(userIds: number[] |string [], titleTranslations: Translations, bodyTranslations: Translations, routeUrl: string = 'https://app.scannow.gg/home', imageUrl: string|null|undefined, isAll: boolean = false): Promise<AMResp<SendNotificationToUserResponse>> => {
    return agent.post('/admin/send-fcm-notification-to-users', {
        userIds,
        titleTranslations,
        bodyTranslations,
        routeUrl,
        imageUrl,
        isAll
    })
}

export const uploadImage = async(slugifiedName: string | undefined, names: Translations |undefined, objKey: string, image: File): Promise<AMResp<Image>> => {

    let form = new FormData()
    if (slugifiedName != null)
        form.set(
            'slugifiedName', slugifiedName
        )
    if (names != null) {
        form.set(
            'names', JSON.stringify(names)
        )
    }
    form.set('objKey', objKey)
    form.set('image', image)
    return agent.post('/admin/upload/image', form)
}

export const getBusinessById = async(businessId: number|string): Promise<AMResp<Business>> => {
    return agent.get(`/admin/business/${businessId}`)
}

export const patchBusiness = async(businessId: number|string, args: {alias?: string, websites: Array<Website>}): Promise<AMResp<Business>> => {
    return agent.patch(`/admin/business/${businessId}`, {
        ...args
    })
}

export const patchPrize = async(prizeId: any, args: any) : Promise<AMResp<Prize>> => {
    return agent.patch(`/admin/prize/${prizeId}`, {
        ...args
    })
}

export const patchShippingOrder = async(shippingOrderId: number|string, status: number|string): Promise<AMResp<ShippingOrder>> => {
    return agent.patch(`/admin/shipping-order/${shippingOrderId}`, {
        status
    })
}

export const getPaginatedShippingOrders = async(page: number|string, perPage: number| string, filters: String[]): Promise<AMResp<Pagination<ShippingOrder>>> => {
    return agent.post(`/admin/paginated-shipping-order`, {
        page,
        perPage,
        filters
    })
}

export const getQRCodeEntityStats = async(qrCodeEntityId: number | string) : Promise<AMResp<QRCodeEntityStats>> => {
    return agent.get(`/admin/qr-code-entity/${qrCodeEntityId}/stats`)
}

export const getSpotById = async(spotId: number | string): Promise<AMResp<Spot>> => {
    return agent.get(`/admin/spot/${spotId}`)
}

export const getClientTaskByExtId = async(taskExtId: string): Promise<AMResp<ClientTask>> => {
    return agent.get(`/admin/client-task/${taskExtId}`)
}


export const getGameStats = async(gameId: number|string): Promise<AMResp<GameStats>> => {
    return agent.get(`/admin/game/${gameId}/stats`)
}

export const getGameById = async(id: number|string): Promise<AMResp<Game>> => {
    return agent.get(`/admin/game/${id}`)
}

export const getPaginatedUserQRCodeUsageHistory = async(userId: number|string, page: number|string, perPage: number|string): Promise<AMResp<Pagination<QRCodeUsageHistory>>> => {
    return agent.get(`/admin/user/${userId}/paginated-qr-code-usage-history`, {
        params: {
            page,
            perPage
        }
    })
}

export const getPaginatedUserCouponOwnerships = async(userId: number|string, page: number|string, perPage: number|string): Promise<AMResp<Pagination<CouponOwnership>>> => {
    return agent.get(`/admin/user/${userId}/paginated-coupon-ownership`, {
        params: {
            page,
            perPage
        }
    })
}

export const getPaginatedUserShippingOrders = async(userId: number|string, page: number|string, perPage: number|string): Promise<AMResp<Pagination<ShippingOrder>>> => {
    return agent.get(`/admin/user/${userId}/paginated-shipping-order`, {
        params: {
            page,
            perPage
        }
    })
}

export const getUserById = async(id: number): Promise<AMResp<UserEntity>> => {
    return agent.get(`/admin/user/${id}`)
}

export const getPaginatedGeographicLocation = async(page: number, perPage: number): Promise<AMResp<Pagination<Location>>> => {
    return agent.get('/admin/paginated-geographic-location', {
        params: {
            page: page,
            perPage: perPage
        }
    }) 
}

export const getPaginatedBusiness = async(page: number, perPage: number): Promise<AMResp<Pagination<Business>>> => {
    return agent.get('/admin/paginated-business', {
        params: {
            page: page,
            perPage: perPage
        }
    }) 
}

export const getPaginatedSpots = async (page: number, perPage: number): Promise<AxiosResponse<MyResponse<Pagination<Spot>>>> => {
    return agent.get('/admin/paginated-spot', {
        params: {
            page: page,
            perPage: perPage
        }
    }) 
}

export const getPaginatedUsers = async (page: number, perPage: number, filters: string[]=[]): Promise<AxiosResponse<MyResponse<Pagination<UserEntity>>>> => {
    return agent.post('/admin/get-paginated-user', {
        page,
        perPage,
        filters
    })
} 

export const getPaginatedGames = async (page: number, perPage: number): Promise<AxiosResponse<MyResponse<Pagination<Game>>>> => {
    return agent.get('/admin/paginated-game', {
        params: {
            page,
            perPage
        }
    })
}

export const reviewClientTask = async (taskExtId: string, action: 'accept'|'reject'): Promise<AxiosResponse<MyResponse<ClientTask>>> => {
    return agent.post(`/admin/client-task/review/${taskExtId}`, {
        action
    })
}