import { Typography, withStyles, Button } from '@material-ui/core';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getQRCodeEntityById } from '../../api/admin';
import { QRCodeEntity, Tag } from '../../api/models';
import QRCodeEntityDisplay from '../../components/qrcodeentity';
import { getColumns as getQRCodeEntityColumns } from '../../components/qrcodeentity/columns';
import { getDefaultControlledConfig as getQRCodeConfig } from '../../components/qrcodeentity/defaultconfig';
import TagDisplay from '../../components/tagdisplay';
import { getColumns as getTagColumns } from '../../components/tagdisplay/columns';
import getTagConfig from '../../components/tagdisplay/defaultconfig';
import { commonFeatureActions } from '../../features/commonfeature';
import { handleApiError, isNumber } from '../../utils';
import { ChangeSet } from '@devexpress/dx-react-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import QRPeriodDisplay from '../../components/qrperiod'
import { getDefaultControlledConfig as getQRPeriodConfig } from '../../components/qrperiod/defaultconfig'
import ScanRuleDisplay from '../../components/scanruledisplay/ScanRuleDisplay'
import { getColumns as getScanRuleColumns } from '../../components/scanruledisplay/columns'
import { getDefaultControlledConfig as getScanRuleConfig } from '../../components/scanruledisplay/defaultconfig'

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,      
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            removeTagsFromQRCodeEntity: commonFeatureActions.removeTagsFromQRCodeEntityRequest,
            addTagsToQRCodeEntity: commonFeatureActions.addTagToQRCodeEntityRequest
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    match: match<{qrCodeEntityId: string;}>,
    container: string,
    qrCodeId?: number|string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type States = {
    qrCodeEntity?: QRCodeEntity,
}

class QRCodeEntityDetailPage extends React.Component<RealProps, States> {

    
    componentDidMount() {
        this.props.updateAppBarConfig({ title: 'QR Code Entity' })
        if (this.idIsOk()) {
            this.fetchAll()
        } else {
            alert("Invalid QR Code Entity Id")
            this.props.history.push('/')
        }
    }

    getQRCodeEntityId = () => {
        
        if (this.props.qrCodeId != null) {
            return this.props.qrCodeId
        } else {
            return this.props.match.params.qrCodeEntityId
        }
    }

    idIsOk = () => {
        // return true
        return isNumber(this.props.match.params.qrCodeEntityId) || isNumber(this.props.qrCodeId)
    }

    fetchAll = () => {
        this.fetchQRCodeEntity()
    }

    fetchQRCodeEntity = () => {
        getQRCodeEntityById({qrCodeEntityId: this.getQRCodeEntityId()}).then((resp) => {
            const qrCodeEntity = resp.data.message
            if (qrCodeEntity != null) {
                this.setState({
                    qrCodeEntity
                })
            }
        }).catch((error) => {
            handleApiError(error, this.props.showMessage)
        })
    }

    getQRCodeArray =() : Array<QRCodeEntity> => {
        let state = this.getState_()
        const qrCodeEntity = state.qrCodeEntity
        if (qrCodeEntity == null || qrCodeEntity == undefined) {
            return []
        }
        return [qrCodeEntity]
    }

    getState_ = ()=> {
        let state = this.state
        if (state == null) {
            state = {}
        }
        return state
    }

    getTagArray = (): Array<Tag> => {
        let state = this.getState_()

        const qrCodeEntity = state.qrCodeEntity
        if (qrCodeEntity == null || qrCodeEntity == undefined) {
            return []
        }
        return qrCodeEntity.tags
    }

    onTagCommitEditing = (changeSet: ChangeSet) => {
        const qrCodeEntity = this.state.qrCodeEntity as unknown as QRCodeEntity
        if (qrCodeEntity == null) {
            return;
        }
        const { added, deleted } = changeSet
        console.log('onTag Commit Editing added', added, 'deleted', deleted)
        if (deleted != null) {
            deleted.forEach( raw => {
                let tagToBeRemove = qrCodeEntity.tags[raw as number]
                // TODO remove something
                console.log('Deleted raw', tagToBeRemove)
                this.props.removeTagsFromQRCodeEntity({
                    id: qrCodeEntity.id,
                    tagIds: [tagToBeRemove.id]
                })
            })
        }
        if (added != null) {
            added.forEach( raw => {
                let { id, value } = raw;
                if (value != null) {
                    this.props.addTagsToQRCodeEntity({
                        qrCodeEntityId: qrCodeEntity.id,
                        tagIds: [],
                        tagValues: [ value ]
                    })
                } else {
                    this.props.addTagsToQRCodeEntity({
                        qrCodeEntityId: qrCodeEntity.id,
                        tagIds: [
                            id
                        ],
                        tagValues: []
                    })
                }
            })
        }
    }

    getQRPeriodArray = () => {
        let state = this.getState_()

        const qrCodeEntity = state.qrCodeEntity
        if (qrCodeEntity == null || qrCodeEntity == undefined) {
            return []
        }
        return qrCodeEntity.qrPeriods
    }

    getScanRuleArray = () => {
        let state = this.getState_()

        const qrCodeEntity = state.qrCodeEntity
        if (qrCodeEntity == null || qrCodeEntity == undefined) {
            return []
        }
        return qrCodeEntity.scanRules
    }

    render() {
        const { container, 
            history, 
            showMessage
        } = this.props;
        let qrCodeEntityId = this.getQRCodeEntityId()
        return (
            <div className={container}>
                <Typography variant='h6'>
                    QR Code Entity
                </Typography>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.fetchAll}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>
                <QRCodeEntityDisplay
                    {...getQRCodeConfig(history)}
                    columns={getQRCodeEntityColumns(history, undefined, false, false)}
                    data={this.getQRCodeArray()}
                    showEdit
                    />
                <QRPeriodDisplay
                    {...getQRPeriodConfig(history)}
                    data={this.getQRPeriodArray()}
                    showEdit
                    />

                <ScanRuleDisplay
                    {...getScanRuleConfig(history)}
                    qrCodeEntityId={qrCodeEntityId}
                    showEdit
                    showAdd
                    columns={getScanRuleColumns(history, showMessage, true, false)}
                    data={this.getScanRuleArray()}
                />

                <TagDisplay
                    {...getTagConfig(history)}
                    showAdd
                    showDelete
                    columns={getTagColumns(history)}
                    data={this.getTagArray()}
                    onCommitEditing={this.onTagCommitEditing}
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(QRCodeEntityDetailPage)))