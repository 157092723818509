import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureSelectors, commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Button, TextField, InputAdornment, IconButton, Chip, Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Spot, Pagination } from '../../api/models'
import SpotDisplay from '../../components/spotdisplay/SpotDisplay';
import getColumns from '../../components/spotdisplay/columns'
import qs from 'querystring'
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { fuzzySearchSpot } from '../../api/admin';



const mapStateToProps = (state: MyTypes.RootState) => ({
    fetchedData: commonFeatureSelectors.getSpotsData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getPaginatedSpotsRequest: commonFeatureActions.getPaginatedSpotsRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            showMessage: commonFeatureActions.showMessage
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type MyState = {
    page: number,
    perPage: number,
    searchMode: boolean,
    searchTerm: string,
    searchResult?: Pagination<Spot>
}

class SpotPage extends React.Component<RealProps, MyState> {
    state = {
        page: 0,
        perPage: 10,
        searchMode: false,
        searchTerm: ''
    }

    searchRef: React.RefObject<HTMLInputElement>;

    constructor(props: RealProps) {
        super(props);
        this.searchRef  = React.createRef();
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'Spot'
        })
        this.getNext()

        let query = qs.parse(this.props.location.search)
        console.log("search params", this.props.location.search, query)
    }

    getNext = ()=> {
        console.log(`SpotPage getNext: page: ${this.state.page} perPage: ${this.state.perPage}`)
        const { searchMode, searchTerm } = this.state;

        if (!searchMode) {
            this.props.getPaginatedSpotsRequest(
                {
                    page: this.state.page + 1,
                    perPage: this.state.perPage
                }
            )
        } else {
            fuzzySearchSpot({
                page: this.state.page + 1,
                perPage: this.state.perPage,
                word: searchTerm
            }).then((resp) => {
                this.setState({
                    searchResult: resp.data.message
                })
            })
        }
    }

    onChangeCurrentPage = (currentPage: number) => {
        console.log('onChangeCurrentPage currentPage', currentPage)
        this.setState({
            page: currentPage
        }, ()=> {
            this.getNext()
        })
        
    }

    onPageSizeChange = (pageSize: number) => {
        console.log('onPageSizeChange pageSize', pageSize)
        this.setState({
            perPage: pageSize
        }, ()=> {
            this.getNext()
        })
        
    }

    searchIt = () => {
        const searchValue = this!!.searchRef!!.current!!.value
        console.log('Search it !!', searchValue)
        // TODO call api here
        if (searchValue == '') {
            this.props.showMessage({
                message: 'Search Term cannot be empty'
            })
            return
        }
        this.setState({
            page: 0,
            searchMode: true,
            searchTerm: searchValue
        }, () => {
            this.getNext()
        })
        
    }

    onRemoveSearchTerm = () => {
        this.setState({
            page: 0,
            searchMode: false,
            searchTerm: ''
        }, ()=> {
            this.getNext()
        })
    }

    render() {
        const { container, fetchedData, history } = this.props;
        const { searchMode, searchResult, searchTerm, page, perPage } = this.state as MyState
        let data : Spot[] = []
        let totalCount = undefined
        if (searchMode && searchResult != null) {
            data = searchResult!!.items!!
            totalCount = searchResult!!.total
        } else if (fetchedData.cached != null && fetchedData.cached.items != null) {
            data = fetchedData.cached.items
            totalCount = fetchedData.cached.total
        }
        return (
            <div className={container}>
                
                <div style={{
                    marginTop: '1vw',
                    marginBottom: '1vw'
                }}>
                    <Button
                    style={{
                        marginBottom: '10px'
                    }}
                    onClick={this.getNext}
                    variant="contained"
                    color="primary"
                    ><RefreshIcon /> Refresh </Button>
                    
                    <TextField
                        style={{marginLeft: '1vw'}}
                        label="Search"
                        id="searchIt"
                        inputRef={this.searchRef}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={this.searchIt}
                                    >
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }}
                        >

                    </TextField>
                    {
                        searchMode ? 
                        (
                        <Chip
                            label={searchTerm}
                            clickable
                            color="primary"
                            onDelete={this.onRemoveSearchTerm} />
                            ) 
                        :
                        null
                    }
                </div>
                
                <SpotDisplay 
                    page={page}
                    perPage={perPage}
                    isLoading={fetchedData.loading}
                    columns={getColumns(history)}
                    onCurrentPageChange={this.onChangeCurrentPage}
                    onPageSizeChange={this.onPageSizeChange}
                    totalCount={totalCount}
                    data={data}/>
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(SpotPage)))