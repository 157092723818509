import { AxiosError } from 'axios';
import { ErrorObj, MyResponse, Image } from '../api';
import { Message } from '../features/commonfeature/models';
import util from 'util'
import { MyColumn } from '../common/models';
import React from 'react';

export function isNumber(value: string | number | undefined): boolean
{
   return ((value != null) && value != '' && !isNaN(Number(value.toString())));
}

export function notNull <T>(value: Array<T>|undefined| null): Array<T> {
   if (value == null) {
      return []
   }
   return value
}

export function wrapIntoArray <T>(value: T | undefined | null) {
   if (value == null || value == undefined) {
      return []
   }
   return [value]
}

export function prop<T, K extends keyof T>(obj: T, key: K) {
   return obj[key];
}


export function isAxiosError(arg: any): arg is AxiosError {
   return arg.config !== undefined
}

export function isErrorObj(arg: any): arg is ErrorObj {
   return arg.errorCode !== undefined && arg.errorMessage !== undefined
}

export function handleApiError(error: any, showMessage: (message: Message) => any) {
   let hasDoneSomething = false
   if (isAxiosError(error) && error.message !== 'Network Error') {
      console.log(error.message)
      console.log(util.inspect(error))
      let axiosError = error as AxiosError
      if (axiosError.response != null) {
         let errorObj = (axiosError.response.data as MyResponse<any>).error
         if (errorObj != null) {
            showMessage({
               message: errorObj.errorMessage
            })
            hasDoneSomething = true
         }
      }
      
   }
   
   if (!hasDoneSomething) {
      showMessage({
         message: 'Unknown Error'
      })
   }
}

/**
 * 
 * @param error 
 */
export function handleApiErrorForStream(error: any): Message {
   if (isAxiosError(error) && error.message !== 'Network Error') {
      console.log(error.message)
      console.log(util.inspect(error))
      let axiosError = error as AxiosError
      if (axiosError.response != null) {
         let errorObj = (axiosError.response.data as MyResponse<any>).error
         if (errorObj != null) {
            return {
               message: errorObj.errorMessage
            }
         }
      }
      
   }
   return {
      message: 'Unknown Error'
   }
}

export function makeImageColumns(title: string): MyColumn[] {
   const camelCasedName = `${title.split(' ').map((value, index, array) => {
      if (index > 0) {
         return value[0].toUpperCase() + value.substring(1)
      } else {
         return value[0].toLowerCase() + value.substring(1)
      }
   }).join('')}`
    return [{
       'title': `${title} Id`,
       'name': `${camelCasedName}Id`,
       
    },
    {
       'title': `${title}`,
       'name': `${title}`,
      editable: false,
       getCellValue: (row: any) => {
          let image = row[camelCasedName] as Image
          if (image != null) {
             return <a href={image.url}>image</a>
          }
       }
    }
   ]
}


export function nullStringInMapToNull(row: any) {
   for (let [k, v] of Object.entries(row)) {
      if (v == 'null') {
          row[k] = null
      }
  }
}