import { createStandardAction } from 'typesafe-actions';
import { LOGIN, LOGOUT, SET_USER_ENTITY } from './constants';
import { UserEntity } from '../../api/models'
//import { Authentication } from './models';






export const login = createStandardAction(LOGIN)<{
    emailOrAnything: string,
    password: string
}>();

export const logout = createStandardAction(LOGOUT)();

export const setUserEntity = createStandardAction(SET_USER_ENTITY)<UserEntity>();