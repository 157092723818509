import { Column } from '@devexpress/dx-react-grid';
import * as H from 'history';



export const getColumns = (history: H.History): Column[] => [
    { title: 'Id', name: 'id' },
    { title: 'Value', name: 'value'},
];

export default getColumns