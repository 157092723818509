import * as H from 'history';
import { MyColumn } from '../../common/models';
// import { Message } from '../../features/commonfeature/models'
import { IconButton } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation'
import React from 'react'
import { PayloadAC } from 'typesafe-actions';
import { Message } from '../../features/commonfeature/models';
import QRCodeEntityDisplay from './QRCodeEntityDisplay';
import QRCodeEntityDetailPage from '../../pages/qrcodeentitydetail';


// TODO we need translations 

export const getColumns = (history: H.History, showMessage: PayloadAC<"commonfeature/SHOW_MESSAGE", Message>|undefined=undefined, editMode: boolean = false, navigationDeeper: boolean = true): MyColumn[] => {
    let output: MyColumn[] = [
        

        { 'title': 'ID', name: 'id', editable: false },
        { title: 'Active', name: 'active', getCellValue: (row) => { return row.active.toString()}, editable: true},
        // { title: 'Active', name: 'active', getCellValue: (row: any) => {
        //     if (row.active == null) {
        //         return null
        //     }
        //     if (row.active) {
        //         return 'true'
        //     } else {
        //         return 'false'
        //     }
        // } },
        { title: 'Start Time', name: 'startTime' },
        { title: 'End Time', name: 'endTime' },
        { title: 'QR Data', name: 'qrData' },
        { title: 'Reward Bundle ID', name: 'rewardBundleId' },
        { title: 'QR Scan Code', name: 'QR Scan Code', getCellValue: (row: any) => {
            if (row.qrPeriods != null && row.qrPeriods.length > 0) {
                return row.qrPeriods[0].shortenUrl
            }
        }},
        { title: 'Spot Id', name: 'spotId'}
    ]

    if (navigationDeeper) {
        output.splice(0, 0, 
            { name: 'Detail', getCellValue: (row: any)=> {
                return (
                    <a target="_blank" href={`/qr-code-entity/${row.id}`}>Detail</a>
                )
            }},
            { name: 'Dialog', getCellValue: (row: any)=> {
                var a = <QRCodeEntityDetailPage qrCodeId={row['id']} container=''/>;
                return (
                    <IconButton onClick={()=> {
                        if (showMessage != null) {
                            showMessage({
                                message: 'QR Code Entity',
                                type: 'dialog',
                                component: a
                            })
                        }}}>
                            <NavigationIcon/>
                    </IconButton>
                    
                )
            }}
        )
    }

    if (editMode) {
        output.push(
            { title: 'Tag Ids', name: 'tagIds'}
        )
    }
    return output
}
