import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getColumns } from '../../components/phonenumberdisplay/columns'
import PhoneNumberDisplay from '../../components/phonenumberdisplay';
import RefreshIcon from '@material-ui/icons/Refresh';

const mapStateToProps = (state: MyTypes.RootState) => ({
    phoneNumberData: commonFeatureSelectors.getPhoneNumberData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getPaginatedPhoneNumber: commonFeatureActions.getPaginatedPhoneNumberRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class PhoneNumberPage extends React.Component<RealProps> {
    state = {
        page: 0,
        perPage: 10,
        addedRows: []
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ title: 'Phone Number' }) 
        this.getNext()
    }

    onCurrentPageChange = (currentPage: number) => {
        console.log('onChange Current Page', currentPage)
        this.setState({
            page: currentPage
        }, ()=> {
            this.getNext()
        })
    }

    getNext = () => {
        console.log('Geographic Location get Next')
        // TODO get something
        this.props.getPaginatedPhoneNumber({
            page: this.state.page + 1,
            perPage: this.state.perPage
        })
    }

    onPageSizeChange = (pageSize: number) => {
        this.setState( { perPage: pageSize }, ()=> { this.getNext() })
    }

    onAddedRowsChange = (rows: any[]) => {
        console.log('onAddedRowsChange', rows)
        
        let newArray = rows
        // newArray.push({id: 1, latitude: '1', longitude: '12'})
        this.setState({
            addedRows: newArray
        })
    }

    render() {
        const { container, history, phoneNumberData } = this.props;
        const { addedRows, page, perPage } = this.state
        console.log('addedRows from state', addedRows)
        
        return (
            <div className={container}>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.getNext}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>

                <PhoneNumberDisplay
                    showAdd={true}
                    onAddedRowsChange={this.onAddedRowsChange}
                    addedRows={this.state.addedRows}
                    onPageSizeChange={this.onPageSizeChange}
                    onCurrentPageChange={this.onCurrentPageChange}
                    columns={getColumns(history, addedRows.length > 0)}
                    data={phoneNumberData.cached != null ? phoneNumberData.cached!!.items: null}
                    totalCount={phoneNumberData.cached != null ? phoneNumberData.cached!!.total : undefined}
                    editColumnExtensions={[{columnName: 'name', editingEnabled: false}, {columnName: 'description', editingEnabled: false}]}
                    page={page}
                    perPage={perPage}
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(PhoneNumberPage)))