import React, { Fragment } from 'react';
// import { connect } from 'react-redux'
import { bindActionCreators, Dispatch} from 'redux'
import Types from 'MyTypes'
import {commonFeatureSelectors} from '../../features/commonfeature'
import { commonFeatureActions } from '../../features/commonfeature'

import { connect } from 'react-redux';
import { Dialog, DialogTitle, Button } from '@material-ui/core';
import { Message } from '../../features/commonfeature/models';
import { RouteComponentProps } from 'react-router-dom';
import QQDialog from '../qqdialog/qqdialog';


const mapStateToProps = (state: Types.RootState) => ({
    messages: commonFeatureSelectors.getMessages(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) => 
    bindActionCreators(
        {
            consumeAllMessages: commonFeatureActions.consumeAllMessages
        },
        dispatch
    )


type RealProps =  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
    messages: Array<Message>
}

class DialogHandler extends React.Component<RealProps, State> {
    state = {
        messages: ([] as Array<Message>)
    }

    doSomething() {
        // console.log('SnackbarHandler recieve props', this.props)
        let messages  = this.props.messages
        if (messages.length > 0) {
            if (messages[0].type != 'snack') {
                let msg0 = messages[0]
                // let message = messages[messages.length - 1]
                console.log('dialog message')
                this.props.consumeAllMessages()
                let stateMessages = this.state.messages
                stateMessages.push(msg0)
                this.setState({
                    messages: stateMessages
                })
            }  
        }
    }

    addAMessageToState = (message: Message) => {
        let stateMessages = this.state.messages
        stateMessages.push(message)
        this.setState({
            messages: stateMessages
        })  
    }


    removeMessageFromState = (message: Message) => {
        let stateMessages = this.state.messages
        let newStateMessages = stateMessages.filter((msg, index, array) => {
            return msg != message
        })
        this.setState({
            messages:newStateMessages
        })
    }

    render() {
        this.doSomething()
        const {messages} = this.state

        return (
                <Fragment>
                    {
                        messages.map((message, index, array) => {
                            if (message == null) {
                                return null
                            } else if (message.component == null) {
                                return (
                                    <Dialog open={true} aria-labelledby="simple-dialog-title">
                                        <DialogTitle>{message != null ? message.message : null}</DialogTitle>
                                        <Button onClick={()=>{this.removeMessageFromState(message)}}>OK</Button>
                                    </Dialog>
                                )
                            } else {
                                return (
                                    <QQDialog open={true} toggleDialogOpen={()=>{this.removeMessageFromState(message)}} 
                                    title={message.message}>
                                        {message.component}
                                    </QQDialog>
                                )
                            }
                        })
                    }
                </Fragment>

        )
        
    }
}


export default 
connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DialogHandler
)

