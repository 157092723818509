import { Filter } from '@devexpress/dx-react-grid';
import { withStyles, Button } from '@material-ui/core';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { getPaginatedShippingOrders } from '../../api/admin';
import { ShippingOrder } from '../../api/models';
import { getDefaultControlledConfig as getShippingOrderConfig } from '../../components/shippingorderdisplay/defaultconfig';
import ShippingOrderDisplay from '../../components/shippingorderdisplay/ShippingOrderDisplay';
import { commonFeatureActions } from '../../features/commonfeature';
import { handleApiError } from '../../utils';
import { getEditedShippingOrders } from '../../features/commonfeature/selectors';
import RefreshIcon from '@material-ui/icons/Refresh';


const mapStateToProps = (state: MyTypes.RootState) => ({
    editedShippingOrders: getEditedShippingOrders(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


const stateObj = {
    page: 1,
    perPage: 10,
    filters: Array<Filter>(),
    shippingOrders: Array<ShippingOrder>(),
    totalCount: 0
}

class ShippingOrderPage extends React.Component<RealProps, typeof stateObj> {
    state = stateObj

    subscription: any;
    filterObs: BehaviorSubject<{filters: Array<Filter>}>;
    shippingConfigs: ReturnType<typeof getShippingOrderConfig>

    constructor(props:RealProps) {
        super(props)
        this.filterObs = new BehaviorSubject({filters: Array<Filter>()})
        this.shippingConfigs = getShippingOrderConfig(props.history)
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ title: 'Shipping Order' }) 
        this.getNext()
        this.subscription = this.filterObs.pipe(
            debounce((val)=> {
                return timer(1000)
            })
        ).subscribe(newState => {
            this.setState({
                ...newState
            }, ()=> { this.getNext() })
        })
    }


    componentWillUnmount() {
        this.subscription.unsubscribe()
    }

    getNext = ()=> {
        const { page, perPage, 
            filters
         } = this.state
        

        const transformedFilters = filters.map((filter) => {
            return `${filter.columnName}=${filter.value}`
        })
        getPaginatedShippingOrders(
            page,
            perPage,
            transformedFilters
        ).then((resp)=> {
            let shippingOrdersPagination = resp.data.message
            if (shippingOrdersPagination != null && shippingOrdersPagination.items) {

                // mix 
                
                this.setState({
                    shippingOrders: shippingOrdersPagination.items,
                    totalCount: shippingOrdersPagination.itemCount * shippingOrdersPagination.pages
                })
            }
        }).catch((error) => {
            handleApiError(error, this.props.showMessage)
        })
    }

    onChangeCurrentPage = (currentPage: number) => {
        this.setState({
            page: currentPage + 1
        }, ()=> {
            this.getNext()
        })
        
    }

    onPageSizeChange = (pageSize: number) => {
        this.setState({
            perPage: pageSize
        }, ()=> {
            this.getNext()
        })
    }

    getShippingOrderArray = (): Array<ShippingOrder> => {
        let shippingOrders = this.state.shippingOrders
        console.log('shippingorders: ', shippingOrders)
        if (shippingOrders == null) {
            return []
        }
        if (shippingOrders == undefined) {
            return []
        }
        const { editedShippingOrders } = this.props

        // Update the response if some item is edited, not very efficieny, but should be fine
        if (editedShippingOrders.length > 0) {
            console.log('edited Shipping order length: ', editedShippingOrders.length)
            shippingOrders = shippingOrders.map((value, index) => {
                const found = editedShippingOrders.find((editedShippingOrder, index)=>{
                    return editedShippingOrder.id == value.id
                }) 
                if (found != null) {
                    console.log('found shipping order', found)
                    return found
                }
                return value
            })
        }
        return shippingOrders
    }

    onFiltersChange = (filters: Filter[]) => {
        this.filterObs.next({filters})
    }

    render() {
        const { container } = this.props;
        const { page, perPage, totalCount } = this.state

        const { 
            pageSizes, 
            ...other } = this.shippingConfigs
        return (
            <div className={container}>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.getNext}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>
                <ShippingOrderDisplay 
                    {...other}
                    showFilter
                    showPagePanel
                    showEdit
                    onFiltersChange={this.onFiltersChange}
                    page={page}
                    perPage={perPage}
                    onCurrentPageChange={this.onChangeCurrentPage}
                    onPageSizeChange={this.onPageSizeChange}
                    totalCount={totalCount}
                    data={this.getShippingOrderArray()}/>
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingOrderPage)))