import * as H from 'history';
import { MyColumn } from '../../common/models';


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal = [
        { title: 'Id', name: 'id' },
        { title: 'Country Code', name: 'countryCode'},
        { title: 'Area Code', name: 'areaCode'},
        { title: 'Subscriber Number', name: 'subscriberNumber'}
    ]
    return normal
}