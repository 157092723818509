import { ActionType, getType } from 'typesafe-actions';
import * as drawerActions from './actions'
import { combineReducers } from 'redux';

export type DrawerState = {
    readonly opened: boolean;
}

export type AuthAction = ActionType<typeof drawerActions>

export default combineReducers<DrawerState, AuthAction> ({
    opened: (state = false, action) => {
        switch(action.type) {
            case getType(drawerActions.closeDrawer):
                console.log("Reducer close drawer")
                return false
            case getType(drawerActions.openDrawer):
                console.log('Reducer open drawer')
                return true
            case getType(drawerActions.toggleDrawer):
                console.log('Reducer: toggle drawer')
                return !state
            default:
                return state
        }
    }
})


