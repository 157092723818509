import React from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { IconButton} from '@material-ui/core';
import { CouponOwnership } from '../../api';
import * as H from 'history';
import NavigationIcon from '@material-ui/icons/Navigation';


  export const getColumns = (history: H.History) : Column[] => [
    { name: 'Detail', getCellValue: (row: CouponOwnership)=> {
        return (
            <IconButton onClick={()=> {
                    history.push(`/coupon-ownership/${row.id}`)
                }}>
                <NavigationIcon fontSize="small" />
            </IconButton>
        )
    }},
    { title: 'Id', name: 'id', },
    { title: 'User Id', name: 'userId'},
    { title: 'Redeemed', name: 'redeemed', getCellValue: (row: CouponOwnership)=> {
        return row.redeemed ? '✓': ''
    }},
    { title: 'Redeemed At', name: 'redeemedAt', },
    { name: 'revoked', getCellValue: (row: CouponOwnership)=> {
        return row.revoked ? '✓' : ''
    }},
    { name: 'Lat', getCellValue: (row: CouponOwnership) => {
        return row.userLocation != null ? row.userLocation.latitude : null
    }},
    { name: 'Long', getCellValue: (row: CouponOwnership) => {
        return row.userLocation !=null ? row.userLocation.longitude : null
    }}
    
]