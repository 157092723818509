import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Paper, Button, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import jsfiledownload from 'js-file-download'
import { agent } from '../../api';
import { isNumber } from '../../utils';


const mapStateToProps = (state: MyTypes.RootState) => ({
    spotRelatedIdsData: commonFeatureSelectors.getSpotRelatedIdsData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            showMessage: commonFeatureActions.showMessage,
            getSpotRelatedIds: commonFeatureActions.getSpotRelatedIdsRequest,
        },
        dispatch
    )


const styles = {
    paper: {
        marginTop: '2%',
        padding: '1%'
    },
    container: {
        width: '100%'
    }
}

type Props = {
    container: string,
    classes: {
        paper: string
    }
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class ClientReportPage extends React.Component<RealProps> {
    state = {
        page: 0,
        perPage: 10,
        addedRows: [],
        loadingReport: false
    }

    startDateRef: React.RefObject<HTMLInputElement>;
    endDateRef: React.RefObject<HTMLInputElement>;
    couponBannerIdRef: React.RefObject<HTMLInputElement>;
    couponIdRef: React.RefObject<HTMLInputElement>;
    qrCodeEntityIdRef: React.RefObject<HTMLInputElement>;
    spotBannerIdRef: React.RefObject<HTMLInputElement>;
    spotIdRef: React.RefObject<HTMLInputElement>;
    

    constructor(props: RealProps) {
        super(props)
        this.startDateRef = React.createRef();
        this.endDateRef = React.createRef();
        this.couponBannerIdRef = React.createRef();
        this.couponIdRef = React.createRef();
        this.qrCodeEntityIdRef = React.createRef();
        this.spotBannerIdRef = React.createRef();
        this.spotIdRef = React.createRef();
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ title: 'Client Report' }) 
    }

    onDownloadCSVButtonClickced = ()=> {
        console.log('onDownloadCSVButtonClickced')

        if (this.state.loadingReport) {
            this.props.showMessage({
                message: 'Loading Report Please Wait'
            })
            return
        }

        const startDate = this.startDateRef.current!!.value;
        const endDate = this.endDateRef.current!!.value;
        const couponBannerId = this.endDateRef.current!!.value;
        const couponId = this.couponIdRef.current!!.value;
        const qrCodeEntityId = this.qrCodeEntityIdRef.current!!.value;
        const spotBannerId = this.spotBannerIdRef.current!!.value;
        const spotId = this.spotIdRef.current!!.value;

        console.log('startDate', startDate)

        if (!isNumber(startDate)) {
            this.props.showMessage({
                message: 'Start Date is invalid, for example 20200120 YYYYMMDD'
            })
            return
        }
        if (!isNumber(endDate)) {
            this.props.showMessage({
                message: 'End Date is invalid, for example 20200220 YYYYMMDD'
            })
            return
        }
        if (!isNumber(couponBannerId)) {
            this.props.showMessage({
                message: 'couponBannerIdis invalid, for example 22'
            })
            return
        }

        if (!isNumber(couponId)) {
            this.props.showMessage({
                message: 'couponId is invalid, for example 22'
            })
            return
        }
        if (!isNumber(qrCodeEntityId)) {
            this.props.showMessage({
                message: 'qrCodeEntityId is invalid, for example 22'
            })
            return
        }
        if (!isNumber(spotBannerId)) {
            this.props.showMessage({
                message: 'spotBannerId is invalid, for example 22'
            })
            return
        }
        if (!isNumber(spotId)) {
            this.props.showMessage({
                message: 'spotId is invalid, for example 22'
            })
            return
        }

        this.setState({
            loadingReport: true
        })

        agent.get(`/admin/big-query/spot-data`, {
            params: {
                startDate,
                endDate,
                couponBannerId,
                couponId,
                qrCodeEntityId,
                spotBannerId,
                spotId
            }
        })
        .then((response) => {
            console.log('response', response)
                // FileDownload(response.data, 'report.csv');
                jsfiledownload(response.data, `spot-${spotId}-client-raw-data.csv`, 'text/csv')
                console.log('jsfiledownload')
            this.setState({
                loadingReport: false
            })
        })
        .catch((exception) => {
            this.setState({
                loadingReport: false
            })
        })
    }

    render() {
        const { container, classes,  spotRelatedIdsData} = this.props;
        console.log('spotRelatedIdsData', spotRelatedIdsData)
        // const { addedRows, page, perPage } = this.state
        return (
            <div className={container}>
                <Typography variant='h6'>
                    ClientReportPage
                </Typography>

                <Paper className={classes.paper}>
                    <Typography style={{whiteSpace: 'pre-wrap'}}>
                        {this.props.spotRelatedIdsData != null ? JSON.stringify(this.props.spotRelatedIdsData.cached, null, 2) : ''}
                    </Typography>

                    <Button onClick={()=> {
                        const spotId = this.spotIdRef.current!!.value;
                        
                        if (!isNumber(spotId)) {
                            this.props.showMessage({
                                message: 'spotId is invalid, for example 22'
                            })
                            return
                        }
                        this.props.getSpotRelatedIds({spotId: spotId})
                    }}>
                        Get Spot Related Ids
                    </Button>
                </Paper>

                <Paper className={classes.paper}>
                        <TextField
                            id="startDate"
                            label="Start Date"
                            helperText={'e.g. 20200220'}
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.startDateRef}
                        />

                        <TextField
                            id="endDateTextField"
                            label="End Date"
                            helperText={'e.g. 20200220'}
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.endDateRef}
                        />
                        <TextField
                            id="couponBannerIdField"
                            label="Coupon Banner Id"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.couponBannerIdRef}
                        />

                        <TextField
                            id="couponIdField"
                            label="Coupon Id"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.couponIdRef}
                        />
                        <TextField
                            id="qrCodeEntityIdField"
                            label="QR Code Entity Id"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.qrCodeEntityIdRef}
                        />
                        <TextField
                            id="spotBannerIdField"
                            label="Spot Banner Id"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.spotBannerIdRef}
                        />
                        <TextField
                            id="spotIdField"
                            label="Spot Id"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.spotIdRef}
                        />
                    <Button 
                        disabled={this.state.loadingReport}
                        onClick={this.onDownloadCSVButtonClickced}>
                        Download CSV
                    </Button>
                </Paper>
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientReportPage)))