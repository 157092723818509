import { withStyles } from '@material-ui/core';
import { RewardBundle } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createRewardBundle: commonFeatureActions.createRewardBundle
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class SpotBannerDisplay extends BaseDisplay<RewardBundle, MyProps<RewardBundle>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as MyProps<RewardBundle>
            const { added } = changeSet
            if (added != null) {
                // TODO change it to coupon banner
                console.log('Adding bundle ids', added)
                

                added.forEach(raw => {
                    let rawCouponIds = raw['couponIds']
                    let couponIds = [] as number[]
                    if (rawCouponIds != null) {
                        couponIds = (rawCouponIds as string).split(',').map((value)=> {
                            return Number(value)
                        })
                    } 

                    let toBeCollected = raw['toBeCollected'] =='1' || raw['toBeCollected'] == 'true'
                    let creditRewardId = raw['creditRewardId']
                    let actionUrl = raw['actionUrl']
                    console.log('Adding', couponIds, toBeCollected, creditRewardId, actionUrl)
                    props.createRewardBundle({
                        couponIds,
                        toBeCollected,
                        creditRewardId,
                        actionUrl
                    })
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SpotBannerDisplay)))