import * as H from 'history';
import { MyColumn } from '../../common/models';


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal = [
        { title: 'Id', name: 'id', editable: false},
        { title: 'Type', name: 'type', editable: false},
        { title: 'Published', name: 'published', editable: true, getCellValue: (row: any) => {
            return row.published != null ? row.published.toString() : ""
        }},
        { title: 'Priority', name: 'priority', editMode: true},
        { title: 'Image Id', name: 'imageId', getCellValue: (row: any) => {
            if (row.imageId != null) {
                return row.imageId;
            } else if (row.image != null) {
                return row.image.id;
            }
        }},
        {
            title: 'Spot Id',
            name: 'spotId',
            getCellValue: (row: any) => {
                if (row.spotId) {
                    return row.spotId;
                } else if (row.spot != null) {
                    return row.spot.id;
                }
            }
        },
        {
            title: 'Coupon Id',
            name: 'couponId',
            getCellValue: (row: any) => {
                if (row.couponId != null){
                    return row.couponId;
                } else if (row.coupon != null) {
                    return row.coupon.id;
                }
            }
        },
        {
            title: 'Prize Id',
            name: 'prizeId',
            getCellValue: (row: any) => {
                if (row.prizeId != null) {
                    return row.prizeId;
                } if (row.prize != null) {
                    return row.prize.id;
                }
            }
        }
    ]
    return normal;
}