import { withStyles } from '@material-ui/core';
import { Location } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { extractInputIntoTranslation } from '../../api';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createLocation: commonFeatureActions.createLocation
        },
        dispatch
    )

type MyProps<Location> = BaseRealProps<Location> & ReturnType<typeof mapDispatchToProps>

class GeographicDisplay extends BaseDisplay<Location, MyProps<Location>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        header: 'Geographic Display',
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as unknown as MyProps<Location>
            console.log('Geographic Display default onCommitEditing', changeSet)
            const { added } = changeSet
            if (added != null) {
                added.forEach(rawLocation => {
                    let nameTranslation = extractInputIntoTranslation('name', rawLocation)
                    let descriptionTranslation = extractInputIntoTranslation('description', rawLocation)
                    let latitude = rawLocation['latitude']
                    let longitude = rawLocation['longitude']
                    props.createLocation({
                        latitude,
                        longitude,
                        name: nameTranslation,
                        description: descriptionTranslation
                    })
                    console.log('rawLocation', rawLocation, 'name Translation', nameTranslation, descriptionTranslation)
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(GeographicDisplay)))