import { combineEpics } from 'redux-observable';

import * as todosEpics from '../features/todos/epics';
import * as appEpcis from '../features/app/epics'
import * as authEpics from '../features/auth/epics';
import * as commonEpics from '../features/commonfeature/epics'


// TODO sshould comment out appEpics
export default combineEpics(...Object.values(todosEpics), 
...Object.values(authEpics),
...Object.values(commonEpics),
...Object.values(appEpcis));
