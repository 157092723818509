import { Column } from '@devexpress/dx-react-grid';
import * as H from 'history';
import { BusinessBranch } from '../../api';


export const getColumns = (history: H.History): Column[] => [
    { title: 'id', name: 'id' },
    { title: "Active", name: 'active', getCellValue: (row: BusinessBranch) => {
        return row.active? '✓' : ''
    }},
    { title: 'Name', name: 'name'},
    { title: 'Opening Hour', name: 'openingHour'}, 
    { title: 'Closing Hour', name: 'closingHour'},
    { title: 'Number of coupons', name: 'numberofCoupons', getCellValue: (row:BusinessBranch) => {
        return row.coupons.length
    }},
    { title: 'Description', name: 'descritpion'},
    { title: 'District', name: 'district', getCellValue: (row: BusinessBranch) => {
        return row.district != null ? row.district.name : null
    }},
    { title: 'Location', name: 'location', getCellValue: (row: BusinessBranch) => {
        return row.location != null ? `${row.location.latitude}, ${row.location.longitude}` : null
    }},
    { title: 'Phone Number', name: 'phoneNumber', getCellValue: (row:BusinessBranch) => {
        return row.phoneNumber == null ? null : `+${row.phoneNumber.countryCode} ${row.phoneNumber.subscriberNumber}`
    }}
    
];

export default getColumns