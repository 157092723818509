import React, { ChangeEvent } from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Paper,  Button, Typography, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import '../../index.css'
import ArticleBlockInput from '../../components/articleblockinput';
import { ArticleBlockInputType } from '../../api';
import { isNumber } from '../../utils';


// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const mapStateToProps = (state: MyTypes.RootState) => ({
    
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            showMessage: commonFeatureActions.showMessage,
            createArticle: commonFeatureActions.createArticle
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        height: '50%',
        padding: '5em',
        marginTop: '5em'
    },
    articleBlock: {
        marginTop: '0.5em'
    }
}

type Props = {
    classes: {
        container: string,
        paper: string,
        articleBlock: string
    }
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


class ArticlePage extends React.Component<RealProps> {
    state = {
        page: 1,
        perPage: 10,
        addedRows: [],
        numOfArticleBlockInputTypes: 0,
        articleBlockInputTypes: Array<ArticleBlockInputType>(),
        tagValues: Array<string>(),
        title: '',
        mainImageId: '',
        thumbnailImageId: ''
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'Article'
        })
    }

    onCreateArticleButtonClicked = ()=> {
        console.log('onCreateArticleButtonClicked')
        let { 
            articleBlockInputTypes, tagValues, title, 
            mainImageId, thumbnailImageId} = this.state;
        console.log('this.states', this.state )

        if ( mainImageId != '' && ! isNumber(mainImageId) ) {
            this.props.showMessage({
                message: 'Main Image Id is not number'
            })
            return 
        }
        if  (thumbnailImageId != '' && ! isNumber(thumbnailImageId) ) {
            this.props.showMessage({
                message: 'Thumbnail Image id is not number'
            })
            return 
        }

        // if (mainImageId == '') {
        //     mainImageId = null
        // }
        // if (thumbnailImageId == '') {
        //     thumbnailImageId = null
        // }

        this.props.createArticle({
            title,
            mainImageId: mainImageId == '' ? undefined : mainImageId,
            thumbnailImageId: thumbnailImageId == '' ? undefined : thumbnailImageId,
            tagKeys: tagValues,
            blocks: articleBlockInputTypes,
            tagIds: []
        })
    }

    render() {
        console.log('Article Page props', this.props)
        const { container, paper, articleBlock } = this.props.classes;

        const { articleBlockInputTypes, tagValues } = this.state 
        console.log('paper style', paper)
        console.log('articleBlockInputTypes', articleBlockInputTypes)
        return (
            <div className={container}>
                <Paper className={paper}>
                    <Button
                        color='secondary'
                        onClick={this.onCreateArticleButtonClicked}
                        >Create Article</Button>

                </Paper>
                <Paper className={paper}>
                    <TextField
                        id='title'
                        label={'Title'}
                        fullWidth
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                title: event.currentTarget.value
                            })  
                        }}
                                    />

                    <TextField
                        id='mainImageId'
                        label={'Main Image Id'}
                        fullWidth
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.setState({
                              mainImageId: event.currentTarget.value
                          })  
                        }}
                        />
                    <TextField
                        id='thumbnailImageId'
                        label={'Thumbnail Image Id'}
                        fullWidth
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.setState({
                              thumbnailImageId: event.currentTarget.value
                          })  
                        }}
                        />
                </Paper>
                <Paper className={paper}>
                    <Button
                        onClick={()=> {
                            // TODO Add block
                            articleBlockInputTypes.push({

                            })
                            console.log('articleBlockInputTypes',articleBlockInputTypes)
                            this.setState({
                                articleBlockInputTypes
                            })
                        }}

                        >Add Block</Button>
                    <Typography variant='h3'>
                        Blocks to be uploaded
                    </Typography>
                    {
                        articleBlockInputTypes.map((articleBlockType: ArticleBlockInputType, index) => {
                            return <ArticleBlockInput
                                    className={articleBlock}
                                    title={`${index + 1}`}
                                    onChange2={(articleBlockInputType_: ArticleBlockInputType)=> {
                                        articleBlockInputTypes[index] = articleBlockInputType_
                                        this.setState({
                                            articleBlockInputTypes
                                        })
                                    }}
                                />
                        })
                    }        
                </Paper>
                <Paper className={paper}>
                    <Button onClick={()=> {
                        tagValues.push('')
                        this.setState({
                            tagValues
                        })
                    }}>Add</Button>
                    <Typography variant='h3'>
                        Tags
                    </Typography>
                    {
                        tagValues.map((tagValue, index) => {
                            return <TextField
                                    id={tagValue}
                                    value={tagValue}
                                    label={`${index + 1}`}
                                    fullWidth
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        tagValues[index] = event.target.value
                                        console.log('tagValues', tagValues)
                                        this.setState({
                                            tagValues
                                        })
                                    }}
                                    />
                        })
                    }
                </Paper>
                    
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ArticlePage)))