import * as H from 'history';
import { MyColumn } from '../../common/models';
import React from 'react'

// TODO we need translations 

export const getColumns = (history: H.History, editMode: boolean = false): MyColumn[] => {
    let output: MyColumn[] = [
        { 'title': 'ID', name: 'id', editable: false },
        { title: 'Period Key', name: 'periodKey' },
        { title: 'Shorten Url', name: 'shortenUrl' },
        { title: 'Start Time', name: 'startTime' },
        { title: 'End Time', name: 'endTime' },
    ]
    return output
}
