import { CommonState } from './reducer'

export const getUserCouponOwnershipsData = (state: CommonState) => state.userCouponOwnershipsData

export const getUserShippingOrders = (state: CommonState) => state.userShippingOrdersData

export const getSpotsData = (state: CommonState) => state.spotsData

export const getMessages = (state: CommonState) => state.messages

export const getUsersData = (state: CommonState) => state.usersData

export const getGamesData = (state: CommonState) => state.gamesData

export const getBusinessData = (state: CommonState) => state.businessData

export const getUserData = (state: CommonState) => state.userData

export const getAppBarConfig = (state: CommonState) => state.appBarconfig

export const getEditedShippingOrders = (state: CommonState) => state.editedShippingOrders

export const getGeographicLocationData = (state: CommonState) => state.geographicLocationsData

export const getPhoneNumberData = (state: CommonState) => state.phoneNumberData

export const getGameBannerData = (state: CommonState) => state.gameBannerData

export const getCouponBannerData = (state: CommonState) => state.couponBannerData

export const getMajorBannerData = (state: CommonState) => state.majorBannerData

export const getSpotBannerData = (state: CommonState) => state.spotBannerData

export const getRewardBundleData = (state: CommonState) => state.rewardBundleData

export const getCouponData = (state: CommonState) => state.couponData

export const getQRCodeEntityData = (state: CommonState) => state.qrCodeEntityData

export const getSpotRelatedIdsData = (state: CommonState) => state.spotRelatedIds

export const getPrizeBannerData = (state: CommonState) => state.prizeBannerData

export const getPrizeData = (state: CommonState) => state.prizeData

export const getTagData = (state: CommonState) => state.tagData

export const getCreditRewardData = (state: CommonState) => state.creditRewardData

export const getClientTaskData = (state: CommonState) => state.clientTaskData;

export const getMixedBannerTaskData = (state: CommonState) => state.mixedBannerData;