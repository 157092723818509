import * as H from 'history';
import { MyColumn } from '../../common/models';
import { makeImageColumns } from '../../utils';
// import { makeMyColumnsTranslation, CouponForAdminExtension } from '../../api';
import { createColumnsForTranslations } from '../utils';


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal: MyColumn[] = [
        {title: 'Id', name: 'id', editable: false},
        ...createColumnsForTranslations('Name', 'name'),
        { title: 'Active', name: 'active', getCellValue: (row) => { 
            if (row.active != null) {
                return row.active.toString()
            }
            
        }},
        { title: 'Valid Until', name: 'validUntil'},
        { title: 'Allowed Period End', name: 'allowedPeriodEnd'},
        { title: 'Allowed Period Start', name:'allowedPeriodStart'},
        { title: 'Allowed Period Descripton', name: 'allowedPeriodDescription'},
        { title: 'Expired', name: 'expired', editable: false},
        { title: 'Maximum Amount', name: 'maximumAmount'},
        { title: 'Phone Number', name: 'phoneNumber', getCellValue: (row) => {
           if (row.phoneNumber != null) {
               let phoneNumber = row.phoneNumber
               return `${phoneNumber.countryCode} (${phoneNumber.areaCode}) ${phoneNumber.subscriberNumber}`
           }
        }, editable: false},
        { title: 'Phone Number Id', name: 'phoneNumberId'},
        ...createColumnsForTranslations('How to use', 'howToUse'),
        ...createColumnsForTranslations('Description', 'description'),
        ...createColumnsForTranslations('Short Description', 'shortDescription'),
        ...createColumnsForTranslations('Slogan1', 'slogan1'),
        ...createColumnsForTranslations('Slogan2', 'slogan2'),
        ...createColumnsForTranslations('Term And Conditions', 'termAndConditions'),
        ...makeImageColumns('Square Image'),
        { title: 'Image Ids', name:'imageIds', getCellValue: (row) => {
            console.log(row)
            if (row.imageIds!=null) return row.imageIds
            if (row.images != null) {
                const output = (row.images as any).map((image: any)=> image.id.toString()).reduce((one: any, two: any) => { 
                    if (one == '') {
                        return two
                    }
                    return one + "," + two
                }, '')
                return output
            }
        }},

    ]

    if (editMode) {
    

        normal.push({
            title: 'Business Branch Ids', name: 'businessBranchIds'
        })
    }
    return normal
}