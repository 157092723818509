import { withStyles } from '@material-ui/core';
import { PhoneNumber } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
// import { commonFeatureActions } from '../../features/commonfeature';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';
// import { extractInputIntoTranslation } from '../../api';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createPhoneNumber: commonFeatureActions.createPhoneNumber
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class PhoneNumberDisplay extends BaseDisplay<PhoneNumber, MyProps<PhoneNumber>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        header: 'Phone Number',
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as unknown as  MyProps<PhoneNumber>
            const { added } = changeSet
            if (added != null) {
                console.log('added phone number', added)
                added.forEach(rawPhoneNumber => {
                    let countryCode = rawPhoneNumber['countryCode']
                    let areaCode = rawPhoneNumber['areaCode']
                    let subscriberNumber = rawPhoneNumber['subscriberNumber']

                    console.log('xxx ', countryCode, areaCode, subscriberNumber)
                    props.createPhoneNumber({
                        countryCode,
                        areaCode,
                        subscriberNumber
                    })
                    
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(PhoneNumberDisplay)))