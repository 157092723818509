import axios from 'axios';

const URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api`;

export const tokenStorer = {
  token: ''
}

const getToken = () => {
  // console.log('getToken', tokenStorer)
  return tokenStorer.token
}

const formatToken = (token: string | null) => {
  return token && `Bearer ${token}`;
};

// Public

export const setToken = (token: string | null) => {
  agentInstance.defaults.headers.common.Authorization = formatToken(token);
};

const agentInstance = axios.create({
  baseURL: URL,
  timeout: 60000, // 60s
  // headers: {
  //   common: {
  //     Authorization: ()=> {return formatToken(getToken())},
  //   },
  // },
});

agentInstance.interceptors.request.use((value)=> {
  // let token = (require('../store').default.getState() as RootState).auth.authentication.token
  // console.log('store', token) 
  value.headers['Authorization'] = formatToken(getToken())
  return value
})

export default 
  agentInstance
;
