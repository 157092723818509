import { withStyles } from '@material-ui/core';
import { RuleCondition } from '../../api/models';
import BaseDisplay, { BaseRealProps, Props } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createRuleCondition: commonFeatureActions.createRuleCondition,
            patchRuleCondition: commonFeatureActions.patchRuleCondtion
        },
        dispatch
    )

type RuleConditionProps = {
    scanRuleId?: number | string
}

type MyProps<Data> = RuleConditionProps & BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class RuleConditionDisplay extends BaseDisplay<RuleCondition, MyProps<RuleCondition>> {
    static defaultProps = {
        
        ...BaseDisplay.defaultProps,
        onRowAdded: (row: any, props: any) => {
            console.log('onRowAdded', row, props)
            console.log('aa')
            let scanRuleId = props.scanRuleId
            if (scanRuleId != null) {
                row['scanRuleId'] = scanRuleId
            }
            (props as unknown as MyProps<RuleCondition>).createRuleCondition(row)
        },
        onRowEdited: (row: any, props: MyProps<RuleCondition>, entity: any) => {
            let entityId = entity.id
            props.patchRuleCondition({id: entityId, args: row})
            // (props as unknown as MyProps<RuleCondition>).
        }
        // onRowAdded: (row: any, props: Props) => {
        //     console.log('onRowAdded', row, props)
        //     console.log('aa')
        // },

        // ,
        // onCommitEditing: function(changeSet: ChangeSet) {


        //     const { added } = changeSet

        //     if (added != null) {
        //         added.forEach(raw => {
        //             let scanRuleId = (this as any).scanRuleId
        //             if ( scanRuleId != null) {
        //                 raw['scanRuleId'] = scanRuleId
        //             }
        //             (this as unknown as MyProps<RuleCondition>).createRuleCondition(raw)
        //         })
        //     }
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(RuleConditionDisplay)))