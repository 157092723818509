import { withStyles } from '@material-ui/core';
import { MajorBanner } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createMajorBanner: commonFeatureActions.createMajorBanner,
            patchMajorBanner: commonFeatureActions.patchMajorBanner
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class MajorBannerDisplay extends BaseDisplay<MajorBanner, MyProps<MajorBanner>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as MyProps<MajorBanner>
            const { added, changed } = changeSet
            const data: any = (this as any).data
            console.log('data', data)
            if (changed != null) {
                for (let rowIndex in changed) {
                    const kv = changed[rowIndex]
                    console.log('coupon kv', kv)
                    const row = data[rowIndex]
                    const id = row.id
                    console.log('data[rowIndex]', row, id);
                    // const rowId: any = data[rowIndex].id

                    (this as any).patchMajorBanner({
                        majorBannerId: id,
                        args: { ...kv }
                    })
                    // TODO
                }
            }
            if (added != null) {
                // TODO change it to coupon banner
                console.log('Adding MajorBanner Banner', added)
                added.forEach(raw => {
                    let active = raw['active'] == '1' || raw['active'] == 'true'
                    
                    let imageId = raw['imageId']
                    let url = raw['url']
                
                    props.createMajorBanner({
                        active, imageId, url
                    })
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(MajorBannerDisplay)))