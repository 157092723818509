import { Filter } from '@devexpress/dx-react-grid';
import { FormControlLabel, Paper, Radio, RadioGroup, Typography, withStyles, Button, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import MyTypes from 'MyTypes';
import React, { ChangeEvent, ReactText } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
// import { Game } from '../../api/models';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import * as Collections from 'typescript-collections';
import { UserEntity } from '../../api';
import { getPaginatedUsers, sendNotificationToUsers } from '../../api/admin';
import UserDisplay from '../../components/userdisplay';
import { getColumnsForSelectingUser as getColumnsForUser } from '../../components/userdisplay/columns';
import { getSelectionConfig as getUserConfig } from '../../components/userdisplay/defaultconfig';
import { commonFeatureActions } from '../../features/commonfeature';
import LanguageInput from '../../components/languageinput';
import LoadingCover from '../../commonui/loadingcover';


const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig
        },
        dispatch
    )


const styles = {
    paper: {
        marginTop: '2%',
        padding: '1%'
    },
    container: {
        width: '100%'
    },
    radioAnchor: {
        color: green[600],
        '&$checked': {
          color: green[500],
        },
      },
    checked: {},
}

type Props = {
    match: match<{gameId: string;}>,
    container: string,
    classes: {
        radioAnchor: string,
        checked: string,
        paper: string
    }
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>




const MODE_SELECT = 'select'
const MODE_ALL = 'all'

type State = {
    mode: string,
    selectUserPagination: {
        data: Array<UserEntity>,
        page: number
        perPage: number,
        filters: Filter[],
        totalCount: number
    },
    selectedUsers: Collections.Set<UserEntity>,
    pageSelections: Collections.Dictionary<number, ReactText[]>,
    showLoading: boolean,
    loadingUserSelection: boolean,
    alertUserForTitle: boolean,
    alertUserForMessage: boolean
}

class NotifyUserPage extends React.Component<RealProps, State> {
    state = {
        mode: MODE_SELECT,
        selectUserPagination: {
            data: Array<UserEntity>(),
            page: 0,
            perPage: 10,
            filters: Array<Filter>(),
            totalCount: 0
        },
        selectedUsers: new Collections.Set<UserEntity>((value) => { return value.id.toString()}),
        pageSelections: new Collections.Dictionary<number, ReactText[]>(),
        showLoading: false,
        loadingUserSelection: false,
        alertUserForTitle: false,
        alertUserForMessage: false
    }

    filterObj: BehaviorSubject<{filters: Array<Filter>}>
    subscription: Subscription;
    messageRef: React.RefObject<LanguageInput>;
    titleRef: React.RefObject<LanguageInput>;
    routeUrlRef: React.RefObject<HTMLInputElement>;
    imageUrlRef: React.RefObject<HTMLInputElement>;

    constructor(props: RealProps) {
        super(props)
        this.filterObj = new BehaviorSubject({filters: Array<Filter>()})
        this.messageRef = React.createRef();
        this.titleRef = React.createRef();
        this.routeUrlRef = React.createRef();
        this.imageUrlRef = React.createRef();
    }

    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'Notify User'
        })
        this.getUsersForSelection()
        this.subscription = this.filterObj.pipe(
            debounce((val) => {
                return timer(1000)
            })
        ).subscribe(newState => {
            this.setState({
                selectUserPagination: {
                    ...this.state.selectUserPagination,
                    filters: newState.filters
                }
            }, ()=> {
                this.getUsersForSelection()
            })
        })
    }

    componentWillUnmount() {
        this.subscription.unsubscribe()
    }

    onModeChange = (event: ChangeEvent<{}>, value: string)=> {
        // event.target.
        console.log('onModeChaNGE', event, value)
        this.setState({mode: value}, ()=> {
            if(value == MODE_SELECT) {
                this.getUsersForSelection()
            }
        })

    }

    onFiltersChange = (filters: Filter[]) => {
        console.log('Notify User ON Filter Change', filters)
        this.filterObj.next({filters})
    }

    getUsersForSelection = ()=> {
        const { page, perPage, filters } = this.state.selectUserPagination
        this.setState({
            loadingUserSelection: true
        })
        getPaginatedUsers(
            page + 1, perPage, filters.map((filter)=> {
                return `${filter.columnName}=${filter.value}`
            })
        ).then((resp)=> {
            let userPagination = resp.data.message
            if (userPagination != null && userPagination.items!=null) {
                let totalCount = userPagination.pages * perPage
                
                this.setState({
                    selectUserPagination: {
                        ...this.state.selectUserPagination,
                        data: userPagination.items,
                        totalCount: totalCount
                    },
                    
                })
            }
        }).finally(()=> {
            this.setState({
                loadingUserSelection: false
            })
        })
    }

    onSelectUserPageChange=(page: number)=> {
        this.setState({
            selectUserPagination: {
                ...this.state.selectUserPagination,
                page
            }
        }, ()=> {
            this.getUsersForSelection()
        })
    }

    onUserSelectionChange = (selections: ReactText[]) => {
        console.log('onUserSelectionChange', selections)

        // console.log(selections[0].toString())

        // if (selection )
        const clone = Object.create(this.state.selectedUsers) as Collections.Set<UserEntity> // new Collection.set(this.state.selectedUsers)
        // const clone = new Collections.Set<UserEntity>((value) => { return value.id.toString()})
        selections
        .map((node) => {
            return node.toString()
        })
        .forEach((index)=> {
            const userEntity = this.state.selectUserPagination.data[Number(index)]
            console.log(userEntity)
            clone.add(userEntity)
        })

        // let removed = this.state.selectedUsers.difference(clone)
        // console.log('removed', removed)
        // console.log('clone', clone)
        // console.log('old selectedUser', this.state.selectedUsers)

        // const pageSelections  = Object.create(this.state.pageSelections) as Collections.Dictionary<number, ReactText[]>
        const { pageSelections } = this.state
        const currentPagSelections = pageSelections.getValue(this.state.selectUserPagination.page)
        if ( currentPagSelections!= null) {
            let added = Array<ReactText>()
            let removed = Array<ReactText>()
            currentPagSelections.forEach(element => {
                if (selections.find((rr)=> rr == element) == null) {
                    // console.log('removing', element)
                    removed.push(element)
                }
            });

            selections.forEach(element => {
                if (currentPagSelections.find((rr)=> rr == element) == null) {
                    // console.log('adding', element)
                    added.push(element)
                }
            })

            if (removed.length > 0) {
                removed.forEach((element) => {
                    clone.remove(this.state.selectUserPagination.data[Number(element)])
                })
            }
            // console.log('Added', added)
            // console.log('Removed', removed)
        }

        pageSelections.setValue(this.state.selectUserPagination.page, selections)
        // console.log('pageSelections', pageSelections)

        this.setState({
            selectedUsers: clone,
            pageSelections
        })
        // console.log('cone size', clone.size())
        // clone.forEach((value)=> {
        //     console.log("clone value", value)
        // })

    }

    onSubmitButtonClicked = () => {
        // Check the title is not null
        if (this.titleRef.current!!.hasUndefinedValue()) {
            this.setState({ alertUserForTitle: true })
            return
        } else { this.setState({ alertUserForTitle: false }) }

        // Check if the Message is not null
        if (this.messageRef.current!!.hasUndefinedValue()) {
            this.setState({ alertUserForMessage: true })
            return
        } else { this.setState({alertUserForMessage: false}) }
        const titleTrans = this.titleRef.current!!.getInputValues()
        const messageTrans = this.messageRef.current!!.getInputValues()

        const selectedUsers = this.state.selectedUsers.toArray()

        const routeUrl = this.routeUrlRef.current!!.value
        const imageUrl = this.imageUrlRef.current!!.value

        console.log('onSubmitButtonClicked', titleTrans, messageTrans, selectedUsers, routeUrl, imageUrl)
        const { mode } = this.state

        this.setState({
            showLoading: true
        })
        sendNotificationToUsers(selectedUsers.map((user)=> user.id), titleTrans, messageTrans, routeUrl, imageUrl, mode == MODE_ALL).then((resp)=> {
            console.log(resp)
            let obj = resp.data.message
            if (obj != null) {
                this.props.showMessage({
                    message: `Send to ${obj.sentToUserIds}`
                })
                this.props.showMessage({
                    message: `Cannot Sent To ${obj.cannotDeliverToUserId}`
                })
            }
        }).finally(()=> {
            this.setState({showLoading: false})
        })
    }

    render() {
        console.log('NotifyUserPage props', this.props);
        const { container, 
            history, 
            classes,
        } = this.props;

        const { alertUserForTitle, alertUserForMessage, showLoading, pageSelections, selectedUsers, selectUserPagination, mode } = this.state

        let selections = pageSelections.getValue(selectUserPagination.page)
        
        if (selections == null) {
            selections = Array<ReactText>()
        }
        console.log('selections ', selections, 'page', selectUserPagination.page)

        const radioAnchorClasses = { root: classes.radioAnchor, checked: classes.checked };
        return (
            <div className={container}>
                Notify User
                { showLoading ? <LoadingCover/> : null }
                    <Paper>
                        <Typography variant='h6'>Modes</Typography>
                        <RadioGroup
                            name="anchorReference"
                            onChange={this.onModeChange}
                            >
                            <FormControlLabel
                            value={MODE_ALL}
                            control={<Radio classes={radioAnchorClasses} />}
                            label="All User"
                            />
                            <FormControlLabel
                            value={MODE_SELECT}
                            control={<Radio classes={radioAnchorClasses} />}
                            label="Selected User"
                            />
                            </RadioGroup>
                    </Paper>
                    
                    {
                        mode == MODE_SELECT ? <Paper
                            className={classes.paper}>
                            <Typography variant='h6'>Select User</Typography>

                            <UserDisplay
                                {...getUserConfig(history)}
                                selections={selections}
                                onSelectionChange={this.onUserSelectionChange}
                                showSelection={true}
                                showFilter={true}
                                columns={getColumnsForUser(history)}
                                onFiltersChange={this.onFiltersChange}
                                onCurrentPageChange={this.onSelectUserPageChange}
                                pageSizes={[selectUserPagination.perPage]}
                                page={selectUserPagination.page}
                                perPage={selectUserPagination.perPage}
                                data={selectUserPagination.data}
                                totalCount={selectUserPagination.totalCount}
                            />
                        </Paper> : null
                    }

                    {
                        mode == MODE_SELECT ? <Paper className={classes.paper}>
                            <Typography variant='h6'>Select User</Typography>

                            <UserDisplay
                                {...getUserConfig(history)}
                                data={selectedUsers.toArray()}
                            />
                        </Paper> : null
                    }

                    <Paper className={classes.paper}>
                        <LanguageInput
                            isAlertUser={alertUserForTitle}
                            title='Title'
                            ref={this.titleRef}
                            />
                    </Paper>

                    <Paper className={classes.paper}>
                        <LanguageInput
                            isAlertUser={alertUserForMessage}
                            title='Messages'
                            ref={this.messageRef}
                            />
                    </Paper>

                    <Paper className={classes.paper}>
                        <Typography variant='h6'>
                            Route Url
                        </Typography>
                        <TextField
                            id="routeUrl"
                            label="routeUrlTextField"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            defaultValue={'https://app.scannow.gg/home'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.routeUrlRef}
                        />
                    </Paper>

                    <Paper className={classes.paper}>
                        <Typography variant='h6'>
                            Image Url
                        </Typography>
                        <TextField
                            id="imageUrl"
                            label="imageUrlTextField"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={this.imageUrlRef}
                        />
                    </Paper>

                    <Button
                        style={{
                            marginTop: '5px',
                            marginBottom: '20px'
                        }}
                        color='secondary'
                        onClick={this.onSubmitButtonClicked}>Submit</Button>
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifyUserPage)))