import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { ShippingOrder } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
// { BaseRealProps }

import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
// import React, { useState } from 'react'

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%',
        paddingTop: '3%',
        paddingBottom: '3%'
    },
    
}


const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            patchShippingOrder: commonFeatureActions.patchShippingOrder
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class ShippingOrderDisplay extends BaseDisplay<ShippingOrder, MyProps<ShippingOrder>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            console.log('onCommit Editing gg', changeSet, this)
            const props = this as MyProps<ShippingOrder>
            const { data } = props

            if (data != null && data != undefined) {
                if (changeSet.changed != undefined) {
                    for (let rowIndex in changeSet.changed) {
                        let changedStatus = undefined
                        const changedKeyValues = changeSet.changed[rowIndex]
                        for (let key in changedKeyValues) {
                            if (key == 'status') {
                                changedStatus = changedKeyValues[key]
                            }
                        }
                        if (changedStatus) {
                            const rowId = data[Number(rowIndex)].id
                            console.log(`Row: ${rowIndex} rowId: ${rowId} changedStatus: ${changedStatus}`)
                            props.patchShippingOrder(
                                {
                                    shippingOrderId: rowId,
                                    status: changedStatus
                                }
                            )
                        }
                    }
                }
            }
        }
    }
    
}




const EnchancedShippingOrderDisplay =  connect(mapStateToProps, mapDispatchToProps) (withRouter(withStyles(styles)(ShippingOrderDisplay)))

export default EnchancedShippingOrderDisplay

// interface WithLoadingProps {
//     loading: boolean;
//   }
  
// const withLoading = <P extends object>(Component: React.ComponentType<P>) =>
// class WithLoading extends React.Component<P & WithLoadingProps> {
//     render() {
//     const { loading, ...props } = this.props;
//     return loading ? null : <Component {...props as P} />;
//     }
// };

// const withCommitEditing = <Data, P extends BaseRealProps<Data>, BD extends BaseDisplay<P>>( Component: BD) =>
//     class WithCommitEditing extends React.Component<P> {
//         render() {
//             return (
//                 <Component {...this.props as P}/>
//             )
//         }
//     }

// export default withCommitEditing(EnchancedShippingOrderDisplay)

// export default withLoading(EnchancedShippingOrderDisplay)

// const NiceShippingOrderDisplay = function NiceShippingOrderDisplay(gg: Pick<typeof EnchancedShippingOrderDisplay, 'propTypes'>) {
    
//     return (<EnchancedShippingOrderDisplay {...gg}  />)
// }
