import { Authentication } from './models';
import * as auths from './actions'
import { ActionType, getType } from 'typesafe-actions' 
import { combineReducers } from 'redux';
import { UserEntity } from '../../api';
import { tokenStorer } from '../../api/agent'

export type AuthenticationState = {
    readonly authentication: Authentication;
    readonly userEntity: UserEntity | null;
    readonly isLoggedIn: boolean;
}

export type AuthAction = ActionType<typeof auths>

export default combineReducers<AuthenticationState,  AuthAction>({
    authentication: (state = {token: null, tokenExpiredAt: null}, action) => {
            switch(action.type) {
                case getType(auths.logout):
                    // TODO logout here
                    return state
                case getType(auths.setUserEntity):
                    console.log('Reducer setUser Entity', action.payload)
                    tokenStorer.token = action.payload.token
                    return {
                        token: action.payload.token,
                        tokenExpiredAt: action.payload.tokenExpiryTime
                    }
                default:
                    return state
            }
    },
    userEntity: (state = null, action) => {
        switch(action.type) {
            case getType(auths.logout): 
                return null
            case getType(auths.setUserEntity):
                return action.payload
            default:
                return state
        }
    },
    isLoggedIn: (state = false, action) => {
        // TODO we did not really return isLogged in here
        switch(action.type) {
            case getType(auths.logout): 
                return false
            case getType(auths.setUserEntity):
                return true
            default:
                return state
        }
    }
})