import React from 'react';
import { QRCodeUsageHistory } from '../../api/models'
import { Paper, withStyles, Typography, LinearProgress } from '@material-ui/core';
import {
    PagingState,
    CustomPaging,
  } from '@devexpress/dx-react-grid';
  import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
  } from '@devexpress/dx-react-grid-material-ui';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { NotControlledDisplayProps } from '../../commontypes';
import { getPaginatedUserQRCodeUsageHistory } from '../../api/admin'
import { handleApiError } from '../../utils';
import MyTypes from 'MyTypes'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%',
        paddingTop: '3%',
        paddingBottom: '3%'
    },
    
}
type Props = {
    classes: {
        container: string,
        paper: string
    },
    userId: number|string
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage
        },
        dispatch
    )


type RealProps = RouteComponentProps & Props & NotControlledDisplayProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class QRCodeUsageHistoryDisplay extends React.Component<RealProps> {
    state = {
        page: 1,
        perPage: 10,
        totalCount: undefined,
        data: Array<QRCodeUsageHistory>(),
        loading: false
    }

    componentDidMount() {
        this.getNext()
    }

    onPageSizeChange = (pageSize: number) => {
        this.setState({
            perPage: pageSize
        })
    }

    onChangeCurrentPage = (page: number) => {
        this.setState({
            page
        })
    }

    getNext = () => {
        console.log('ShippingOrderDisplay get Next')
        this.setState({loading: true})
        getPaginatedUserQRCodeUsageHistory(
            this.props.userId,
            this.state.page,
            this.state.perPage
        ).then((resp) => {
            let paginatedQRCodeUsageHistory = resp.data.message
            console.log('paginatedQRCodeUsage:', paginatedQRCodeUsageHistory)
            if (paginatedQRCodeUsageHistory != null) {
                this.setState({
                    data: paginatedQRCodeUsageHistory.items,
                    totalCount: paginatedQRCodeUsageHistory.itemCount * paginatedQRCodeUsageHistory.pages
                })
            }
            this.setState({
                loading: false
            })
        }).catch((error) => {
            console.log('QRCodeUsageHistoryDisplay fetch error', error)
            handleApiError(error, this.props.showMessage)
            this.setState({
                loading: false
            })
        })
    }

    render() {
        let { columns } = this.props;
        let { page, perPage, data, totalCount, loading } = this.state
        let { paper } = this.props.classes;
        if (data == null) {
            data = []
        }
        console.log('data', data)
        return (
            <Paper className={paper}>
                {loading ? <LinearProgress /> : null }
                <Typography variant='h6'>
                    QR Code Usage History
                </Typography>      
                <Grid
                    rows={data}
                      columns={columns}>
                    
                    <PagingState
                        defaultCurrentPage={0}
                        defaultPageSize={10}
                        currentPage={page}
                        pageSize={perPage}
                        onPageSizeChange={this.onPageSizeChange}
                        onCurrentPageChange={this.onChangeCurrentPage}
                    />
                    <CustomPaging 
                        totalCount={totalCount}
                        />
                    <Table />
                    <TableHeaderRow />
                    <PagingPanel 
                        pageSizes={[1, 10, 50, 100]}
                        />
                </Grid>

            </Paper>
        )
    }
}


export default connect( mapStateToProps, mapDispatchToProps) 
(withRouter(withStyles(styles)(QRCodeUsageHistoryDisplay)))