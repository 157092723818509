import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Business, Translations, Website } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import { ChangeSet } from '@devexpress/dx-react-grid';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { connect } from 'react-redux';
import { extractInputIntoTranslation } from '../../api';
import { groupAndExtractTranslations, groupColumnsForTranslations } from '../utils';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        paddingTop: '3%',
        marginTop: '3%',
        marginBottom: '3%'
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createBusiness: commonFeatureActions.createBusiness,
            patchBusiness: commonFeatureActions.patchBusiness
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>


class BusinessDisplay extends BaseDisplay<Business, BaseRealProps<Business>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as unknown as MyProps<Business>
            const { added, changed } = changeSet
            const data = (this as any).data
            if (changed != null) {
                for (let rowIndex in changed) {
                    let kv = changed[rowIndex]

                    groupColumnsForTranslations('name', kv)
                    groupColumnsForTranslations('description', kv)

                    const rowId = data[Number(rowIndex)].id;
                    console.log('Business kv', kv);
                    const websiteUrl = kv['websiteUrl']
                    const websiteType = kv['websiteType']
                    let websites: Array<Website> = [];
                    if (websiteUrl != null && websiteType != null) {
                        websites.push({
                            url: websiteUrl,
                            type: websiteType
                        })
                    }

                    
                    (this as any).patchBusiness({
                        businessId: rowId,
                        args: { ...kv, websites }
                    });
                }
            }
            if (added != null) {
                // TODO change it to coupon banner
                console.log('Adding Business Banner', added)
                added.forEach(raw => {
                    let nameTranslations = groupAndExtractTranslations('name', raw) as Translations
                    let descriptionTranslations = groupAndExtractTranslations('description', raw) as Translations
                    
                    let businessIconId = raw['businessIconId']

                    let alias = raw['alias']

                    console.log('business raw', raw, nameTranslations, descriptionTranslations, businessIconId)

                    let websiteUrl = raw['websiteUrl']
                    let websiteType = raw['websiteType']
                
                    let websites: Array<Website> = []
                    let args = {
                        names: nameTranslations,
                        descriptions: descriptionTranslations,
                        businessIconId,
                        alias,
                        websites
                    }
                    if (websiteUrl != null && websiteType != null) {
                        websites.push({
                            url: websiteUrl,
                            type: websiteType
                        })
                    }
                    console.log('args to create business', args)
                    props.createBusiness(args)
                });
            }
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(BusinessDisplay)))