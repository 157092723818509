import { Typography, withStyles, Button, Paper, Dialog, StyledComponentProps } from '@material-ui/core';
import MyTypes from 'MyTypes';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getSpotById, getQRCodeEntityStats } from '../../api/admin';
import { Business, Spot, QRCodeEntity, Tag, Image, Coupon, Translations } from '../../api/models';
import BusinessDisplay from '../../components/businessdisplay';
import { getDefaultControlledConfig as getBusinessConfig } from '../../components/businessdisplay/defaultconfig';
import SpotDisplay from '../../components/spotdisplay';
import { getDefaultControlledConfig as getSpotConfig } from '../../components/spotdisplay/defaultconfig';
import { commonFeatureActions } from '../../features/commonfeature';
import { handleApiError, isNumber } from '../../utils';
import { getDefaultControlledConfig as getQRCodeConfig } from '../../components/qrcodeentity/defaultconfig'
import TagDisplay from '../../components/tagdisplay'
import getTagConfig from '../../components/tagdisplay/defaultconfig'
import { getColumns as getTagColumns } from '../../components/tagdisplay/columns'
import QRCodeEntityDisplay from '../../components/qrcodeentity'
import { getColumns as getQRColumns } from '../../components/qrcodeentity/columns'
import { Column, ChangeSet } from '@devexpress/dx-react-grid';
import { getColumnsExceptDetailButton } from '../../components/spotdisplay/columns';
import ImageDisplay from '../../components/imagedisplay'
import { getColumns as getImageColumns } from '../../components/imagedisplay/columns'
import getImageConfig  from '../../components/imagedisplay/defaultconfig'
import CouponDisplay from '../../components/coupondisplay';
import { getColumns as getCouponColumns } from '../../components/coupondisplay/columns'
import getCouponConfig from '../../components/coupondisplay/defaultconfig'
import RefreshIcon from '@material-ui/icons/Refresh';
import QRCodeEntityDetailPage from "../../pages/qrcodeentitydetail/QRCodeEntityDetailPage";
import QQDialog from '../../commonui/qqdialog/qqdialog'


const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,      
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            addTagsToSpot: commonFeatureActions.addTagToSpotRequest,
            removeTagsFromSpot: commonFeatureActions.removeTagsFromSpotRequest,
            patchSpot: commonFeatureActions.patchSpotRequest
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    match: match<{spotId: string;}>,
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type States = {
    spot?: Spot,
    qrCodeColumns: Column[]
}

class SpotDetailPage extends React.Component<RealProps, States> {
    state = {
        spot: undefined,
        qrCodeColumns: getQRColumns(this.props.history, this.props.showMessage).concat({
            'name': 'Number Of Scan', getCellValue: (row: QRCodeEntity) => {
                return <Button onClick={async ()=> {
                    try {
                        let resp = await getQRCodeEntityStats(row.id)
                        let stats = resp.data.message
                        if (stats != null) {
                            alert(`Number of Scan: ${stats.numberOfScan}`)
                        }
                    } catch (error) {
                        handleApiError(error, this.props.showMessage)
                    }
                }}>Get</Button>
            }
        })
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ title: 'Spot Detail' })
        if (this.idIsOk()) {
            this.fetchAll()
        } else {
            alert("Invalid Game Id")
            this.props.history.push('/')
        }
    }

    getSpotId = () => {
        return this.props.match.params.spotId
    }

    idIsOk = () => {
        return isNumber(this.props.match.params.spotId)
    }

    fetchAll = () => {
        this.fetchSpot()
    }

    fetchSpot = () => {
        getSpotById(this.getSpotId()).then((resp) => {
            const spot = resp.data.message
            if (spot != null) {
                this.setState({
                    spot
                })
            }
        }).catch((error) => {
            handleApiError(error, this.props.showMessage)
        })
    }

    getSpotArray = () : Array<Spot> => {
        if (this.state.spot == null) {
            return []
        }
        if (this.state.spot == undefined) {
            return []
        }
        return [this.state.spot as unknown as Spot]
    }

    getBusinessArray = (): Array<Business> => {
        const spot = this.state.spot
        if (spot == null || spot == undefined) {
            return []
        }
        const businesses = (spot as unknown as Spot).businesses
        if (businesses == null || businesses == undefined) {
            return []
        }
        return businesses
    }

    getQRCodeArray =() : Array<QRCodeEntity> => {
        const spot = this.state.spot
        if (spot == null || spot == undefined) {
            return []
        }
        const qrCodeEntities = (spot as unknown as Spot).qrCodeEntities
        if (qrCodeEntities == null || qrCodeEntities == undefined) {
            return []
        }
        return qrCodeEntities
    }

    getTagArray = (): Array<Tag> => {
        const spot = this.state.spot
        if (spot == null || spot == undefined) {
            return []
        }
        const tags = (spot as unknown as Spot).tags
        return tags
    }

    getImageArray = (): Array<Image> => {
        const spot = this.state.spot
        if (spot == null || spot == undefined) {
            return []
        }
        const images = (spot as unknown as Spot).images
        
        return images == null ? [] : images
    }

    onImageCommitEditing = (changeSet: ChangeSet) => {
        const spot = this.state.spot as unknown as Spot
        if (spot == null) {
            return;
        }
        const { added } = changeSet

        if (added != null && spot.images != null) {
            let imageIds = ((this.state.spot as unknown as Spot).images as Image[]).map((image) => {
                console.log('image', image)
                return image.id.toString()
            })
            added.forEach(raw => {imageIds.push(raw.id)})

            console.log('image ids', imageIds)
            added.forEach( raw => {
                let { id } = raw;
                if (id  != null) {
                    (this.props as any).patchSpot({
                        spotId: this.getSpotId(),
                        imageIds: imageIds
                    })
                }
            })
            
        }
    }

    onTagCommitEditing = (changeSet: ChangeSet) => {
        const spot = this.state.spot as unknown as Spot
        if (spot == null) {
            return;
        }
        const { added, deleted } = changeSet
        console.log('onTag Commit Editing added', added, 'deleted', deleted)
        if (deleted != null) {
            deleted.forEach( raw => {
                let tagToBeRemove = spot.tags[raw as number]
                // TODO remove something
                console.log('Deleted raw', tagToBeRemove)
                this.props.removeTagsFromSpot({
                    spotId: spot.id,
                    tagIds: [tagToBeRemove.id]
                })
            })
        }
        if (added != null) {
            added.forEach( raw => {
                let { id, value } = raw;
                if (value != null) {
                    this.props.addTagsToSpot({
                        spotId: spot.id,
                        tagIds: [],
                        tagValues: [ value ]
                    })
                } else {
                    this.props.addTagsToSpot({
                        spotId: spot.id,
                        tagIds: [
                            id
                        ],
                        tagValues: []
                    })
                }
            })
        }
    }

    getCouponArray = () => {
        const spot = this.state.spot as unknown as Spot
        if (spot == null || spot == undefined) {
            return []
        }
        let output: Array<Coupon> = []
        if (spot.qrCodeEntities == null) {
            return []
        }
        spot.qrCodeEntities.forEach((value) => {

            if (value.rewardBundle != null) {
                output = output.concat(value.rewardBundle.coupons)
            }
            
        })

        // Filter by id
        let alreadyHaveId = new Set()
        return output.filter((item, index) => {
            if (!alreadyHaveId.has(item.id)) {
                alreadyHaveId.add(item.id)
                return true
            }
            return false
        })
    }

    takeTranslationsIntoTypos = (key: string, obj: Translations) => {
        return <Fragment>
                { obj.en ?
                <Typography>
                    {key} EN: "{obj.en}"
                </Typography> : null}
                { obj.zh_Hant ?
                <Typography>
                    {key} zh_Hant_HK: "{obj.zh_Hant_HK}"
                </Typography> : null }

                { obj.zh_Hant_HK ? 
                <Typography>
                    {key} zh_Hant: "{obj.zh_Hant}"
                </Typography> : null }
            </Fragment>
    }

    render() {
        console.log('UserDetailPage props', this.props);
        const { container, 
            history, 
        } = this.props;
        const { qrCodeColumns } = this.state
        let spots = this.getSpotArray()
        let spot0: any = null
        if (spots.length > 0) {
            spot0 = spots[0]
        }
        let spot0Keys: string[] | null = null
        
        if (spot0 != null) {
            spot0Keys = Object.keys(spot0)
        }
        console.log('spots', spots)
        console.log('spot0', spot0)
        console.log('spot0Keys', spot0Keys);
        let showMessage = this.props.showMessage;
        return (
            <div className={container}>
                <Typography variant='h6'>
                    Spot Detail
                </Typography>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.fetchAll}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>

                <Paper style={{ padding: '5px'}} elevation={1}>
                    <Typography variant="h6" component="h6">
                    Important info
                    </Typography>
                    {spot0Keys != null && spot0 != null?
                        spot0Keys.map((key) => {
                            
                            let value = spot0![key]
                            
                            if (key.includes('Translations') && value != null) {
                                
                                return this.takeTranslationsIntoTypos(key, value)
                            }
                            if (typeof value === 'object') {
                                return null
                            }
                            return (<Typography>{key}: {JSON.stringify(spot0![key] as object)}</Typography>)
                        }) : null
                    }
                </Paper>
                <SpotDisplay
                        showEdit
                        {...getSpotConfig(history)}
                        columns={getColumnsExceptDetailButton(history)}
                        onCurrentPageChange={(page: number) => {}}
                        onPageSizeChange={(perPage: number) => {}}
                        data={this.getSpotArray()}
                    />

                <BusinessDisplay
                    onCurrentPageChange={(page: number) => {}}
                    onPageSizeChange={(perPage: number) => {}}
                    {...getBusinessConfig(history)}
                    data={this.getBusinessArray()}/>

                <QRCodeEntityDisplay
                    {...getQRCodeConfig(history)}
                    columns={qrCodeColumns}
                    data={this.getQRCodeArray()}
                    />

                <TagDisplay
                    {...getTagConfig(history)}
                    showAdd
                    showDelete
                    columns={getTagColumns(history)}
                    data={this.getTagArray()}
                    onCommitEditing={this.onTagCommitEditing}
                    />

                <ImageDisplay
                    {...getImageConfig(history) }
                    showAdd
                    columns={getImageColumns(history)}
                    data={this.getImageArray()}
                    onCommitEditing={this.onImageCommitEditing}
                    />

                <CouponDisplay
                    {...getCouponConfig(history) }
                    columns={getCouponColumns(history)}
                    data={this.getCouponArray()}/>
                {/* <QQDialog
                >
                            <QRCodeEntityDetailPage qrCodeId='9' container=''/>
                </QQDialog> */}
{/* 
                <QRCodeEntityDetailPage qrCodeId='9' container=''/> */}
            </div>
        )
    }
}




export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(SpotDetailPage)))