import * as React from 'react';
import Types from 'MyTypes';
import { connect }  from 'react-redux';
import { authActions, authSelectors } from '../../features/auth'
import { bindActionCreators, Dispatch } from 'redux';
import Paper from '@material-ui/core/Paper';
import {
    RouteComponentProps
  } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { Theme, withStyles, TextField, Button } from '@material-ui/core'
import { MouseEvent } from 'react'
import AppleLogin from 'react-apple-login'


const mapStateToProps = (state: Types.RootState) => ({
    isLoggedIn: authSelectors.getIsLoggedIn(state.auth),
    authentication: authSelectors.getAuthentication(state.auth)
})

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
    bindActionCreators(
    {
        login: authActions.login,
    },
    dispatch
);



const styles = (theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    paper: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        width: '80%'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    }
})

interface MyProps {
    classes: {
        container: string;
        paper: string;
        textField: string;
    }
}

type Props = MyProps & RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class LoginPage extends React.Component<Props> {
    state = {
        loginReference: '',
        password: ''
    }

    onLoginReferenceChanged = (event: any) => {
        this.setState({
            loginReference: event.target.value
        })
    }

    handleChange = (name: string) => (event: any) => {
        this.setState({
            [name]: event.target.value
        })
    }
    onLoginButtonClicked =  ((event: MouseEvent) => {
        const username = this.state.loginReference
        const password = this.state.password
        console.log(`login username: ${username} password: ${password}`)
        this.props.login({
            emailOrAnything: username,
            password: password
        })
    }) 
    
    render() {
        const { container, paper, textField } = this.props.classes
        const { isLoggedIn, history } = this.props;
        if (isLoggedIn) {
            history.push('/')
        }
        return (
            <div className={container}>
                <Paper className={paper}>
                    <Typography>Login</Typography>
                    <form>
                        <TextField
                            id="username"
                            label="Username"
                            className={textField}
                            value={this.state.loginReference}
                            onChange={this.onLoginReferenceChanged}
                            margin="normal"
                            />
                        <TextField
                            label="Password"
                            type="password"
                            className={textField}
                            value={this.state.password}
                            onChange={this.handleChange('password')}
                            margin="normal" 
                        />

                        <Button
                            onClick={this.onLoginButtonClicked}>
                            Login
                        </Button>
                    </form>

                    <AppleLogin clientId="gg.scannow.www.debug" redirectURI="https://www.scannow.gg/redirect"/>
                </Paper>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)
        (LoginPage)
)