import React, { useEffect, useState } from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button, Paper, Grid, FormControl, InputLabel, Select, MenuItem, Card, CardContent, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import GeographicLocationDisplay from '../../components/geographiclocationdisplay/GeographicLocationDisplay'
import { getColumns as getGeographicLocationColumns } from '../../components/geographiclocationdisplay/columns'
import { ChangeSet } from '@devexpress/dx-react-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ClientTaskDisplay from '../../components/clienttaskdisplay';
import { getControlledConfig } from '../../components/clienttaskdisplay/defaultconfig';
import { ClientAction, ClientTask } from '../../api/models';
import { match } from 'react-router-dom';
import { getClientTaskByExtId, reviewClientTask } from '../../api/admin';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { action } from 'typesafe-actions';

const mapStateToProps = (state: MyTypes.RootState) => ({
    data: commonFeatureSelectors.getClientTaskData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {

            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            showMessage: commonFeatureActions.showMessage
        },
        dispatch
    )


const styles = (theme: any) => ({
    container: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    }
})

type Props = {
    container: string,
    formControl: String,
    match: match<{taskExtId: string;}>,
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>



function ClientTaskDetailPage (props: RealProps) {
    const { container, history, data, match } = props;
    const [clientTask, setClientTask] = useState<ClientTask | null | undefined>(null);


    useEffect(()=> {
        props.updateAppBarConfig({ title: 'Client Portal Detail' })
        let taskExtId = match.params.taskExtId;
        // 64c14fa0-4ab9-4560-ba55-9dc54d55a046
        if (taskExtId == null || taskExtId == '' || taskExtId.length != 36) {
            alert('Invalid Task Ext Id');
            history.push('/')
        } else {
            fetchAll(taskExtId);
        }
        console.log('on page or per Page changed taskExtId', taskExtId);
    }, [])


    const fetchAll = (extId: string) => {
        getClientTaskByExtId(extId).then((resp) => {
            let task = resp.data.message;
            setClientTask(task);
        })
    }

    let taskExtId = match.params.taskExtId

    return (
        <div className={container}>
            <Grid container direction='row'>
                <Grid item xs={12} style={{display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <Button
                        disabled={clientTask == null || clientTask.status != 'initial'}
                        style={{margin: '1em'}}
                        variant="contained"
                        color="primary"
                        onClick={()=> {
                            reviewClientTask(taskExtId, 'accept')
                            .then(
                                (resp)=> {
                                    let newClientTask = resp.data.message;
                                    console.log('newClientTask', newClientTask)
                                    setClientTask(newClientTask)
                                    props.showMessage({
                                        message: 'Done Accepting'
                                    })

                                }
                            ).catch((e)=> {
                                console.log(e)
                                props.showMessage({
                                    message: 'Failed',
                                    options: {
                                        variant: 'error'
                                    }
                                })
                            })
                            return ''
                        }}
                    >Accept</Button>

                    <Button
                        disabled={clientTask == null || clientTask.status != 'initial'}
                        style={{margin: '1em'}}
                        variant="contained"
                        color="secondary"
                        onClick={()=> {
                            reviewClientTask(taskExtId, 'reject')
                            .then(
                                (resp)=> {
                                    let newClientTask = resp.data.message;
                                    console.log('newClientTask', newClientTask)
                                    setClientTask(newClientTask)
                                    props.showMessage({
                                        message: 'Done Rejecting'
                                    })

                                }
                            ).catch((e)=> {
                                console.log(e)
                                props.showMessage({
                                    message: 'Failed',
                                    options: {
                                        variant: 'error'
                                    }
                                })
                            })
                            return ''
                        }}
                    >Reject</Button>

                </Grid>
                    

            </Grid>
            
            
            <ClientTaskDisplay
                {...getControlledConfig(history, false)}
                data={clientTask != null ? [clientTask] : []}
                >
            </ClientTaskDisplay>

            <Typography style={{marginTop: '1%'}} variant='h6'>
                Actions
            </Typography>
            {
                clientTask && clientTask.actions.map((action) => 
                (<ActionCard clientAction={action}/>))
            }
            
        </div>
    )
}

function ActionCard(props: {clientAction: ClientAction}) {
    const { clientAction } = props;

    // let kwargsStringPPrint = ''
    // if (clientAction.kwargs != null) {
    //     let value = JSON.parse(clientAction.kwargs);
    //     console.log('Parsed kwargs', value);
    // }
    

    return <Card>
        <CardContent>
            <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="actionType"
                        label="Action Type"
                        value={clientAction.actionType}
                        disabled
                        fullWidth
                        margin="normal"
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="createdAt"
                        label="Created At"
                        value={clientAction.createdAt}
                        fullWidth
                        disabled
                        margin="normal"
                        />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="id"
                        label="Id"
                        value={clientAction.id}
                        fullWidth
                        disabled
                        margin="normal"
                        />
                </Grid>



                <Grid item xs={12} md={6}>
                    <TextField
                        id="objectId"
                        label="Object Id"
                        value={clientAction.objectId}
                        fullWidth
                        disabled
                        margin="normal"
                        />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="objectType"
                        label="Object Type"
                        value={clientAction.objectType}
                        fullWidth
                        disabled
                        margin="normal"
                        />
                </Grid>
                <Grid item xs={12}>
                    {clientAction.kwargs != null &&
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {clientAction.kwargs}
                        </SyntaxHighlighter>
                    }
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientTaskDetailPage)))