import { withStyles } from '@material-ui/core';
import { QRPeriod } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';
import { nullStringInMapToNull } from '../../utils';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            patchQRPeriod: commonFeatureActions.patchQRPeriod
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class QRPeriodDisplay extends BaseDisplay<QRPeriod, MyProps<QRPeriod>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onRowEdited: (row: any, props: MyProps<QRPeriod>, entity: any) => {
            let entityId = entity.id;
            console.log('entityId', entityId, row)
            nullStringInMapToNull(row);
            // props.pa
            // TODO patch the qr period
            props.patchQRPeriod({id: entityId, args: row})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(QRPeriodDisplay)))