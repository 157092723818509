import { RootAction, RootState, Services } from 'MyTypes';
import { 
  // combineEpics,
   Epic } from 'redux-observable';
import { debounceTime, tap, ignoreElements } from 'rxjs/operators';

import { saveState } from '../../services/local-storage-service';

const SAVING_DELAY = 1000;

// persist state in local storage every 1s
export const saveStateInLocalStorage: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, store) =>
  action$.pipe(
    debounceTime(SAVING_DELAY),
    tap(action => {
      // handle side-effects
      console.log('save state in local storage', store.value)
      saveState(store.value);
    }),
    ignoreElements()
  );

  // comment out last line so that we know that this has no effect
// export const epics = combineEpics(saveStateInLocalStorage);

