import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureSelectors, commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getColumns } from '../../components/tagdisplay/columns'
import TagDisplay from '../../components/tagdisplay';
import { ChangeSet } from '@devexpress/dx-react-grid';
import RefreshIcon from '@material-ui/icons/Refresh';

const mapStateToProps = (state: MyTypes.RootState) => ({
    tagsData: commonFeatureSelectors.getTagData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getPaginatedTag: commonFeatureActions.getPaginatedTagRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            createTag: commonFeatureActions.createTag
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


class TagPage extends React.Component<RealProps> {
    state = {
        page: 0,
        perPage: 10,
        addedRows: []
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ 
            title: 'Tag'
        })
        this.getNext()
    }

    refresh = () => {
        this.getNext()
    }

    onCurrentPageChange = (currentPage: number) => {
        console.log('onChange Current Page', currentPage)
        this.setState({
            page: currentPage
        }, ()=> {
            this.getNext()
        })
    }

    getNext = () => {
        console.log('Tag get Next')
        this.props.getPaginatedTag({
            page: this.state.page + 1,
            perPage: this.state.perPage
        })
    }

    onPageSizeChange = (pageSize: number) => {
        this.setState( { perPage: pageSize, page: 0 }, ()=> { this.getNext() })
    }

    onAddedRowsChange = (rows: any[]) => {
        console.log('onAddedRowsChange', rows)
        let newArray = rows
        this.setState({
            addedRows: newArray
        })
    }

    onCommitEditing = (changeSet: ChangeSet) => {
        const { added } = changeSet
        if (added != null) {
            console.log('Adding Tag', added)
            added.forEach(raw => {
                console.log('Tag', raw)

                this.props.createTag({
                    value: raw.value
                })
            });
        }
    }

    render() {
        const { container, history, tagsData: { cached } } = this.props;
        const { addedRows, page, perPage } = this.state
 
        return (
            <div className={container}>
                <Button
                    onClick={this.refresh}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>
                <TagDisplay
                    showAdd
                    onCommitEditing={this.onCommitEditing}
                    onAddedRowsChange={this.onAddedRowsChange}
                    addedRows={addedRows}
                    onPageSizeChange={this.onPageSizeChange}
                    onCurrentPageChange={this.onCurrentPageChange}
                    columns={getColumns(history)}
                    page={page}
                    perPage={perPage}
                    data={cached != null? cached.items : null}
                    totalCount={cached != null ? cached.total : undefined}
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(TagPage)))