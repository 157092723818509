import { withStyles } from '@material-ui/core';
import { ClientTask, Location } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { extractInputIntoTranslation } from '../../api';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            
        },
        dispatch
    )

type MyProps<A> = BaseRealProps<A> & ReturnType<typeof mapDispatchToProps>

class ClientTaskDisplay extends BaseDisplay<ClientTask, MyProps<ClientTask>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        header: 'Client Task',
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ClientTaskDisplay)))