import React from 'react'
import { singleItemDefaultConfig } from '../singleitemdisplay/SingleItemDisplay'
import { getColumns as getPrizeColumns } from '../prize/columns'
import prizeConfig from '../prize/defaultconfig'
import PrizeDisplay from '../prize'
import * as H from 'history';
import { GamePrize } from '../../api';
// import { GamePrize } from '../../api';

export default (history: H.History) => ({
    ...singleItemDefaultConfig,
    header: 'GamePrize',
    rowDetail: ({ row } : { row: GamePrize}) => {
        console.log('row detail: ', row)
        return (
            <PrizeDisplay
                {...prizeConfig}
                columns={getPrizeColumns(history)}
                showPagePanel={false}
                data={[row.prize]}
                />
        )
    }
})