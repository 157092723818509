import React, { RefObject, ChangeEvent } from 'react';

const css = require('./DropZone.css')

type Props = {
    disabled?: boolean,
    onFileAdded?: (fileList: File[]) => any
    multiple?: boolean
}

class DropZone extends React.Component<Props> {
    fileInputRef: RefObject<HTMLInputElement>;
    css: any;


    constructor(props: Props) {
        super(props)
        this.fileInputRef = React.createRef()
        this.css = css
        // console.log(css)
    }

    openFileDialog = () => {
        if (this.props.disabled) return
        const current = this.fileInputRef.current
        if (current != null) {
            console.log('file click')
            current.click()
        }
    }

    onFilesAdded = (evt: ChangeEvent<HTMLInputElement>) => {
        if (this.props.disabled) return;
        const files = evt.target.files;
        if (this.props.onFileAdded && files != null) {
            this.props.onFileAdded(this.fileListToArray(files as FileList))
        }
        
    }

    fileListToArray = (fileList: FileList): Array<File> => {
        const array = []

        for (let i = 0; i < fileList.length; i++) {
            array.push(fileList.item(i) as File)
        }
        return array
    }

    onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (this.props.disabled) return
        this.setState({
            highlight: true
        })
    }

    onDragLeave = () => {
        this.setState({highlight: false})
    }

    onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        if (this.props.disabled) return;

        // const files =event.dataTransfer.files;

        if (this.props.onFileAdded) {
            this.props.onFileAdded(this.fileListToArray(event.dataTransfer.files))
        }
        this.setState({ highlight:false})
    }

    render() {
        const { multiple } = this.props
        return (
            <div className="Dropzone"
                onClick={this.openFileDialog}
                style={{ cursor: this.props.disabled ? 'default': 'pointer'}}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                >
                <img
                    alt="upload"
                    className="Icon"
                    src="baseline-cloud_upload-24px.svg"/>
                <input
                    ref={this.fileInputRef}
                    className="FileInput"
                    type="file"
                    multiple={multiple}
                    onChange={this.onFilesAdded}/>
                <span>Select File</span>
            </div>
        )
    }
}

export default DropZone;