import slutify from 'slugify'

export const resolveWithDelay = <T>(value: T, time: number = 1000) => new Promise(
  (resolve) => setTimeout(() => resolve(value), time)
);

export const rangeQueryString = (count: number, pageNumber?: number) =>
  `limit=${count}&offset=${pageNumber ? pageNumber * count : 0}`;

export const removeKeys = <T>(payload: T, keys: Array<keyof T>) => {
  keys.forEach((key) => {
    delete payload[key];
  });

  return payload;
};

export const makeMyColumnsTranslation = (title: String, name?: String) => {
  if (name == null) {
    name = slutify(title.toLowerCase())
  }
  return [
    {
      'title': `${title} EN`,
      'name': `${name}-en`
    },
    {
      'title': `${title} ZH-Hant`,
      'name': `${name}-zh-Hant-HK`
    }
  ]
}

//latitude: "32"
// longitude: "32"
// name-en: "name1"
// name-zh-Hant-HK: "manma"

/** may not working anymore */
export const extractInputIntoTranslation = (title: string, input: any) => {
  // let columns = makeMyColumnsTranslation(name, name)

  let slugifiedName = slutify(title.toLowerCase())
  console.log('slugifiedName', slugifiedName)
  // let allNames = columns.map((value) => value.name)
  
  let output = {
    'en': input[`${slugifiedName}-en`],
    'zh_Hant_HK': input[`${slugifiedName}-zh-Hant-HK`]
  }
  return output
}

// export const extractInput