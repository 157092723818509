import { RootState, Services } from 'MyTypes';
import { Epic } from 'redux-observable';
import { tap, filter, mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs'
// import { isOfType } from 'typesafe-actions';

// import * as AuthActions from './actions';
import * as AuthConstants from './constants';
// import { login } from './actions'
import { AuthAction } from './reducer';
import * as AuthApi from '../../api/auth'
import { isOfType, 

    // ActionType 
} from 'typesafe-actions';
import { UserEntity } from '../../api/models'
import  * as authActions from './actions';
import { from } from 'rxjs';
// import { CommonAction } from '../commonfeature/reducer'
import { handleApiErrorForStream } from '../../utils';
import  * as commonFeatureActions from '../commonfeature/actions';
// import { CommonAction } from '../commonfeature/reducer'
// import { EmptyAction } from 'typesafe-actions'
// import { LOGIN } from './constants'

//  type JJAction = ActionType<typeof authActions & typeof commonFeatureActions>
//   & typeof commonFeatureActions>
// type JJAction = AuthAction & CommonAction
// contrived example!!!
export const doLogin: Epic<AuthAction, any, RootState, Services> = (
  action$,
  state$,
  { logger }
) =>
  action$.pipe(
    // filter(action => action.type === AuthConstants.LOGIN),
    filter(isOfType(AuthConstants.LOGIN)),
    tap(action => {
        logger.log('we get login action')
    }),
    mergeMap(action => {
        const { emailOrAnything, password } = action.payload
        return from(AuthApi.login({
            emailOrAnything,
            password
        })).pipe(
            map (response => {
                console.log('response', response)
                let message = response.data.message
                return authActions.setUserEntity(message as UserEntity)
            }),
            catchError((error: Error) => {
                // we have to catch early XD, otherwise this epic will be unsubscribed
                
                console.log('catchError', error)
                // return of (authActions.logout())
                return of (commonFeatureActions.showMessage(handleApiErrorForStream(error))) 
            })
        )
    })
);
