import * as H from 'history';
import { MyColumn } from '../../common/models';
import { makeMyColumnsTranslation } from '../../api';
import React from 'react';


export const getColumns =(history: H.History, withDetailNavigation: boolean = true, editMode: boolean =false): MyColumn[] => {
    let normal = [
        { title: 'Ext Id', name: 'extId' },
        { title: 'Invocator Id', name: 'invocatorId'},
        { title: 'Created At', name: 'createdAt'},
        { title: 'Status', name: 'status'},
    ]
    if (withDetailNavigation) {
        let navigationItem =  { title: 'Detail', name: 'detail', getCellValue: (row: any, columnName: String) =>{
            return (
                <a href={`/client-task/${row.extId}`}>Detail</a>
            )
        }};
        normal.splice(0, 0, navigationItem);
    }
    return normal
}