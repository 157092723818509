import React from 'react';
import { Avatar } from '@material-ui/core';
import { Spot } from '../../api';
import * as H from 'history';
import { MyColumn } from '../../common/models';
import { createColumnsForTranslations } from '../utils';


export const getColumnsExceptDetailButton = (history: H.History): MyColumn[] => [
    
    // { name: 'name_en', getCellValue: (row) => {
    //     let { name_en } = row;
    //     if (name_en  != null) {
    //         return name_en
    //     }
    //     if (row.nameTranslations) return row.nameTranslations.en 
    // }},
    // { name: 'name_zh_Hant_HK', 
    // getCellValue: (row) => { 
    //     let { name_zh_Hant_HK } = row
    //     if (name_zh_Hant_HK != null) {
    //         return name_zh_Hant_HK
    //     }
    //     if (row.nameTranslations) return row.nameTranslations.zh_Hant_HK
    // }},
    // { name: 'name_zh_Hant', 
    // getCellValue: (row) => {
    //     if (row.name_zh_Hant != null) {
    //         return row.name_zh_Hant
    //     }
    //     if (row.nameTranslations) return row.nameTranslations.zh_Hant_HK
    // }},
    { title: 'id', name: 'id' },
    ...createColumnsForTranslations('Name', 'name'),
    { title: 'Active', name: 'active', getCellValue: (row) => { return row.active.toString()}, editable: true},
    ...createColumnsForTranslations('Available Period Description', 'availablePeriodDescription'),
    { title: 'Period', name: 'period'
    , getCellValue: (row) => { 
        let { period } = row
        console.log('period', period)
        if (period != null) {
            return row.period
        }
        if (row.allowedPeriodDescriptionTranslations != null) return row.allowedPeriodDescriptionTranslations.en
    }
    , editable: true},
    { title: 'Business1', name: 'business1', getCellValue: (row: Spot) => { 
        if (row.businesses != null && row.businesses.length > 0) 
            return row.businesses[0].name
    }},
    { title: "Business Ids", name: 'businessIds', getCellValue: (row) => {
        if (row.businessIds != null) return row.businessIds
        return row.businesses.map((bus: any) => bus.id).reduce((one: any, two: any) => { 
            if (one == '') {
                return two
            }
            return one + "," + two
        }, '' )
    }},
    { title: 'Category Id (Obsolete)', name: 'categoryId', getCellValue: (row) => {
        return row.categoryId
    }},
    { title: 'Category (Obsolete)', name: 'category', getCellValue: (row: Spot) => { 
        if (row.category == null) {
            return;
        }
        return row.category.name 
    }},
    ...createColumnsForTranslations('Description', 'description'),
    { title: 'Description', name: 'Description', getCellValue: (row: Spot) => { if (row.descriptionTranslations != null) return row.descriptionTranslations.en }},
    { title: 'District', name: 'district', getCellValue: (row: Spot) => { 
        if (row.district != null) {
            return row.district.name    
        }
        return null
    }},
    { title: 'Location Id', name: 'locationId', getCellValue: (row: Spot) => { return row.locationId }},
    { title: 'location', name: 'location', getCellValue: (row: Spot) => { 
        if (row.location != null) {
            return row.location.name    
        }
    }},
    { title: 'Lattitude', name: 'Latitude', getCellValue: (row: Spot) => {
        if (row.location != null) {
            return row.location.latitude
        }
    }},
    { title: 'Longitude', name: 'Longitude', getCellValue: (row: Spot) => {
        if (row.location != null) {
            return row.location.longitude
        }
    }},
    { title: 'Phone Number Id', name: 'phoneNumberId', getCellValue: (row: any) => {
        return row.phoneNumberId;
    }},
    { name: 'Phone Number', getCellValue: (row: Spot) => { 
        if (row.phoneNumber) {
            const ph = row.phoneNumber
            return `${ph.countryCode} ${ph.subscriberNumber}`
        }
    }},
    { name: 'region', getCellValue: (row: Spot) => {
        if (row.region != null) {
            return row.region.name
        }
    }},
    { name: 'No. QR', getCellValue: (row: Spot) => {
        if (row.qrCodeEntities != null) {
            return row.qrCodeEntities.length
        }
    }},
    { title: 'Listing Image Id', name: 'squareImageId', getCellValue: (row) => {
        
        return row.squareImageId
    }},
    { name: 'Listing Image', getCellValue: (row: Spot) => {
        return (<Avatar alt="Remy Sharp" src={row.squareImage != null ? row.squareImage.url : undefined}/>)
    }},
    { title: 'Image Ids', name:'imageIds', getCellValue: (row) => {
        console.log(row)
        if (row.imageIds!=null) return row.imageIds
        const output = (row.images as any).map((image: any)=> image.id.toString()).reduce((one: any, two: any) => { 
            if (one == '') {
                return two
            }
            return one + "," + two
        }, '')
        return output
    }},
    { name: 'Detail Image', getCellValue: (row: Spot) => {
        return (<Avatar alt="Remy Sharp" src={row.images != null  && row.images.length> 0 ? row.images[0].url : undefined}/>)
    }},
    { title: 'Website Url Id', name: 'websiteUrlId', editable: true},
    {
        name:'Website Url', getCellValue: (row: Spot) => {
            if (row.websiteUrl == null) {
                return null
            }
            return row.websiteUrl.value
        }
    },
    { title: 'Instagram Url Id', name: 'instagramUrlId', editable: true},
    { title: 'Instagram Url', name: 'insgramUrl', 
        getCellValue: (row:Spot)=> { if (row.instagramUrl == null) return; return row.instagramUrl.value}},
    {title: 'Facebook Url Id', name: 'facebookUrlId', editable: true},
    { title: 'Facebook url', name: 'facebookUrl', getCellValue: (row: Spot) => { if (row.facebookUrl == null) return; return row.facebookUrl.value;}}

]


const getColumns = (history: H.History, includeDetailButton: boolean = true): MyColumn[] => 
{
    const output : MyColumn[] = [
        
        ...getColumnsExceptDetailButton(history)
    ];

    if (includeDetailButton) {
        output.unshift({ name: 'Detail', getCellValue: (row: any)=> {
            const path = `/spot/${row.id}`
            return (
                <a target="_blank" href={path}>Detail</a>
                // <IconButton onClick={()=> {
                //         history.push(`/spot/${row.id}`)
                //     }}>
                //     <NavigationIcon fontSize="small" />
                // </IconButton>
            )
        }})    
    }
    return output
}


export default getColumns