import React from 'react';
// import { Column } from '@devexpress/dx-react-grid';
import { IconButton} from '@material-ui/core';
import { Business, ShippingOrder } from '../../api';
import * as H from 'history';
import NavigationIcon from '@material-ui/icons/Navigation';
import { shippingOrderStateToName, ShippingOrderState } from '../../common/constants';
import { prop } from '../../utils';
import { MyColumn } from '../../common/models';


export const getColumns = (history: H.History, includeDetail: boolean = false) : MyColumn[] => 
{

    const output : MyColumn[]= [
        { title: 'Id', name: 'id', },
        { title: 'User Id', name: 'userId'},
        { title: 'Given Name', name: 'givenName'},
        { title: 'Last Name', name: 'lastName'},
        { title: 'Address', name: 'address'},
        { title: 'Email', name: 'email'},
        { name: 'Game Point', getCellValue: (row: ShippingOrder) => {
            if (row.gamePrizeRedemptionHistory != null) {
                return row.gamePrizeRedemptionHistory.gamePointSpent
            }
        }},
        { name: 'S Point', getCellValue: (row: ShippingOrder) => {
            if (row.prizeRedemptionHistory != null) {
                return row.prizeRedemptionHistory.creditSpent
            }
        }},
        
        { title: 'Phone Number', name: 'Phone Number', getCellValue: (row: ShippingOrder) => {
            const { countryCode, subscriberNumber } = row.phoneNumber
            return `${countryCode} ${subscriberNumber}`
        }},
        { title: 'Prize Id', name: 'Prize Id', getCellValue: (row:ShippingOrder) => {
            return row.prize.id
        }},
        { title: 'Prize Name', name: 'Prize Name', getCellValue: (row: ShippingOrder) => {
            return row.prize.name
        }},
        { title: 'Shipping Method', name: 'Shipping Method', getCellValue: (row:ShippingOrder) => {
            return row.shippingMethod.name
        }},
        { title: 'Status', name: 'status', getCellValue: (row: ShippingOrder) => {
            return prop(shippingOrderStateToName, row.status as ShippingOrderState)
        }, filterable: true, editable:true},
    ]
    if (includeDetail) {
        output.unshift({ name: 'Detail', getCellValue: (row: Business)=> {
            return (
                <a target="_blank" href={`/shipping-order/${row.id}`}>Detail</a>
            )
        }})
    }
    
    return output
}
