import { RootAction, RootState, Services } from 'MyTypes';
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import { composeEnhancers } from './utils';
import rootReducer from './root-reducer';
import rootEpic from './root-epic';
import services from '../services';
import { loadState } from '../services/local-storage-service'
import  { tokenStorer } from '../api/agent'

export const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({
  dependencies: services,
});

// configure middlewares
const middlewares = [epicMiddleware];
// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));


// rehydrate state on app start
const stateFromStorage = loadState<RootState>()
const initialState = stateFromStorage != null ? stateFromStorage : {}

if ((initialState as RootState).auth != null) {
  let auth = (initialState as RootState).auth
  if (auth.authentication.token != null) {
    tokenStorer.token = auth.authentication.token
  }
}
console.log('tokenStorer', tokenStorer)

// create store
const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

// export store singleton instance
export default store;
