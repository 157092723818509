import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import MyAppBar from './commonui/myappbar'
import MyDrawer from './commonui/drawer'
import TestingPage from './pages/testing-page'
import LoginPage from './pages/login'
import HomePage from './routes/Home'
import { SnackbarProvider } from 'notistack';
import SnackbarHandler from './commonui/snackhandler'
import UserPage from './pages/user'
import GamePage from './pages/game'
import SpotPage from './pages/spot'
import BusinessPage from './pages/business'
import UserDetailPage from './pages/userdetail'
import GameDetailPage from './pages/gamedetail'
import SpotDetailPage from './pages/spotdetail'
import ShippingOrderPage from './pages/shippingorder'
import BusinessDetailPage from './pages/businessdetail'
import UploadPhotoPage from './pages/uploadphoto'
import NotifyUserPage from './pages/notifiyuser'
import PhoneNumberPage from './pages/phonenumber'
import MixedBannerPage from './pages/mixedbanner';
import { Path } from './common/constants'
import NotFoundPage from './pages/notfound'
import GeographicLocationPage from './pages/geographiclocation'
import GameBannerPage from './pages/gamebanner'
import CouponBannerPage from './pages/couponbanner'
import MajorBannerPage from './pages/majorbanner'
import SpotBannerPage from './pages/spotbanner'
import RewardBundlePage from './pages/rewardbundle'
import CouponPage from './pages/coupon'
import QRCodeEntityPage from './pages/qrcodeentity'
import QRCodeEntityDetailPage from './pages/qrcodeentitydetail';
import ClientReportPage from './pages/clientreport';
import CreateArticlePage from './pages/article'
import PrizeBannerPage from './pages/prizebanner'
import PrizePage from './pages/prize'
import TagPage from './pages/tag'
import DialogHandler from './commonui/dialoghandler'
import CreditRewardPage from './pages/creditreward'
import ClientTasksPage from './pages/clientportal';
import ClientTaskDetailPage from './pages/clienttaskdetail';

class App extends Component {
  render() {
    return (
      // @ts-ignore
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
        <SnackbarHandler/>
        
        <Router>
          <div style={{
            padding: '100px'
          }}>
            <DialogHandler/>
            <MyAppBar/>
            <MyDrawer/>
            <Switch>
              <Route path={Path.mixedBanner} component={MixedBannerPage}/>
              <Route path={Path.clientTaskDetail} component={ClientTaskDetailPage}/>
              <Route path={Path.clientTask} component={ClientTasksPage}/>
              <Route path={Path.creditReward} component={CreditRewardPage}/>
              <Route path={Path.createArticle} component={CreateArticlePage}/>
              <Route path={Path.clientReport} component={ClientReportPage}/>
              <Route path={Path.qrCodeEntityDetail} component={QRCodeEntityDetailPage}/>
              <Route path={Path.qrCodeEntity} component={QRCodeEntityPage}/>
              <Route path={Path.coupon} component={CouponPage}/>
              <Route path={Path.rewardBundle} component={RewardBundlePage}/>
              <Route path={Path.spotBanner} component={SpotBannerPage}/>
              <Route path={Path.majorBanner} component={MajorBannerPage}/>
              <Route path={Path.couponBanner} component={CouponBannerPage}/>
              <Route path={Path.prizeBanner} component={PrizeBannerPage}/>
              <Route path={Path.gameBanner} component={GameBannerPage}/>
              <Route path={Path.geographicLocation} component={GeographicLocationPage}/>
              <Route path={Path.notifyUser} exact component={NotifyUserPage}/>
              <Route path={Path.uploadPhoto} exact component={UploadPhotoPage}/>
              <Route path={Path.businessDetail} exact component={BusinessDetailPage}/>
              <Route path={Path.shippingOrder} exact component={ShippingOrderPage}/>
              <Route path={Path.spotDetail} component={SpotDetailPage}/>
              <Route path={Path.gameDetail} component={GameDetailPage}/>
              <Route path={Path.userDetail} component={UserDetailPage}/>
              <Route path={Path.business} exact component={BusinessPage}/>
              <Route path={Path.spot} exact component={SpotPage}/>
              <Route path={Path.user} exact component={UserPage}/>
              <Route path={Path.game} exact component={GamePage}/>
              <Route path={Path.home} exact component={HomePage}/>
              <Route path={Path.testing} exact component={TestingPage}/>
              <Route path={Path.login} component={LoginPage}/>
              <Route path={Path.phoneNumber} component={PhoneNumberPage}/>
              <Route path={Path.prize} component={PrizePage}/>
              <Route path={Path.tag} component={TagPage}/>
              
              <Route component={NotFoundPage}/>
              
            </Switch>
            {/* <TestingPage/> */}
          </div>
        </Router>
        </SnackbarProvider>
      </Provider>
    );
  }
}

export default App;
