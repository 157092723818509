import { Typography } from '@material-ui/core';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import MyTypes from 'MyTypes'
import { commonFeatureActions } from '../features/commonfeature';
import { connect } from 'react-redux';


const mapStateToProps = (state: MyTypes.RootState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
  bindActionCreators(
    {
      updateAppBarConfig: commonFeatureActions.updateAppBarConfig
    },
    dispatch
  )

type RealProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class HomePage extends React.Component<RealProps> {

  componentDidMount() {
    this.props.updateAppBarConfig({title: 'Home'})
  }
  
  render() {
    return (
      <div>
        <div style={{
          width: '100%',
          height: '100%'
        }}>
          <Typography>Scannow Admin Portal</Typography>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
