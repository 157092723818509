import { withStyles } from '@material-ui/core';
import { MixedBanner } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { extractInputIntoTranslation } from '../../api';
import { nullStringInMapToNull } from '../../utils';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createMixedBanner: commonFeatureActions.createMixedBanner,
            patchMixedBanner: commonFeatureActions.patchMixedBanner
        },
        dispatch
    )

type MyProps<A> = BaseRealProps<A> & ReturnType<typeof mapDispatchToProps>

class MixedBannerDisplay extends BaseDisplay<MixedBanner, MyProps<MixedBanner>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        header: 'Mixed Banner',
        onRowAdded: (row: any, props: any) => {
            
            console.log('Mixed Banner onRowAdded', row, props);
            let published = row['published']
            row['published'] = published == '1' || published == 'true' || published == 't'
            let type = row['type']
            if (type == null) {
                if (row['spotId'] != null) {
                    row['type'] = 'spot'
                } else if (row['prizeId'] != null) {
                    row['type'] = 'prize'
                } else if (row['couponId'] != null) {
                    row['type'] = 'coupon'
                }
            }
            (props as unknown as MyProps<MixedBanner>).createMixedBanner(row)
        },
        onRowEdited: (row: any, props: MyProps<MixedBanner>, entity: any) => {
            let entityId = entity.id
            nullStringInMapToNull(row);
            // for (let [k, v] of Object.entries(row)) {
            //     if (v == 'null') {
            //         row[k] = null
            //     }
            // }
            
            props.patchMixedBanner({id: entityId, args: row})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(MixedBannerDisplay)))

