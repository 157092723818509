import * as H from 'history';
import { MyColumn } from '../../common/models';
import { makeMyColumnsTranslation } from '../../api';


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal = [
        { title: 'Id', name: 'id' },
        { title: 'Name', name: 'name'},
        { title: 'Description', name: 'description'},
        { title: 'Latitude', name: 'latitude'},
        { title: 'Longitude', name: 'longitude' }
    ]
    if (editMode) {
        normal = normal.concat(makeMyColumnsTranslation('Name'))
        normal = normal.concat(makeMyColumnsTranslation('Description'))
        // normal.push({
        //     title: 'Name zh-hk', name: 'name-zh-hk' 
        // })
        // normal.push({
        //     title: 'Name en', name: 'name-en' 
        // })

        // normal.push({
        //     title: 'Description zh-hk', name: 'descripiton-zh-hk' 
        // })
        // normal.push({
        //     title: 'description en', name: 'desceription-en' 
        // })
    }
    return normal
}