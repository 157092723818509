import { singleItemDefaultConfig } from '../singleitemdisplay/SingleItemDisplay'
import * as H from 'history'
import { getColumns } from './columns'

export const getDefaultControlledConfig =  (history: H.History) => {
    const columns = getColumns(history)
    return {
        ...singleItemDefaultConfig,
        filteringEnabled: false,
        columns,
        filterColumnExtensions: columns.map((value)=> {
            if(! value.filterable) {
                return {
                    columnName: value.name,
                    filteringEnabled: false
                }
            }
            return {
                columnName: value.name,
                filteringEnabled: true
            }
        }),
        editColumnExtensions: columns.map((value)=> {
            const val = {
                columnName: value.name,
                editingEnabled: value.editable
            }
            console.log('editable', val)
            return val
        }),
        header: 'Shipping Order',
    }    
}

// olumnName: string;
//     /** Specifies whether filtering is enabled for a column. */
//     filteringEnabled: boolean;