import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getColumns } from '../../components/majorbannerdisplay/columns'
import MajorBannerDisplay from '../../components/majorbannerdisplay';
import RefreshIcon from '@material-ui/icons/Refresh';

const mapStateToProps = (state: MyTypes.RootState) => ({
    majorBannerData: commonFeatureSelectors.getMajorBannerData(state.commonFeatures)    
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getMajorBanners: commonFeatureActions.getPaginatedMajorBannerRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class MajorBannerPage extends React.Component<RealProps> {
    state = {
        page: 0,
        perPage: 10,
        addedRows: []
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ title: 'Major Banner' }) 
        this.getNext()
    }

    onCurrentPageChange = (currentPage: number) => {
        console.log('onChange Current Page', currentPage)
        this.setState({
            page: currentPage
        }, ()=> {
            this.getNext()
        })
    }

    getNext = () => {
        console.log('Coupon Banner get Next')
        this.props.getMajorBanners({
            page: this.state.page + 1,
            perPage: this.state.perPage
        })
    }

    onPageSizeChange = (pageSize: number) => {
        this.setState( { perPage: pageSize }, ()=> { this.getNext() })
    }

    onAddedRowsChange = (rows: any[]) => {
        console.log('onAddedRowsChange', rows)
        let newArray = rows
        this.setState({
            addedRows: newArray
        })
    }

    render() {
        const { container, history, majorBannerData: { cached } } = this.props;
        const { addedRows, page, perPage } = this.state
        console.log('addedRows from state', addedRows)
        
        return (
            <div className={container}>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.getNext}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>

                <MajorBannerDisplay
                    showEdit
                    showAdd={true}
                    onAddedRowsChange={this.onAddedRowsChange}
                    addedRows={this.state.addedRows}
                    onPageSizeChange={this.onPageSizeChange}
                    onCurrentPageChange={this.onCurrentPageChange}
                    columns={getColumns(history, addedRows.length > 0)}
                    editColumnExtensions={[{columnName: 'id', editingEnabled: false}, {columnName: 'image', editingEnabled: false}, {columnName: 'tag', editingEnabled: false}]}
                    page={page}
                    perPage={perPage}
                    data={cached != null ? cached.items : null}
                    totalCount={cached != null ? cached.total : undefined}
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(MajorBannerPage)))