import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Game } from '../../api/models';
import BaseDisplay from '../basedisplay/BaseDisplay';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}
// type Props = {
//     classes: {
//         container: string,
//         paper: string
//     },
//     columns: Column[]
// }

// type RealProps = RouteComponentProps & Props & PaginatedProps<Game>;


class GameDisplay extends BaseDisplay<Game> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        header: 'Game'
    }
}

// class GameDisplay extends React.Component<RealProps> {

//     render() {
//         let { data, columns, page, perPage,  onPageSizeChange, onChangeCurrentPage, totalCount} = this.props;
//         let { paper } = this.props.classes;
//         if (data == null) {
//             data = []
//         }
//         console.log('data', data)
//         return (
//             <Paper className={paper}>
//                 <Typography variant='h6'>
//                     Game
//                 </Typography>                
//                 <Grid
//                     rows={data}
//                       columns={columns}>
                    
//                     <PagingState
//                         defaultCurrentPage={0}
//                         defaultPageSize={10}
//                         currentPage={page}
//                         pageSize={perPage}
//                         onPageSizeChange={onPageSizeChange}
//                         onCurrentPageChange={onChangeCurrentPage}
//                     />
//                     <CustomPaging 
//                         totalCount={totalCount}
//                         />
//                     <Table />
//                     <TableHeaderRow />
//                     <PagingPanel 
//                         pageSizes={[1, 10, 50, 100]}
//                         />
//                 </Grid>

//             </Paper>
//         )
//     }
// }


export default withRouter(withStyles(styles)(GameDisplay))