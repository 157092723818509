import { withStyles } from '@material-ui/core';
import { QRCodeEntity } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createQRCodeEntity: commonFeatureActions.createQRCodeEntity,
            patchQRCodeEntity: commonFeatureActions.patchQRCodeEntity
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class QRCodeEntityDisplay extends BaseDisplay<QRCodeEntity, MyProps<QRCodeEntity>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as MyProps<QRCodeEntity>
            const { added, changed } = changeSet

            const data = (this as any).data

            if (changed != null) {
                for (let rowIndex in changed) {
                    const kv = changed[rowIndex]

                    const rowId = data[Number(rowIndex)].id;
                    console.log('QR code entity row', data[Number(rowIndex)])
                    console.log('rowId', rowId)

                    console.log('QR Code Entity kv', kv);

                    // TODO call api
                    (this as any).patchQRCodeEntity({
                        qrCodeEntityId: rowId, 
                        args: {...kv}
                    })
                }
            }

            if (added != null) {
                added.forEach(raw => {
                    let { active, startTime, endTime, qrData, rewardBundleId, spotId, tagIds} = raw;
                    active = active == '1' || active == 'true'
                    let tagIdArray = []
                    if (tagIds != null) {
                        tagIdArray = tagIds.split(',')
                    }
                    props.createQRCodeEntity({
                        active, startTime, endTime, qrData, rewardBundleId, spotId, 
                        tagIds: tagIdArray
                    })
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(QRCodeEntityDisplay)))