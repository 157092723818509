// import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import BusinessIcon from '@material-ui/icons/Business';
import FaceIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import PlaceIcon from '@material-ui/icons/Place';
import SendIcon from '@material-ui/icons/Send';
import ShippingIcon from '@material-ui/icons/LocalShipping'
import VideoGameAssetIcon from '@material-ui/icons/VideogameAsset';
import PhoneIcon from '@material-ui/icons/Phone'
import GiftIcon from '@material-ui/icons/CardGiftcard'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import BorderOuterIcon from '@material-ui/icons/BorderOuter'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import RedeemIcon from '@material-ui/icons/Redeem'
import BookIcon from '@material-ui/icons/Book'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import AssignmentIcon from '@material-ui/icons/Assignment';
import Types from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Path } from '../../common/constants';
import { drawerActions, drawerSelectors } from '../../features/drawer';
import { RouteComponentProps, withRouter } from 'react-router-dom'



// import { bindActionCreators, Dispatch } from 'redux'


// const mapStateToProps = (state.Types.)

const styles = {
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  };

type Props = {
    classes: {
        list: string;
        fullList: string
    },
    opened: boolean;
}

const mapStateToProps = (state: Types.RootState) =>({
  opened: drawerSelectors.getOpened(state.drawers)
})

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
  bindActionCreators(
    {
        close: drawerActions.closeDrawer,
        open: drawerActions.openDrawer
    },
    dispatch
);

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props & RouteComponentProps

type Item = {
  name? : string,
  icon?: React.ReactElement,
  isSeparator?: boolean,
  path: string
}

const itemList: Array<Item> = [
  {
    name: 'Home',
    icon: <HomeIcon/>,
    path: Path.home
  },
  {
    name: 'Notify User',
    icon: <SendIcon/>,
    path: Path.notifyUser
  },
  {
    name: 'Upload Photo',
    icon: <AddToPhotosIcon/>,
    path: Path.uploadPhoto
  },
  {
    name: 'Client Task',
    icon: <AssignmentIcon/>,
    path: Path.clientTask
  },
  {
    name: 'Business',
    icon: <BusinessIcon/>,
    path: Path.business
  },
  {
    name: 'Spot',
    icon: <PlaceIcon/>,
    path: Path.spot
  },
  {
    name: 'User',
    icon: <FaceIcon/>,
    path: Path.user
  },
  {
    name: 'Game',
    icon: <VideoGameAssetIcon/>,
    path: Path.game
  },
  {
    name: 'Shipping Order',
    icon: <ShippingIcon/>,
    path: Path.shippingOrder
  },
  {
    name: 'Geographic Location',
    icon: <MyLocationIcon/>,
    path: Path.geographicLocation
  },
  {
    name: 'Phone Number',
    icon: <PhoneIcon/>,
    path: Path.phoneNumber
  },
  {
    name: 'Game Banner',
    icon: <VideoGameAssetIcon/>,
    path: Path.gameBanner
  },
  {
    name: 'Coupon Banner',
    icon: <GiftIcon/>,
    path: Path.couponBanner
  },
  {
    name: 'Major Banner',
    icon: <LabelImportantIcon/>,
    path: Path.majorBanner
  },
  {
    name: 'Mixed Banner',
    icon: <LocationCityIcon/>,
    path: Path.mixedBanner
  },
  {
    name: 'Spot Banner (Obsolete)',
    icon: <LocationCityIcon/>,
    path: Path.spotBanner
  },
  {
    name: 'Prize Banner',
    icon: <RedeemIcon/>,
    path: Path.prizeBanner 
  },
  {
    name: 'Reward Bundle',
    icon: <FavoriteIcon/>,
    path: Path.rewardBundle
  },
  {
    name: 'Coupon',
    icon: <LoyaltyIcon/>,
    path: Path.coupon
  },
  { 
    name: 'QR Code Entity',
    icon: <BorderOuterIcon/>,
    path: Path.qrCodeEntity
  },
  {
    name: 'Prize',
    icon: <RedeemIcon/>,
    path: Path.prize
  },
  {
    name: 'Tag',
    icon: <LocalOfferIcon/>,
    path: Path.tag
  },
  {
    name: 'Client Data',
    icon: <BookIcon/>,
    path: Path.clientReport
  },
  {
    name: 'Create Article',
    icon: <LibraryBooksIcon/>,
    path: Path.createArticle
  },
  {
    name: 'Credit Reward',
    icon: <LoyaltyIcon/>,
    path: Path.creditReward
  },
  
]

class MyDrawer extends React.Component<CombinedProps> {

      toggle = (open: Boolean) => {
        console.log(`toggle open ${open}`)
          if (!open) {

            this.props.close()
          } else {
            this.props.open()
          }
      }



      render() {
        let { opened } = this.props;
        console.log(`MyDrawer opened ${opened}`)
          const { classes, history } = this.props
          const fullList = (
            <div className={classes.fullList}>
              <List>
                {
                  itemList.map(({name, icon, isSeparator, path})=> {
                    return (
                      <ListItem  button key={name} onClick={()=> {
                        console.log('onClick', name)
                        
                        history.push(path)
                      }}>
                        {
                          icon != undefined ? <ListItemIcon>{icon}</ListItemIcon> : null
                        }
                        <ListItemText primary={name}/>
                        
                      </ListItem>
                    )
                  }) 
                }
              </List>
            </div>
        );
            return (
                <SwipeableDrawer
                    onOpen={() => this.toggle(true)}
                    onClose={() => this.toggle(false)}
                    open={this.props.opened}
                    >
                    {fullList}
                </SwipeableDrawer>
            )


      }
  }


  export default 
  withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)
    (withStyles(styles)
      (MyDrawer)
    ))