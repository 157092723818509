import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureSelectors, commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Button, TextField, InputAdornment, IconButton, Chip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Business, Pagination } from '../../api/models'
import { getColumns } from '../../components/businessdisplay/columns'
import BusinessDisplay from '../../components/businessdisplay';
import { getDefaultControlledConfig as getBusinessConfig } from '../../components/businessdisplay/defaultconfig';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { fuzzySearchBusiness } from '../../api/admin';

const mapStateToProps = (state: MyTypes.RootState) => ({
    fetchedData: commonFeatureSelectors.getBusinessData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getPaginatedBusinessesRequest: commonFeatureActions.getPaginatedBusinessesRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            showMessage: commonFeatureActions.showMessage
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type MyState = {
    page: number,
    perPage: number,
    searchMode: boolean,
    searchTerm: string,
    searchResult?: Pagination<Business>,
    addedRows: Array<any>
}

class BusinessPage extends React.Component<RealProps, MyState> {
    state = {
        page: 0,
        perPage: 10,
        addedRows: [],
        searchMode: false,
        searchTerm: ''
    }

    searchRef: React.RefObject<HTMLInputElement>;

    constructor(props: RealProps) {
        super(props);
        this.searchRef  = React.createRef();
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'Business'
        })
        this.getNext()
    }

    getNext = ()=> {
        console.log(`SpotPage getNext: page: ${this.state.page} perPage: ${this.state.perPage}`)
        const { searchMode, searchTerm } = this.state;
        if (!searchMode) { 
            this.props.getPaginatedBusinessesRequest(
                {
                    page: this.state.page + 1,
                    perPage: this.state.perPage
                }
            )
        } else {
            fuzzySearchBusiness({
                page: this.state.page + 1,
                perPage: this.state.perPage,
                word: searchTerm
            }).then((resp) => {
                this.setState({
                    searchResult: resp.data.message
                })
            })
        }
        
    }

    onChangeCurrentPage = (currentPage: number) => {
        console.log('onChangeCurrentPage currentPage', currentPage)
        this.setState({
            page: currentPage
        }, ()=> {
            this.getNext()
        })
        
    }

    onPageSizeChange = (pageSize: number) => {
        console.log('onPageSizeChange pageSize', pageSize)
        this.setState({
            perPage: pageSize
        }, ()=> {
            this.getNext()
        })
        
    }

    onAddedRowsChange = (rows: any[]) => {
        this.setState({addedRows: rows})
    }

    searchIt = () => {
        const searchValue = this!!.searchRef!!.current!!.value
        console.log('Search it !!', searchValue)
        // TODO call api here
        if (searchValue == '') {
            this.props.showMessage({
                message: 'Search Term cannot be empty'
            })
            return
        }
        this.setState({
            page: 0,
            searchMode: true,
            searchTerm: searchValue
        }, () => {
            this.getNext()
        })
        
    }

    onRemoveSearchTerm = () => {
        this.setState({
            page: 0,
            searchMode: false,
            searchTerm: ''
        }, ()=> {
            this.getNext()
        })
    }

    render() {
        const { container, fetchedData, history } = this.props;
        let data : Business[] = []
        let totalCount = undefined
        const { searchMode, searchResult, searchTerm, page, perPage, addedRows } = this.state as MyState
        
        if (searchMode && searchResult != null) {
            data = searchResult!!.items!!
            totalCount = searchResult!!.total
        } else if (fetchedData.cached != null && fetchedData.cached.items != null) {
            data = fetchedData.cached.items
            totalCount = fetchedData.cached.itemCount * fetchedData.cached.pages
        }

        console.log('searchResult', searchResult, data)
        return (
            <div className={container}>
                
                <div style={{
                    marginTop: '1vw',
                    marginBottom: '1vw'
                }}>
                    <Button
                    style={{
                        marginBottom: '10px'
                    }}
                    onClick={this.getNext}
                    variant="contained"
                    color="primary"
                    ><RefreshIcon /> Refresh </Button>
                    <TextField
                        style={{marginLeft: '1vw'}}
                        label="Search"
                        id="searchIt"
                        inputRef={this.searchRef}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={this.searchIt}
                                    >
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }}
                        >

                    </TextField>
                    {
                        searchMode ? 
                        (
                        <Chip
                            label={searchTerm}
                            clickable
                            color="primary"
                            onDelete={this.onRemoveSearchTerm} />
                            ) 
                        :
                        null
                    }
                </div>
                <BusinessDisplay 
                    showAdd
                    showEdit
                    {...getBusinessConfig(history)}
                    isLoading={fetchedData.loading}
                    showPagePanel={true}
                    page={page}
                    perPage={perPage}
                    onAddedRowsChange={this.onAddedRowsChange}
                    addedRows={addedRows}
                    columns={getColumns(history, addedRows.length > 0)}
                    onCurrentPageChange={this.onChangeCurrentPage}
                    onPageSizeChange={this.onPageSizeChange}
                    totalCount={totalCount}
                    data={data}/>
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessPage)))