// import { UserEntityResponse } from './models';
import agent from './agent';
// import { AxiosRequestConfig } from 'axios'

export const login = async ({emailOrAnything, password} : {emailOrAnything: string, password: string}) => {
    return agent.post('/admin/wildcard-login', {
        loginReference: emailOrAnything,
        password
    }, {
        headers: {
            'lalal': 'cccc'
        }
    })
}