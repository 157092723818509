import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureSelectors, commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Game } from '../../api/models'
import GameDisplay from '../../components/gamedisplay/GameDisplay';
import { getColumns as getGameColumns } from '../../components/gamedisplay/columns'
import RefreshIcon from '@material-ui/icons/Refresh';

const mapStateToProps = (state: MyTypes.RootState) => ({
    gamesData: commonFeatureSelectors.getGamesData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getPaginatedGamesRequest: commonFeatureActions.getPaginatedGamesRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


class GamePage extends React.Component<RealProps> {
    state = {
        page: 1,
        perPage: 10,
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({ 
            title: 'Game'
        })
        this.getNext()
    }

    getNext = ()=> {
        console.log(`UserPage getNext: page: ${this.state.page} perPage: ${this.state.perPage}`)
        this.props.getPaginatedGamesRequest(
            {
                page: this.state.page,
                perPage: this.state.perPage
            }
        )
    }

    onChangeCurrentPage = (currentPage: number) => {
        console.log('onChangeCurrentPage currentPage', currentPage)
        this.setState({
            page: currentPage + 1
        }, ()=> {
            this.getNext()
        })
        
    }

    onPageSizeChange = (pageSize: number) => {
        console.log('onPageSizeChange pageSize', pageSize)
        this.setState({
            perPage: pageSize
        }, ()=> {
            this.getNext()
        })
        
    }

    render() {
        const { container, gamesData, history } = this.props;
        let data : Game[] = []
        let totalCount = undefined
        if (gamesData.cached != null && gamesData.cached.items != null) {
            data = gamesData.cached.items
            totalCount = gamesData.cached.itemCount * gamesData.cached.pages
        }
        return (
            <div className={container}>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.getNext}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>

                <GameDisplay
                    isLoading={gamesData.loading}
                    onCurrentPageChange={this.onChangeCurrentPage}
                    onPageSizeChange={this.onPageSizeChange}
                    totalCount={totalCount}
                    columns={getGameColumns(history)}
                    data={
                        data
                }/>
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(GamePage)))