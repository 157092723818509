import * as H from 'history';
import { MyColumn } from '../../common/models';
import React from 'react'



export const getColumns = (history: H.History): MyColumn[] => {
    let output: MyColumn[] = [
        { 'title': 'Id', name: 'id', editable: false },
        { title: 'Type', name: 'type' },
        { title: 'Value Int', name: 'valueInt' },
        { title: 'Value Operator', name: 'valueOperator' }
    ]
    return output
}


// class RuleConditionSchema(IdSchema):
//     scanRuleId = fields.Int(attribute='scan_rule_id')
//     type = fields.String(default=RuleCondition.TYPE_NO_OF_SCAN)
//     valueInt = fields.Integer(attribute='value_int')
//     valueOperator = fields.String(attribute='value_operator')

//     class Meta:
//         strict = True
