import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, withStyles, Button, Paper, Dialog, StyledComponentProps } from '@material-ui/core';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ReactComponent } from '*.svg';





type DialogTitlePros = {
    onClose: any,
    classes: {
        root?: string,
        closeButton?: string    
    }
}
// const StyledDialogTitle = withStyles(theme => ({
//     root: {
//       borderBottom: `1px solid ${theme.palette.divider}`,
//       margin: 0,
//       padding: theme.spacing.unit * 2,
//     },
//     closeButton: {
//       position: 'absolute',
//       right: theme.spacing.unit,
//       top: theme.spacing.unit,
//       color: theme.palette.grey[500],
//     },
//   }))((props: DialogTitlePros) => {
//     const { onClose, classes } = props;
//     // const classes = (props as any).classes
//     const children = (props as any).children
//     var root = undefined
//     if (classes != null) {
//         root = classes.root
//     }
//     return (
//       <MuiDialogTitle disableTypography className={root}>
//         <Typography variant="h6">{children}</Typography>
//         {onClose ? (
//           <IconButton aria-label="Close" className={(classes as any).closeButton} onClick={onClose}>
//             <CloseIcon />
//           </IconButton>
//         ) : null}
//       </MuiDialogTitle>
//     );
//   });



class DialogTitle extends React.Component<DialogTitlePros>  {

    render() {
        const { onClose, children, classes } = this.props;
        // val classes = this.props.classes
        // const classes = (props as any).classes
        // const children = (props as any).children
        // var root = undefined
        // if (classes != null) {
        //     root = classes.root
        // }
        console.log('Dialog title props', this.props)
        return (
            <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>  
        )
    }
}

const StyledDialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(DialogTitle);

type MyState = {

}

type SomeProps = {
    title: string | undefined,
    toggleDialogOpen: () => void,
    open: boolean
}

class QQDialog extends React.Component<SomeProps, MyState> {

    
    onCloseClicked = () => {
        // this.setState({
        //     open: !this.state.open
        // })
        this.props.toggleDialogOpen()
    }

    render() {
        let name = this.props.title
        return (
            <Dialog
                maxWidth='xl'
                onClose={()=> {console.log('Dialog on close')}}
                open={this.props.open}
                aria-labelledby="form-dialog-title"
                >
                    <StyledDialogTitle
                        onClose={this.onCloseClicked}>
                        {name}
                    </StyledDialogTitle>
                    <MuiDialogContent>
                      {this.props.children}
                    </MuiDialogContent>
                </Dialog>
        )
    }
}

export default QQDialog