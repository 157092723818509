import React from 'react';
import { IconButton, Avatar } from '@material-ui/core';
import { Business, makeMyColumnsTranslation, Website } from '../../api';
import * as H from 'history';
import NavigationIcon from '@material-ui/icons/Navigation';
import { MyColumn } from '../../common/models';
import { createColumnsForTranslations } from '../utils';


export const getColumnsExceptDetailButton = (history: H.History) : MyColumn[] => [
    { title: 'Id', name: 'id', editable: false},
    ...createColumnsForTranslations('Name', 'name'),
    ...createColumnsForTranslations('Description', 'description'),
    { title: 'Alias', name: 'alias', editable: true},
    // { title: 'Description', name: 'description', editable: false},
    { name: 'Business Icon', getCellValue: (row: Business) => {
        return (<Avatar alt="Remy Sharp" src={row.businessIcon != null ? row.businessIcon.url : undefined}/>)
    }, editable: false},
    { name: 'No. Branch', getCellValue: (row: Business) => {
        if (row.businessBranches != null) {
            return row.businessBranches.length
        }
        
    }, editable: false},
    {
        title: 'Facebook', name:'facebook', editable: false, getCellValue: (row) => {
            let websites:Array<Website> = row['websites']
            // let ss = websites.filter((value, index, array)=> {
            //     if (value.type == 'facebook') {
            //         return true
            //     }
            //     return false
            // })
            if (websites == null) {
                return null
            }
            let website = websites.find((value, index, array) => {
                if (value.type == 'facebook') {
                    return true
                }
                return false
            })

            if (website == null) {
                return <a/>
            } else {
                return <a target="_blank" href={website.url}>Facebook</a>
            }
        }
    },
    {
        title: 'Official', name:'official', editable: false, getCellValue: (row) => {
            let websites:Array<Website> = row['websites']
            if (websites == null) {
                return null
            }
            let website = websites.find((value, index, array) => {
                if (value.type == 'official') {
                    return true
                }
                return false
            })

            if (website == null) {
                return <a/>
            } else {
                return <a target="_blank" href={website.url}>Official</a>
            }
        }
    },
    { title: 'Website Url', name:'websiteUrl' },
    { title: 'Website Type', name: 'websiteType'}
]

export const getDetailButton = (history: H.History) : MyColumn[] => [
    { name: 'Detail', getCellValue: (row: Business)=> {
        return (
            <a target="_blank" href={`/business/${row.id}`}>Detail</a>
        )
    }, editable: false}
]

export const getColumns = (history: H.History, editMode: boolean = false) : MyColumn[] => {
    let output : MyColumn[] = [
        ...getDetailButton(history),
        ...getColumnsExceptDetailButton(history)
    ]

    // let columns = getColumnsExceptDetailButton(history)
    // for (let column of columns) {
    //     if (editMode) {
    //         if (column.editable == true) {
    //             output.push()
    //         }
    //     }
        
    // }

    output.push({
        title: 'Business Icon Id', name: 'businessIconId'
    })
    if (editMode) {
        output = output.concat(
           makeMyColumnsTranslation('Name')
        )

        output = output.concat( makeMyColumnsTranslation('description'))

        // output = output.concat(
        //     {
        //         title: 'Website Url',
        //         name: 'websiteUrl'
        //     }
        // )
        // output = output.concat(
        //     {
        //         title: 'Website type',
        //         name: 'websiteType'
        //     }
        // )
        
    }
    // output = output.filter((value, index, array) => {
    //     if (value.editable == false && editMode) {
    //         return false
    //     }
    //     return true
    // })
    console.log('column output', output)
    return output
}