import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
// import { Game } from '../../api/models';
import { match } from 'react-router-dom'
import { isNumber, handleApiError } from '../../utils';
import GameDisplay from '../../components/gamedisplay'
import { getColumns as getGameColumns } from '../../components/gamedisplay/columns'
import { getGameById, getGameStats } from '../../api/admin';
import { Game, GameStats } from '../../api/models';
import GameBannerDisplay from '../../components/gamebannerdisplay'
import{ getColumns as getGameBannerColumns } from '../../components/gamebannerdisplay/columns'
import GamePrizeDisplay from '../../components/gameprize'
import getGamePrizeDefaultConfig from '../../components/gameprize/defaultconfig';
import { getColumns as getGamePrizeColumns } from '../../components/gameprize/columns'
import GameStatsDisplay from '../../components/gamestats/GameStatsDisplay';
import { getColumns as getGameStatsColumns } from '../../components/gamestats/columns'
import { getControlledDefaultConfig as getGameStatsControlledConfig } from '../../components/gamestats/defaultconfig'
import RefreshIcon from '@material-ui/icons/Refresh';


const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,       
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    match: match<{gameId: string;}>,
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


class GameDetailPage extends React.Component<RealProps, {
    gamePagination: {
        data: Array<Game>
    },
    gameStats: GameStats | null
}> {
    state = {
        gamePagination: {
            data: Array<Game>()
        },
        gameStats: null
    }

    componentWillMount() {
        if (!isNumber(this.props.match.params.gameId)) {
            alert("Invalid Game Id")
            this.props.history.push('/')
        }
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'Game Detail'
        })
        this.refresh()
    }

    refresh = () => {
        this.fetchGame()
        this.fetchGameStats()
    }

    idIsOk = ()=> {
        return isNumber(this.props.match.params.gameId)
    }

    fetchGameStats = ()=>{
        if (this.idIsOk()) {
            getGameStats(this.props.match.params.gameId).then((resp)=> {
                let gameStats = resp.data.message
                if (gameStats != null) {
                    this.setState({
                        gameStats
                    })
                }
            }).catch((error) => {
                handleApiError(error, this.props.showMessage)
            })
        }
    }

    fetchGame = ()=> {
        if(this.idIsOk()) {
            getGameById(this.props.match.params.gameId).then((resp)=> {
                let game = resp.data.message
                if (game != null) {
                    this.setState({
                        gamePagination: {
                            
                            data: [game]
                        }
                    })
                }
            }).catch((error) => {
                handleApiError(error, this.props.showMessage)
            })
        }
    }

    getGames() {
        return this.state.gamePagination.data
    }

    getGameBanners() {
        if (this.state.gamePagination.data.length == 0) {
            return []
        }

        if (this.state.gamePagination.data[0].gameBanners == null) {
            return []
        }
        return this.state.gamePagination.data[0].gameBanners
    }

    getGamePrizes() {
        let gamePagination = this.state.gamePagination.data
        if (gamePagination.length == 0) {
            return []
        }

        return this.state.gamePagination.data[0].gamePrizes
    }

    render() {
        console.log('UserDetailPage props', this.props);
        const { container, 
            history, 
        } = this.props;
        const { gameStats } = this.state
        return (
            <div className={container}>
                GameDetailPage
                <Button
                    style={{margin: '1em'}}
                    onClick={this.refresh}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>

                <GameDisplay
                    onCurrentPageChange={(page: number)=> {}}
                    onPageSizeChange={(perPage: number) => {}}
                    page={1}
                    perPage={1}
                    totalCount={1}
                    data={this.getGames()}
                    columns={getGameColumns(history, false)}
                    />

                <GameBannerDisplay
                    data={this.getGameBanners()}
                    columns={getGameBannerColumns(history)}
                    />

                <GamePrizeDisplay
                    {...getGamePrizeDefaultConfig(history)}
                    columns={getGamePrizeColumns(history)}
                    data={this.getGamePrizes()}
                    />

                {// ignore followng error
                }
                <GameStatsDisplay
                    {...getGameStatsControlledConfig(history)}
                    columns={getGameStatsColumns(history)}
                    data={gameStats != null ? [gameStats] : null}/> 
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(GameDetailPage)))