import React from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { IconButton} from '@material-ui/core';
import { QRCodeUsageHistory } from '../../api';
import * as H from 'history';
import NavigationIcon from '@material-ui/icons/Navigation';


export const getColumns = (history: H.History) : Column[] => [
    // { name: 'Detail', getCellValue: (row: QRCodeUsageHistory)=> {
    //     return (
    //         <a target="_blank" href={`/qr-code-usage-history/${row.id}`}>Detail</a>
    //     )
    // }},
    { title: 'Id', name: 'id', },
    { title: 'User id', name: 'User Id', getCellValue: (row: QRCodeUsageHistory) => {
        return row.user.id
    }},
    { title: 'Latitude', name: 'Latitude', getCellValue: (row: QRCodeUsageHistory) => {
        return row.userLocation.latitude
    }},
    {
        name: 'Longitude', getCellValue: (row: QRCodeUsageHistory) => {
            return row.userLocation.longitude
        }
    },
    {
        name: 'QR Code Entity Id', getCellValue: (row: QRCodeUsageHistory) => {
            return row.qrCodeEntity.id
        }
    }
    
]