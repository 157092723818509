import React from 'react';
import { CouponOwnership } from '../../api/models'
import { Paper, withStyles, Typography } from '@material-ui/core';
import {
    PagingState,
    CustomPaging,
    Column,
  } from '@devexpress/dx-react-grid';
  import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
  } from '@devexpress/dx-react-grid-material-ui';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PaginatedProps } from '../../commontypes';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%',
        paddingTop: '3%',
        paddingBottom: '3%'
    },
    
}
type Props = {
    classes: {
        container: string,
        paper: string
    },
    columns: Column[]
}


type RealProps = RouteComponentProps & Props & PaginatedProps<CouponOwnership>;

class CouponOwnershipDisplay extends React.Component<RealProps> {

    render() {
        let { data, page, perPage,  onPageSizeChange, onChangeCurrentPage, totalCount, columns} = this.props;
        let { paper } = this.props.classes;
        if (data == null) {
            data = []
        }
        console.log('data', data)
        return (
            <Paper className={paper}>
                <Typography variant='h6'>
                    Coupon Ownership
                </Typography>      
                <Grid
                    rows={data}
                      columns={columns}>
                    
                    <PagingState
                        defaultCurrentPage={0}
                        defaultPageSize={10}
                        currentPage={page}
                        pageSize={perPage}
                        onPageSizeChange={onPageSizeChange}
                        onCurrentPageChange={onChangeCurrentPage}
                    />
                    <CustomPaging 
                        totalCount={totalCount}
                        />
                    <Table />
                    <TableHeaderRow />
                    <PagingPanel 
                        pageSizes={[1, 10, 50, 100]}
                        />
                </Grid>

            </Paper>
        )
    }
}


export default withRouter(withStyles(styles)(CouponOwnershipDisplay))