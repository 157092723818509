import { Translations } from '../api/models'


const generateACell = (title: String, name: String, languageName: String) => {
    return {
        name: name + "_" + languageName,
            getCellValue: (row: any) => {
                if  (row[name + "_" + languageName] != null) {
                    return row[name + "_" + languageName]
                } else if (row[name + "Translations"] != null) {
                    const translations = (row[name + "Translations"] as any)
                    return translations["" + languageName]
                }
            },
            editable: true
    }
}

export const createColumnsForTranslations = (title: String, name: String) => {
    return [
        {
            name: name + "_en",
            getCellValue: (row: any) => {
                if  (row[name + "_en"] != null) {
                    return row[name + "_en"]
                } else if (row[name + "Translations"] != null) {
                    return row[name + "Translations"]['en']
                }
            }
        },
        generateACell(title, name, "zh_Hant_HK"),
        generateACell(title, name, "zh_Hant"),
    ]


}

export const groupAndExtractTranslations = (name: String, kv: any) => {
    const nameTag = name + 'Translations'
    
    const en = kv[name + '_en']
    const zhHantHK = kv[name + '_zh_Hant_HK']
    const zhHant = kv[name + '_zh_Hant']
    if (en == null && zhHant == null && zhHantHK == null) {
        return
    }
    return {
        'en': en,
        'zh_Hant_HK': zhHantHK,
        'zh_Hant': zhHant
    } as Translations
}

export const groupColumnsForTranslations = (name: String, changeKeyValues: any) => {
    const nameTag = name + 'Translations'
    
    const en = changeKeyValues[name + '_en']
    const zhHantHK = changeKeyValues[name + '_zh_Hant_HK']
    const zhHant = changeKeyValues[name + '_zh_Hant']
    if (en == null && zhHant == null && zhHantHK == null) {
        return
    }
    changeKeyValues[nameTag + ''] = {
        'en': en,
        'zh_Hant_HK': changeKeyValues[name + '_zh_Hant_HK'],
        'zh_Hant': changeKeyValues[name + '_zh_Hant']
    } 
    delete changeKeyValues[name + '_en']
    delete changeKeyValues[name + '_zh_Hant_HK']
    delete changeKeyValues[name + '_zh_Hant']
    return changeKeyValues
}

export const separeteCommmaStrForSomeInput = (name: String, changeKeyValues: any) => {
    var value = changeKeyValues[name + '']
    console.log('separeteCommmaStrForSomeInput value: $', value)
    if (value == '') {
        changeKeyValues[name + ''] = []
        return
    }
    if (value != null) {
        changeKeyValues[name + ''] = value.split(',')
    }
}