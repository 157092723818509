import { Button } from '@material-ui/core';
import * as H from 'history';
import { MyColumn } from '../../common/models';
import React from 'react';



export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal: MyColumn[] = [
        { title: 'Id', name: 'id', editable: false },
        { title: 'Credit Reward Id', name: "creditRewardId", getCellValue: (row) => {
            if (row.creditReward != null) {
                return row.creditReward.id;
            }
            return null
        }},
        { title: 'Action Url', name: 'actionUrl'},
        { title: 'To Be Collected', name: 'toBeCollected' },
        { title: 'Coupon ids', name: 'couponIds', getCellValue: (row) => {
            if (row.coupons != null) {
                return row.coupons.map((e: any) => e.id).toString();
            }
            return null;
        }},
        {
            title: "Coupon detail", "name": "toCouponDetail", getCellValue: (row) => {
                var path = `/coupon/${row.coupons[0].id}`
                return <Button 
                style={{textTransform: 'none'}}
                href={path}
                onClick={() => {
                    if (row.coupons != null) {
                        history.push(path)            
                    }
                }}>To Coupon</Button>
            }
        }
    ]

    if (editMode) {
        normal.push({
            title: 'Coupon Ids(, separated)',
            name: 'couponIds'
        })
    }
    return normal
}