import BaseDisplayThings from '../basedisplay'
// import { BaseConfigProps } from '../basedisplay/BaseDisplay';


export const singleItemDefaultConfig = {
    ...BaseDisplayThings.defaultDisplayConfig,
    defaultCurrentPage: 1,
    defaultPageSize: 1,
    pageSizes: [1],
    showPagePanel: false
}


export default BaseDisplayThings.default