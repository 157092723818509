import { Avatar, IconButton } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import * as H from 'history';
import React from 'react';
import { UserEntity } from '../../api';
import { MyColumn } from '../../common/models';


export const getColumns = (history: H.History): MyColumn[] => [
    { name: 'Detail', getCellValue: (row)=> {
        return (
            <a target="_blank" href={`/user/${row.id}`}>Detail</a>
        )
    }},
    { title: 'id', name: 'id', filterable: true },
    { title: 'Username', name: 'username'},
    { title: 'First Name', name: 'firstName' },
    { title: 'Last Name', name: 'lastName' },
    { title: 'Registered At', name: 'createdAt'},
    { title: 'Credit Earned', name: 'credit'},
    { title: 'Credit Spent', name: 'creditSpent'},
    { title: "Credit Balance", name: 'creditBalance'},
    { title: "Birthday", name: 'dateOfBirth'},
    { title: "Contact Email", name: "email"},
    { title: 'Gender', name: 'gender'},
    { title: 'Locale', name: 'locale'},
    { title: 'image', name: 'image', getCellValue: (row: UserEntity) => {
        if (row.profileImage != null) {
            return <Avatar alt="Remy Sharp" src={row.profileImage.url}/>
        }
    }},
    { title: 'Type', name: 'type'},
    { title: 'Allow Notification', name: '', getCellValue: (row: UserEntity)=> {
        if (row.userSettings == null) {
            return '✓'
        }
        return row.userSettings.allowNotification ? '✓': ''
    }},
    { title: 'Google Auth', name: 'Google Auth', getCellValue: (row: UserEntity)=> {
        return row.authByGoogle != null ? '✓' : ''
    }},
    {title: 'Email Auth', name: 'Email Auth', getCellValue: (row: UserEntity) => {
        return row.authByEmailPassword != null ? '✓': ''
    }},
    {name: 'Facebook Auth', getCellValue: (row) => {
        return row.authByFacebook != null ? '✓' : ''
    }}
];


export const getColumnsForSelectingUser = (history: H.History): MyColumn[] => {
    return [
        ...(getColumns(history).slice(1))
    ]
}

export default getColumns