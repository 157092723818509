import { withStyles } from '@material-ui/core';
import { PrizeBanner } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createPrizeBanner: commonFeatureActions.createPrizeBanner,
            patchPrizeBanner: commonFeatureActions.patchPrizeBanner
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class PrizeBannerDisplay extends BaseDisplay<PrizeBanner, MyProps<PrizeBanner>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as MyProps<PrizeBanner>
            const { added, changed } = changeSet
            const data = (this as any).data

            if (changed != null) {
                for (let rowIndex in changed) {
                    let kv = changed[rowIndex]

                    const rowId = data[Number(rowIndex)].id;
                    console.log('Prize banner id', rowId);

                    (this as any).patchPrizeBanner({
                        prizeBannerId: rowId,
                        args: {...kv}
                    })
                }
            }

            if (added != null) {
                // TODO change it to coupon banner
                console.log('Adding Prize Banner', added)
                added.forEach(raw => {
                    let tag = raw['tag']
                    let prizeId = raw['prizeId']
                    let imageId = raw['imageId']
                    console.log(`tag: ${tag} couponId: ${prizeId} imageId: ${prizeId}`)
                    props.createPrizeBanner({
                        tag,
                        prizeId,
                        imageId
                    })
                });
            }

        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(PrizeBannerDisplay)))