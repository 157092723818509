import { withStyles, Button } from '@material-ui/core';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { UserEntity } from '../../api/models';
import CouponOwnershipDisplay from '../../components/couponownershipdisplay';
import { getColumns as getCouponOwnershipColumns, getColumns as getUserSettingsColumns } from '../../components/couponownershipdisplay/columns';
import QRCodeUsageHistoryDisplay from '../../components/qrcodeusagehistorydisplay';
import { getColumns as getQRCodeUsageHistoryColumns } from '../../components/qrcodeusagehistorydisplay/columns';
import { getColumns as getShippingOrderColumns } from '../../components/shippingorderdisplay/columns';
import { getDefaultControlledConfig as getShippingOrderConfig } from '../../components/shippingorderdisplay/defaultconfig';
import { getControlledConfig as getUserConfig } from '../../components/userdisplay/defaultconfig'
import ShippingOrderDisplay from '../../components/shippingorderdisplay/ShippingOrderDisplay';
import UserDisplay from '../../components/userdisplay';
import { getColumnsForSelectingUser } from '../../components/userdisplay/columns';
import UserSettingsDisplay from '../../components/usersettings';
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { isNumber, notNull } from '../../utils';
import RefreshIcon from '@material-ui/icons/Refresh';


const mapStateToProps = (state: MyTypes.RootState) => ({
    userData: commonFeatureSelectors.getUserData(state.commonFeatures),
    userShippingOrdersData: commonFeatureSelectors.getUserShippingOrders(state.commonFeatures),
    userCouponOwnershipsData: commonFeatureSelectors.getUserCouponOwnershipsData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            getUserRequest: commonFeatureActions.getUserRequest,
            getUserShippingOrdersRequest: commonFeatureActions.getPaginatedUserShippingOrdersRequest,
            getUserCouponOwnershipsRequest: commonFeatureActions.getPaginatedUserCouponOwnershipRequest,
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    match: match<{userId: string;}>,
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


class UserDetailPage extends React.Component<RealProps> {
    state = {
        shippingOrderPagination: {
            page: 1,
            perPage: 10,
        },
        couponOwnershipPagination: {
            page: 1,
            perPage: 10
        }
    }

    componentWillMount() {
        console.log('userid ', this.props.match.params.userId)
        if (! isNumber(this.props.match.params.userId)) {
            alert("Invalid User Id")
            this.props.history.push('/')
        }
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({title: 'User Detail'})
        this.refresh()
    }

    refresh = ()=> {
        this.getNextUser()
        this.getNextShippingOrder()
        this.getNextCouponOwnership()
    }

    getNextUser = ()=> {
        if (isNumber(this.props.match.params.userId)) {
            this.props.getUserRequest(Number(this.props.match.params.userId))
        }
    }

    getUsers = () => {
        const { userData } = this.props;
        let users : UserEntity[] = []
        if (userData.cached != null && userData.cached != null) {
            users = [userData.cached]
        }
        return users
    }

    getNextCouponOwnership = ()=> {
        if (isNumber(this.props.match.params.userId)) {
            this.props.getUserCouponOwnershipsRequest({
                userId: Number(this.props.match.params.userId),
                page: this.state.couponOwnershipPagination.page,
                perPage: this.state.couponOwnershipPagination.perPage
            })
        }
    }

    onChangeCouponOwnershipCurrentPage = ()=> {
        this.setState({
            couponOwnershipPagination: {
                ...this.state.couponOwnershipPagination,
                page: this.state.couponOwnershipPagination.page + 1
            }
        }, ()=> {
            this.getNextCouponOwnership()
        })
    }

    onCouponOwnershipPageSizeChange = () => {
        this.setState({
            couponOwnershipPagination: {
                ...this.state.couponOwnershipPagination,
                perPage: this.state.couponOwnershipPagination.perPage + 1
            }
        }, ()=> {
            this.getNextCouponOwnership()
        })
    }

    getCouponOwnerships = ()=> {
        if (this.props.userCouponOwnershipsData.cached == null) {
            return []
        }
        return notNull(this.props.userCouponOwnershipsData.cached.items)
    }

    getTotalNumberOfCouponOwnership = () => {
        if (this.props.userCouponOwnershipsData.cached == null) {
            return 0
        }
        return this.props.userCouponOwnershipsData.cached.itemCount * this.props.userCouponOwnershipsData.cached.pages
    }

    getNextShippingOrder = () => {
        if (isNumber(this.props.match.params.userId)) {
            this.props.getUserShippingOrdersRequest({
                userId: Number(this.props.match.params.userId),
                page: this.state.shippingOrderPagination.page,
                perPage: this.state.shippingOrderPagination.perPage
            })
        }
    }

    onChangeShippingOrderCurrentPage = ()=> {
        this.setState({
            shippingOrderPagination: {
                ...this.state.shippingOrderPagination,
                page: this.state.shippingOrderPagination.page + 1
            }
        }, ()=> {
            this.getNextShippingOrder()
        })
    }

    onShippingOrderPageSizeChange = () => {
        this.setState({
            shippingOrderPagination: {
                ...this.state.shippingOrderPagination,
                perPage: this.state.shippingOrderPagination.perPage + 1
            }
        }, ()=> {
            this.getNextShippingOrder()
        })
    }

    getShippingOrders = ()=> {
        if (this.props.userShippingOrdersData.cached == null) {
            return []
        }
        return notNull(this.props.userShippingOrdersData.cached.items)
    }

    getTotalShippingOrders = () => {
        if (this.props.userShippingOrdersData.cached == null) {
            return 0
        }
        return this.props.userShippingOrdersData.cached.itemCount * this.props.userShippingOrdersData.cached.pages
    }

    render() {
        console.log('UserDetailPage props', this.props);
        const { container, history } = this.props;
        const shippingOrders =  this.getShippingOrders()
        
        return (
            <div className={container}>
                <Button
                    style={{margin: '1em'}}
                    onClick={this.refresh}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>

                <UserDisplay
                    {...getUserConfig(history)}
                    columns={getColumnsForSelectingUser(history)} 
                    onCurrentPageChange={(page: number) => {}}
                    onPageSizeChange={(pageSize: number) => {}}
                    totalCount={1}
                    data={this.getUsers()}
                    />

                <ShippingOrderDisplay
                    {...getShippingOrderConfig(history)}
                    page={this.state.shippingOrderPagination.page}
                    perPage={this.state.shippingOrderPagination.perPage}
                    onCurrentPageChange={this.onChangeShippingOrderCurrentPage}
                    onPageSizeChange={this.onShippingOrderPageSizeChange}
                    totalCount={this.getTotalShippingOrders()}
                    data={shippingOrders}
                    columns={getShippingOrderColumns(history)}
                    />

                <CouponOwnershipDisplay
                    onChangeCurrentPage={this.onChangeCouponOwnershipCurrentPage}
                    onPageSizeChange={this.onCouponOwnershipPageSizeChange}
                    totalCount={this.getTotalNumberOfCouponOwnership()}
                    data={this.getCouponOwnerships()}
                    columns={getCouponOwnershipColumns(history)}
                    />

                <QRCodeUsageHistoryDisplay userId={Number(this.props.match.params.userId)}
                   columns={getQRCodeUsageHistoryColumns(history)}/>

                <UserSettingsDisplay
                    columns={getUserSettingsColumns(history)}
                    data={this.getUsers().length > 0  && this.getUsers()[0].userSettings != null ? [this.getUsers()[0].userSettings] : undefined }
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDetailPage)))