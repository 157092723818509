import { Tag } from '../../api/models'
import { withStyles } from '@material-ui/core';
import { withRouter} from 'react-router-dom'
import BaseDisplay from '../singleitemdisplay/SingleItemDisplay';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%',
        paddingTop: '3%',
        paddingBottom: '3%'
    }
}


class TagDisplay extends BaseDisplay<Tag> {
    
    
}


export default withRouter(withStyles(styles)(TagDisplay))