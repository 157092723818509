import React from 'react';
import { UserSettings } from '../../api/models'
import { Paper, withStyles, Typography } from '@material-ui/core';
import {
    PagingState,
    CustomPaging,
    Column,
  } from '@devexpress/dx-react-grid';
  import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
  } from '@devexpress/dx-react-grid-material-ui';
import { withRouter, RouteComponentProps } from 'react-router-dom'

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%',
        paddingTop: '3%',
        paddingBottom: '3%'
    }
}
type Props = {
    classes: {
        container: string,
        paper: string
    },
    columns: Column[],
    data? : UserSettings[]
}


type RealProps = RouteComponentProps & Props

class UserSettingsDisplay extends React.Component<RealProps> {

    render() {
        let { data, columns} = this.props;
        let { paper } = this.props.classes;
        if (data == null) {
            data = []
        }
        console.log('data', data)
        return (
            <Paper className={paper}>
                <Typography variant='h6'>
                    User Settings
                </Typography>
                <Grid
                    rows={data}
                      columns={columns}>
                    
                    <PagingState
                        defaultCurrentPage={0}
                        defaultPageSize={10}
                        currentPage={1}
                        pageSize={1}
                    />
                    <CustomPaging 
                        totalCount={1}
                        />
                    <Table />
                    <TableHeaderRow />
                    <PagingPanel 
                        pageSizes={[1]}
                        />
                </Grid>

            </Paper>
        )
    }
}


export default withRouter(withStyles(styles)(UserSettingsDisplay))