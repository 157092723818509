import { withStyles } from '@material-ui/core';
import { Coupon } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';
import { groupColumnsForTranslations, separeteCommmaStrForSomeInput } from '../utils';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createCoupon: commonFeatureActions.createCoupon,
            patchCoupon: commonFeatureActions.patchCouponRequest
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class CouponDisplay extends BaseDisplay<Coupon, MyProps<Coupon>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as MyProps<Coupon>
            const { added, changed } = changeSet
            const data = (this as any).data
            if (changed != null ) {
                for (let rowIndex in changed) {
                    const kv = changed[rowIndex]

                    console.log('Coupon kv', kv)

                    const rowId = data[Number(rowIndex)].id;
                    groupColumnsForTranslations('name', kv)
                    console.log('kv after name change', kv)
                    groupColumnsForTranslations('howToUse', kv)
                    groupColumnsForTranslations('description', kv)
                    groupColumnsForTranslations('shortDescription', kv)
                    groupColumnsForTranslations('slogan1', kv)
                    groupColumnsForTranslations('slogan2', kv)
                    groupColumnsForTranslations('termAndConditions', kv)
                    
                    separeteCommmaStrForSomeInput('imageIds', kv);

                    (this as any).patchCoupon({
                        couponId: rowId,
                        args: {...kv }
                    })
                }
            }
            if (added != null) {
                // TODO change it to coupon banner
                console.log('Adding Coupon', added)
                added.forEach(raw => {
                    
                    // console.log(`isCurrent: ${isCurrent} couponId: ${couponId} imageId: ${couponId}`)
                    // raw['active'] == '1' || raw['active'] == 'true';
                    // let allowedPeriodDescription = raw['allowedPeriodDescription']

                    let { imageIds, businessBranchIds
                    } = raw;

                    // let args = { allowedPeriodEnd, allowedPeriodStart, 
                    //     expired, maximumAmount, phoneNumberId, validUntil , imageIds, businessBranchIds, squareImageId,
                    //     name, description, howToUse, shortDescription, slogan1, slogan2, termAndConditions
                    // }
                    let rawClone = Object.assign({}, raw)
                    let args = rawClone

                    groupColumnsForTranslations('name', args)
                    groupColumnsForTranslations('description', args)
                    groupColumnsForTranslations('howToUse', args)
                    groupColumnsForTranslations('shortDescription', args)
                    groupColumnsForTranslations('slogan1', args)
                    groupColumnsForTranslations('slogan2', args)
                    groupColumnsForTranslations('termAndConditions', args)


                    // console.log('args', args)
                    // let nameTranslations = extractInputIntoTranslation('name', raw) 
                    // let descriptionTranslations = extractInputIntoTranslation('Description', raw) 
                    // let howToUseTranslations = extractInputIntoTranslation('How To Use', raw)
                    // let shortDescriptionTranslations = extractInputIntoTranslation('Short Description', raw);
                    // let slogan1Translations = extractInputIntoTranslation('Slogan1', raw)
                    // let slogan2Translations = extractInputIntoTranslation('Slogan2', raw)
                    // let termAndConditionsTranslations = extractInputIntoTranslation('Term And Conditions', raw)
                    
                    let imageIdsOutput = [] as number[]
                    if (imageIds != null) {
                        imageIdsOutput = (imageIds as string).split(',').map((value)=> {
                            return Number(value)
                        })
                    }
                    args['imageIds'] = imageIdsOutput

                    let businessBranchIdsOutput = [] as number []
                    if (businessBranchIds != null) {
                        businessBranchIdsOutput = (businessBranchIds as string).split(',').map((value) => {
                            return Number(value)
                        })
                    }

                    args['businessBranchIds'] = businessBranchIdsOutput

                    // console.log(`active; ${active} allowedPeriodDescription: ${allowedPeriodDescription} allowedPeriodEnd: ${allowedPeriodEnd} allowedPeriodStart: ${allowedPeriodStart}`)
                    // console.log('descriptionTranslations: ', descriptionTranslations)
                    console.log('Final args', args)
                    props.createCoupon(args)
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(CouponDisplay)))