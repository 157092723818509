import React from 'react';
import { Button } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';


class NotFoundPage extends React.Component<RouteComponentProps>{
    render () {
        const { history } = this.props
        return (
            <div>
                <h1>Page Not Found on our Server</h1>
                <div>
                    <Button onClick={()=> {
                        history.push('/')
                    }}>Back to Home</Button>
                </div>
            </div>
        )
    }
}


export default NotFoundPage