import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Spot } from '../../api/models';
import BaseDisplay from '../basedisplay/BaseDisplay';
import { ChangeSet } from '@devexpress/dx-react-grid';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { connect } from 'react-redux';
import { groupColumnsForTranslations, separeteCommmaStrForSomeInput } from '../utils';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%'
    }
}
// type Props = {
//     classes: {
//         container: string,
//         paper: string
//     },
//     columns: Column[]
// }


// type RealProps = RouteComponentProps & Props & PaginatedProps<Spot>;

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            patchSpot: commonFeatureActions.patchSpotRequest
        },
        dispatch
    )


class SpotDisplay extends BaseDisplay<Spot> {

    
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            console.log('onCommitEditing Spot Display')
            const data = (this as any).data as Spot[]
            if (data != null && data != undefined) {
                console.log('data', data)
                if (changeSet.changed != undefined) {
                    for (let rowIndex in changeSet.changed) {
                        const changedKeyValues = changeSet.changed[rowIndex];
                        console.log('changed key values', changedKeyValues);
                        groupColumnsForTranslations('name', changedKeyValues)
                        groupColumnsForTranslations('availablePeriodDescription', changedKeyValues)
                        groupColumnsForTranslations('description', changedKeyValues)
                        separeteCommmaStrForSomeInput('imageIds', changedKeyValues)
                        separeteCommmaStrForSomeInput('businessIds', changedKeyValues)
                        console.log('changed key values', changedKeyValues);
                        const rowId = data[Number(rowIndex)].id;
                            (this as any).patchSpot(
                                {
                                    spotId: rowId,
                                    ...changedKeyValues
                                }
                            )
                    }
                }
            }
        }
    }
}

// class SpotDisplay extends React.Component<RealProps> {

//     render() {
//         let { data, page, perPage,  onPageSizeChange, onChangeCurrentPage, totalCount, columns} = this.props;
//         let { paper } = this.props.classes;
//         if (data == null) {
//             data = []
//         }
//         console.log('data', data)
//         return (
//             <Paper className={paper}>
                
//                 <Grid
//                     rows={data}
//                       columns={columns}>
                    
//                     <PagingState
//                         defaultCurrentPage={0}
//                         defaultPageSize={10}
//                         currentPage={page}
//                         pageSize={perPage}
//                         onPageSizeChange={onPageSizeChange}
//                         onCurrentPageChange={onChangeCurrentPage}
//                     />
//                     <CustomPaging 
//                         totalCount={totalCount}
//                         />
//                     <Table />
//                     <TableHeaderRow />
//                     <PagingPanel 
//                         pageSizes={[1, 10, 50, 100]}
//                         />
//                 </Grid>

//             </Paper>
//         )
//     }
// }


export default connect(mapStateToProps, mapDispatchToProps) (withRouter(withStyles(styles)(SpotDisplay)))