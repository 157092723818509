import React, { ChangeEvent } from 'react'
import { Card, TextField, Typography } from '@material-ui/core'
import { ArticleBlockInputType } from '../../api/models'
import { EditorState, convertToRaw } from 'draft-js'
import { Grid } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

type Props ={
    title: string
    className?: string
    htmlString?: string,
    imageUrl?: string,
    imageId?: string,
    routeUrl?: string,
    onChange2?: (articleBlockInputType: ArticleBlockInputType) => void
}

type State = {
    blockType?: 'image' | 'text',
    htmlString?: string,
    routeUrl?: string,
    imageUrl?: string,
    imageId?: string,
    editorState: EditorState
}

class ArticleBlockInput extends React.Component<Props, State> {

    state = {
        editorState: EditorState.createEmpty()
    } as State
    
    htmlStringRef: React.RefObject<HTMLInputElement>;
    routeUrlRef: React.RefObject<HTMLInputElement>;
    imageUrlRef: React.RefObject<HTMLInputElement>;
    imageIdRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props)
        this.htmlStringRef = React.createRef();
        this.routeUrlRef = React.createRef();
        this.imageUrlRef = React.createRef();
        this.imageIdRef = React.createRef();
    }

    getValues = (): ArticleBlockInputType => {
        // const htmlString = this.htmlStringRef.current!!.value
        if (this.imageIdRef.current == null) {
            return {}
        }
        const { editorState } = this.state;
        const htmlString = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const imageId = this.imageIdRef.current!!.value
        // const imageUrl = this.imageUrlRef.current!!.value
        const routeUrl = this.routeUrlRef.current!!.value

        return {
            htmlString,
            imageId: imageId == '' ? undefined : imageId,
            // imageUrl,
            routeUrl: routeUrl == '' ? undefined : routeUrl
        }
    }
    
    onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.log('onTextFieldChange', event.target, event.target.id)
        const target = event.target
        const targetId = target.id
        if (targetId == 'htmlString') {

        }
        console.log('this.props.onChange', this.props.onChange2)
        if (this.props.onChange2 != null) {
            console.log('onChange is not null')
            this.props.onChange2(this.getValues())
        }
    }

    onEditorStateChange = (editorState: EditorState)  => {
        const props = this.props as any
        const getValue = this.getValues
        this.setState({
            editorState
        }, ()=> {
            if (props != undefined) {
                props!!.onChange2(getValue())
            }
        })
        
    }

    getBlockType = () => {
        const values = this.getValues()
        console.log('values', values);
        if (values.imageId != null && values.imageId != '') {
            console.log(`imageId "${values.imageId}"`)
            return 'image'
        }
        return 'text'
    }

    render() {
        const { title, className } = this.props
        const { editorState } = this.state
        return  (
            <Card className={className} style={{
                padding: '1em'
            }}>
                <Typography variant='h6'>
                    {title}
                </Typography>
                {/* <TextField
                    id='blockType'
                    label='Block Type'
                    fullWidth
                    margin="normal"
                    disabled
                    onChange={this.onTextFieldChange}
                    value={this.getBlockType()}
                /> */}
                {/* <TextField
                    id='htmlString'
                    label='HTML String'
                    fullWidth
                    margin="normal"
                    inputRef={this.htmlStringRef}
                    value={this.state.htmlString != null ? this.state.htmlString : this.props.htmlString}
                    onChange={this.onTextFieldChange}
                /> */}
                <Grid container>
                        <Grid item xs ={6}>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType'],
                                    inline: {
                                      options: ['bold',],
                                      bold: { className: 'bordered-option-classname' },
                                    },
                                    blockType: {
                                      className: 'bordered-option-classname',
                                    }
                                  }}
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{
                                    width: '100%'
                                }}
                                multiline
                                disabled={false}
                                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                            />
                        </Grid>
                </Grid>
                {/* <TextField
                    id='imageUrl'
                    label='Image Url'
                    fullWidth
                    margin="normal"
                    inputRef={this.imageUrlRef}
                    value={this.state.imageUrl != null ? this.state.imageUrl : this.props.imageUrl}
                    >
                </TextField> */}

                <TextField
                    id='imageId'
                    label='Image Id'
                    fullWidth
                    margin="normal"
                    inputRef={this.imageIdRef}
                    value={this.state.imageId != null ? this.state.imageId : this.props.imageId}
                    onChange={this.onTextFieldChange}
                    />
                <TextField
                    id='routeUrl'
                    label='Route Url'
                    fullWidth
                    margin='normal'
                    inputRef={this.routeUrlRef}
                    value={this.state.routeUrl != null ? this.state.routeUrl : this.props.routeUrl}
                    onChange={this.onTextFieldChange}
                    />
            </Card>
        )
    }
}

export default ArticleBlockInput;