import { combineReducers } from 'redux';


import todosReducer from '../features/todos/reducer';
import countersReducer from '../features/counters/reducer';
import authReducer from '../features/auth/reducer';
import drawerReducer from '../features/drawer/reducer';
import commonFeatureReducer from '../features/commonfeature/reducer'

const rootReducer = combineReducers({
  todos: todosReducer,
  counters: countersReducer,
  auth: authReducer,
  drawers: drawerReducer,
  commonFeatures: commonFeatureReducer
});

export default rootReducer;
