import React from 'react';
import { IconButton } from '@material-ui/core';
import { CouponOwnership } from '../../api';
import * as H from 'history';
import NavigationIcon from '@material-ui/icons/Navigation';
import { MyColumn } from '../../common/models';


export const getColumns = (history: H.History, includeDetail: boolean = true): MyColumn[] => 
{
    const output : MyColumn []= [
        
        { title: 'id', name: 'id' },
        { title: 'Active', name: 'active', getCellValue: (row) => { return row.active ? '✓' : '' } },
        { title: 'Published', name: 'published', getCellValue: (row) => { return row.published ? '✓' : '' } },
        { title: 'Start Time', name: 'startTime' },
        { title: 'End Time', name: 'endTime' },
    ]

    if (includeDetail) {
        output.unshift({
            name: 'Detail', getCellValue: (row) => {
                return (
                    <a target="_blank" href={`/game/${row.id}`}>Detail</a>
                )
            }
        },)
    }
    return output
}
