import * as H from 'history';
import { MyColumn } from '../../common/models';
import { GameBanner } from '../../api';
import React from 'react'


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal: MyColumn[] = [
        { title: 'Id', name: 'id' },
        { title: 'Url', name: 'url'},
        { title: 'Active', name: 'active', getCellValue: (row) => {
            return row.active.toString()
        } },
        { title: 'Image Id', name: 'imageId'},
        { title: 'image', name: 'image', getCellValue: (row: GameBanner) => {
            if (row.image != null) {
                return (
                    <a href={row.image.url}>Image</a>
                )
            }
        }, editable: false},
    ]
    return normal
}