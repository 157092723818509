import * as H from 'history'
import { getColumns, getColumnsForSelectingUser } from './columns'

export const getControlledConfig =  (history: H.History) => ({
    header: 'User',
    columns: getColumns(history)
})

export const getSelectionConfig = (history:H.History)=> {
    const columns = getColumnsForSelectingUser(history)
    return {
        ...getControlledConfig(history),
        columns,
        filterColumnExtensions: columns.map((value) => {
            return {
                columnName: value.name,
                filteringEnabled: value.filterable == true
            }
        })
    }
}