// import React from 'react';
import * as H from 'history';
import { MyColumn } from '../../common/models';


// "url": "https://asset.scannow.gg/coupon/discount-coming-soon",
//         "id": 269,
//         "objKey": "coupon/discount-coming-soon",
//         "slugifiedName": "discount-coming-soong",
//         "name": null

export const getColumns = (history: H.History): MyColumn[] => {
    const output : MyColumn []= [
        { title: 'id', name: 'id' },
        { title: 'Obj Key', name: 'objKey'},
        { title: 'SlugifiedName', name: 'slugifiedName' },
        { title: 'Name', name: 'name' },
    ]
    return output
}