import { ChangeSet, Column, CustomPaging, EditingState, Filter, FilteringState, PagingState, RowDetailState, SelectionState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableEditColumn, TableEditRow, TableFilterRow, TableHeaderRow, TableRowDetail, TableSelection, TableColumnResizing,
    //  TableColumnResizing 
    } from '@devexpress/dx-react-grid-material-ui';
import { Paper, Typography } from '@material-ui/core';
import React, { ReactText } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { notNull } from '../../utils';
import LoadingCover from '../../commonui/loadingcover';
import { MyColumn } from '../../common/models';


type GenericProps<Data> = {
    data? : Data[] | null,
} 

export type Props = {
    classes: {
        container: string,
        paper: string
    },
    page?: number;
    perPage?: number;
    totalCount?: number;
    rowDetail?: React.ComponentType<TableRowDetail.ContentProps>,
    isLoading?: boolean
}

export interface BaseConfigProps {
    columns: Column[],
    header?: String,
    defaultCurrentPage: number;
    defaultPageSize: number;
    pageSizes: number[];
    showPagePanel: boolean,
    onCurrentPageChange?: (page: number) => any,
    onPageSizeChange?: (perPage: number) => any,
    onFiltersChange?: (filters: Filter[])=> any,
    onCommitEditing?: (changeSet: ChangeSet) => any,
    showFilter?: boolean,
    filterColumnExtensions?: FilteringState.ColumnExtension[]
    editColumnExtensions?: EditingState.ColumnExtension[],
    showEdit?: boolean,
    showAdd?: boolean,
    showDelete?: boolean,
    addedRows?: any[],
    onAddedRowsChange?: (value: any)=> any,
    showSelection?: boolean,
    onSelectionChange? : (selections: ReactText[]) => any,
    selections?: Array<ReactText>
}


type AllButGeneric = RouteComponentProps & BaseConfigProps & Props

export type BaseRealProps<Data> = GenericProps<Data> & AllButGeneric


export const defaultDisplayConfig = {
    defaultCurrentPage: 1,
    defaultPageSize: 10,
    pageSizes: [1, 5, 10, 100, 99999],
    showPagePanel: true,

    // onRowAdded: (row: any, props: Props) => {
    //     console.log('default on row added')
    // }, // do nothing
    // onRowEdited: (row: any, props: Props, entity: any) => {},

    // onCommitEditing: (changeSet: ChangeSet) => {
    //     const { added, changed} = changeSet
    //     let props = this as unknown as Props
    //     if (added != null) {
    //         added.forEach(raw => {
    //             this.onRowAdded(row, props)
    //         })
    //     }
    // }
}

interface _defaultProps {
    onRowAdded?: (row: any, props: any) => void,
    onRowEdited?: (row: any, props: any, entity: any) =>void
}

type DefaultProps = typeof defaultDisplayConfig & _defaultProps

interface BaseState  {
    columnWidthInfos: Array<TableColumnWidthInfo>
}

const defaultStates = {
    columnWidthInfos: Array<TableColumnWidthInfo>()
}

type DefaultStates = typeof defaultStates

/** This Class is Controlled, may be we need a class that is not controlled */
let BaseDisplay = (class BaseDisplay<Data, Props extends BaseRealProps<Data> = BaseRealProps<Data>, States extends BaseState = BaseState> extends React.Component<Props, States> {

    static defaultProps: DefaultProps = defaultDisplayConfig as (DefaultProps)
    // ignore following error XD
    // state: BaseState = defaultStates
    
    
    constructor(props: Props) {
        super(props)
        // @ts-ignore
        this.state = this.makeColumndWidthInfo(props.columns)
        
    }

    onCurrentPageChange = (page: number)=> {
        if (this.props.onCurrentPageChange != null) {
            this.props.onCurrentPageChange(page)
        }
    }

    onPageSizeChange = (perPage: number) => {
        if (this.props.onPageSizeChange != null) {
            this.props.onPageSizeChange(perPage)
        }
    }

    onCommitEditing = (changeSet: ChangeSet) => {
        if(this.props.onCommitEditing != null) {
            this.props.onCommitEditing(changeSet)
        } else {
            const { added, changed} = changeSet
                let props = this.props as unknown as any
                if (added != null) {
                    added.forEach(raw => {
                        console.log('props', props, raw)
                        if (props.onRowAdded != null) {
                            props.onRowAdded(raw, props)
                        }
                        
                    })
                }
                if(changed != null) {
                    for (let rowIndex in changed) {
                        
                        const _data = props.data
                        if (_data != null) {
                            let row = changed[rowIndex]
                            let entity = _data[Number(rowIndex)];
                            (this.props as unknown as any).onRowEdited(row, props, entity)
                        }
                    }
                }
        }
    }

    getData = (): any[] => {
        let { data } = this.props
        // if (data == null || data == undefined) {
        //     return []
        // }
        return notNull(data)
    }

    onSelectionChange = (selections: ReactText[]) => {
        const { onSelectionChange } = this.props
        if (onSelectionChange != null) {
            onSelectionChange(selections)
        }
    }

    myColumnsToEditColumnExtensions = (columns: MyColumn[]) => {
        return columns.map((value)=> {
            // console.log(`myColumnsToEditColumnExtensions name: ${value.name} editable: ${value.editable} value: ${value}`)
            // console.log(value)
            const val = {
                columnName: value.name,
                editingEnabled: value.editable
            }
            // console.log('editable', val)
            return val
        })
    }

    makeColumndWidthInfo = (columns: MyColumn[]) => {
        let defaultTableColumnWidthInfos = (columns as MyColumn[])
                    .map((value, index, array) => {
                        return {
                            'columnName': value.name, 
                            'width': 100
                        }
                    })
        return defaultTableColumnWidthInfos
    }

    componentWillReceiveProps(nextProps: Props) {
        console.log('BaseDisplaycomponentWillReceiveProp', nextProps)
        let { columns } = nextProps

        if (this.state == null || this.state.columnWidthInfos == null) {
            let defaultTableColumnWidthInfos = this.makeColumndWidthInfo(columns)
            this.setState({
                columnWidthInfos: defaultTableColumnWidthInfos
            })
        } else {
            let columnNames = new Set()
            if (nextProps.columns.length > this.props.columns.length) {
                // Added columns
                let widthInfos = this.state.columnWidthInfos.concat([])
                widthInfos.forEach((value, index, array) =>  {
                    columnNames.add(value.columnName)
                })
                nextProps.columns.forEach((value, _, __) => {
                    if (!columnNames.has(value.name)) {
                        widthInfos.push({
                            'columnName': value.name, 
                            'width': 100
                        })
                    }
                })
                this.setState({
                    columnWidthInfos: widthInfos
                })
            } else {
                // removed columns
                // do nothing
            }
        }

        
    }

    onColumnWidthsChange = (nextColumnWidthInfos: Array<TableColumnWidthInfo>) => {
        console.log('onColumnWidthsChange', nextColumnWidthInfos)
        this.setState({
            columnWidthInfos: nextColumnWidthInfos
        })
        
    }

    render() {
        let { addedRows, onAddedRowsChange, isLoading, selections, showSelection, showEdit, showAdd, showDelete,
            filterColumnExtensions, editColumnExtensions, showFilter, onFiltersChange, rowDetail, 
            showPagePanel, columns, header, defaultCurrentPage, defaultPageSize, pageSizes, page, perPage, totalCount} = this.props;
        let { paper } = this.props.classes;

        if (this.state == null) {
            return null
        }
        let state = {} as States
        if (this.state != null) {
            state = this.state
        }
        let columnWidthInfos = state.columnWidthInfos


        // let defaultTableColumnWidthInfos = (columns as MyColumn[])
        //             .map((value, index, array) => {
        //                 return {
        //                     'columnName': value.name, 
        //                     'width': 100
        //                 }
        //             })

        console.log('BaseDisplay state', this.state)
        console.log('BaseDisplay columns', columns, columnWidthInfos)
        
        if (editColumnExtensions == null) {
            editColumnExtensions = this.myColumnsToEditColumnExtensions(columns)
        }
        
        // console.log('data', data)
        console.log('BaseDisplay addedRows', addedRows)
        return (
            <div style={{position: 'relative'}}>
                
            <Paper className={paper}>
                {
                    header != null ? 
                    <Typography style={{
                        marginLeft: '1vw',
                        marginTop: '1vh'
                    }}variant='h6'>
                        {header}
                    </Typography> : null
                }
                
                <Grid
                
                    rows={this.getData()}
                    columns={columns}>
                    
                    {
                        showSelection ?
                        <SelectionState
                            selection={selections}
                            onSelectionChange={this.onSelectionChange}
                        /> : null
                    }

                    {
                        showFilter ? 
                        <FilteringState
                            columnExtensions={filterColumnExtensions}
                            onFiltersChange={onFiltersChange}/> : null
                    }

                    {
                        
                        showEdit || showAdd ? 
                        <EditingState
                            
                            onCommitChanges={this.onCommitEditing}
                            columnExtensions={editColumnExtensions}
                            onAddedRowsChange={onAddedRowsChange}
                            addedRows={addedRows}
                        /> : null
                    }

                    
                    
                    <PagingState
                        defaultCurrentPage={defaultCurrentPage}
                        defaultPageSize={defaultPageSize}
                        currentPage={page}
                        pageSize={perPage}
                        onPageSizeChange={this.onPageSizeChange}
                        onCurrentPageChange={this.onCurrentPageChange}
                    />
                    <CustomPaging 
                        totalCount={totalCount}
                        />
                    <Table/>
                    {/* <TableColumnResizing defaultColumnWidths={[
                        { columnName: 'id', width: 100},
                        { columnName: 'prizeId', width: 100},
                        { columnName: 'imageId', width: 100},
                        { columnName: 'tag', width: 100},
                        { columnName: 'image', width: 100},
                        
                    ]}/>
                    */}
                   

                    <TableColumnResizing columnWidths={
                        columnWidthInfos
                    }
                    onColumnWidthsChange={this.onColumnWidthsChange}
                    minColumnWidth={45}
                    />
                    <TableHeaderRow />
                    {
                        showPagePanel ? 
                        <PagingPanel 
                            pageSizes={pageSizes}
                            /> : null
                    }
                    <RowDetailState
                    />
                    {
                        rowDetail ?
                        <TableRowDetail
                            contentComponent={rowDetail}
                        /> : null
                    }
                    {
                        showFilter ? <TableFilterRow />: null
                    }
                    { showEdit || showAdd || showDelete? <TableEditRow /> : null}
                    {
                        showEdit || showAdd? <TableEditColumn
                            showAddCommand={showAdd}
                            showEditCommand={showEdit}
                            showDeleteCommand={showDelete}
                        /> : null
                    }
                    {
                        showSelection ? <TableSelection
                            selectByRowClick
                        /> : null
                    }
                    
                    
                </Grid>
            </Paper>
                {
                    isLoading ? <LoadingCover/> : null
                }
            </div>
        )
    }
})

type one = Partial<Pick<typeof BaseDisplay, 'defaultProps'>>

BaseDisplay = (BaseDisplay) as (typeof BaseDisplay & one)


// export default withRouter(withStyles(styles)(BaseDisplay))
export default BaseDisplay