import * as React from 'react';

import Types from 'MyTypes';
// import { bindActionsCreators, Dispatch} from 'redux';
import { connect }  from 'react-redux';
//import * as React from 'react';
import { authActions, authSelectors } from '../features/auth'
// import { Authentication } from '../features/auth/models';
import { bindActionCreators, Dispatch } from 'redux';
import { Button } from '@material-ui/core';
import { drawerActions } from '../features/drawer'
import { commonFeatureActions } from '../features/commonfeature'
import {
    RouteComponentProps
  } from "react-router-dom";
import { withSnackbar, WithSnackbarProps } from 'notistack';

// type Props = {
//     isLoggedIn: Boolean;
//     authentication: Authentication;
//     login: (email: string, password: string) => any,
//     logout: ()=> any
// }



const mapStateToProps = (state: Types.RootState) => ({
    isLoggedIn: authSelectors.getIsLoggedIn(state.auth),
    authentication: authSelectors.getAuthentication(state.auth)
})

// const dispatchProps = {
//     login: authActions.login,
//     logout: authActions.logout
// };

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
    bindActionCreators(
    {
        showMessage: commonFeatureActions.showMessage,
        login: authActions.login,
        logout: authActions.logout,
        openDrawer: drawerActions.openDrawer,
        closeDrawer: drawerActions.closeDrawer,

    },
    dispatch
  );

type Props = WithSnackbarProps &  RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class TestingPage extends React.Component<Props> {
    state = {
        count: 0
    }

    constructor(props: Props){
        super(props)
        console.log('TestingPage props', props)
    }

    onLoginButtonClicked = (event: any) => {
        console.log('onLoginButtonClicked')
        const email = 'gg@gmailc.om';
        const password ='12345678'
        this.props.login({
            emailOrAnything: email,
            password: password
        })
    }

    

    render() {
        const { history, showMessage } = this.props;
        return (
            <div style={{
                
            }}>

                This is Testing Page
                
                <Button onClick={this.onLoginButtonClicked}>
                    Login
                </Button>
                <Button onClick={this.props.logout}>
                    Logout
                </Button>

                <Button onClick={this.props.openDrawer}>
                    Open drawer
                </Button>

                <Button onClick={this.props.closeDrawer}>
                    Close drawer
                </Button>

                <Button onClick={()=> {
                    history.push("/nothing")
                }}>
                    Go to Nothing
                </Button>
                <Button onClick={()=> {
                    let count = this.state.count
                    this.setState({
                        count: count+1
                    })
                    showMessage({
                        message: 'hihih: ' + count
                    })
                }}>
                    snack
                </Button>

                <Button onClick={()=> {
                    let count = this.state.count
                    this.setState({
                        count: count+1
                    })
                    showMessage({
                        message: 'hihih: ' + count,
                        type: 'dialog'
                    })
                }}>
                    Dialog
                </Button>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withSnackbar(TestingPage));