import { withStyles } from '@material-ui/core';
import { Prize } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { groupColumnsForTranslations, separeteCommmaStrForSomeInput } from '../utils';
import { createPrize } from '../../api/admin';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%',
        paddingTop: '3%',
        paddingBottom: '3%'
    }
}


const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            patchPrize: commonFeatureActions.patchPrize,
            showMessage: commonFeatureActions.showMessage
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>


class PrizeDisplay extends BaseDisplay<Prize, MyProps<Prize>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            console.log('onCommitEditing Prize Display')
            let added = changeSet.added
            const data = (this as any).data
            // console.log('added', added)
            if ( added != null) {
                console.log('Adding prize', added)
                added.forEach( raw => {
                    let rawClone = Object.assign({}, raw)
                    let args = rawClone
                    groupColumnsForTranslations('name', args)
                    groupColumnsForTranslations('shortDescription', args)
                    groupColumnsForTranslations('description', args)
                    separeteCommmaStrForSomeInput('shippingMethodKeys', args)

                    let shippingMethodKeys = args['shippingMethodKeys']
                    delete args['shippingMethodKeys']
                    console.log('shippingMethodKeys', shippingMethodKeys)
                    if (shippingMethodKeys.length > 0) {
                        args['shippingMethodKey'] = shippingMethodKeys[0]
                    }

                    console.log('args', args)
                    createPrize({
                        ...args,
                        published: true,

                    }).then((value) => {
                        (this as any).showMessage({
                            message: `Create prize ${value.data.message!!.id}`,
                            type: "dialog"
                        })
                    });

                    
                })
            }
            if (changeSet.changed != undefined) {
                for (let rowIndex in changeSet.changed) {
                    const kv = changeSet.changed[rowIndex]

                    console.log('changed key values', kv);
                    groupColumnsForTranslations('name', kv)
                    groupColumnsForTranslations('shortDescription', kv)
                    groupColumnsForTranslations('description', kv)
                    separeteCommmaStrForSomeInput('shippingMethodKeys', kv)
                    console.log('changed key values', kv);


                    const rowId = data[Number(rowIndex)].id;
                        (this as any).patchPrize(
                            {
                                prizeId: rowId,
                                args: kv
                            }
                        )
                }
            }
        }
    }
    
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(PrizeDisplay)))