import * as H from 'history';
import React from 'react';
import { Prize } from '../../api';
import { MyColumn } from '../../common/models';
import { createColumnsForTranslations } from '../utils';

// TODO we need translations 

export const getColumns = (history: H.History) : MyColumn[] => [
    { title: 'Id', name: 'id', editable: false},
    {
        title: 'Published',
        name: 'published',
        getCellValue: (row) => {
            if (row.published != null) {
                return row.published.toString()
            } else {
                return 'null'
            }
            
        },
        editable: true
    },
    ...createColumnsForTranslations('Name', 'name'),
    ...createColumnsForTranslations('Description', 'description'),
    ...createColumnsForTranslations('Short Description', 'shortDescription'),
    { title: 'Price', name: 'price'},
    { title: 'Brand ID', name: 'brandId'},
    {
        title: 'Brand Name', name: 'Brand Name', getCellValue: (row: Prize) => {
            if (row.brand == null) {
                return null
            }
            return row.brand.name
        }
    },
    { title: "Maximum Amount", name: "maximumAmount"},
    { title: "Listing Image Id", name: "listingImageId"},
    { name: 'Listing Image', editable: false, getCellValue: (row: Prize)=> {
        if (row.listingImage == null) {
            return ''
        }
        return <a href={row.listingImage.id.toString()}>to image</a>
    }},
    { title: "Detail Image Id", name: "detailImageId" },
    { name: 'Detail Image', editable: false, getCellValue: (row: Prize) => {
        if (row.detailImage == null) {
            return ''
        }
        return <a href={row.detailImage.id.toString()}>to image</a>
    }},
    { name: 'Shipping Methods', getCellValue: (row: Prize) => {
        if (row.shippingMethods == null) {
            return null
        }
        return row.shippingMethods
        .map((value) => {
            return value.name
        })
        .reduce((p, c) => {
            return `${p}, ${c}`
        }, '')
    }, editable: false},

    { title: 'Share Url', name: 'shareUrl', editable: false},
    { title: "Shipping Method Keys", name: 'shippingMethodKeys', getCellValue: (row) => {
        if (row.shippingMethodKeys != null) {
            return row.shippingMethodKeys;
        }
        
        if (row.shippingMethods == null) {
            return null
        }

        const output = (row.shippingMethods as any).map((shippingMethod: any)=> shippingMethod.key.toString()).reduce((one: any, two: any) => { 
            if (one == '') {
                return two
            }
            return one + "," + two
        }, '')
        return output
    }}
    
]