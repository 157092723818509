import React, { useEffect, useState } from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button, Paper, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import GeographicLocationDisplay from '../../components/geographiclocationdisplay/GeographicLocationDisplay'
import { getColumns as getGeographicLocationColumns } from '../../components/geographiclocationdisplay/columns'
import { ChangeSet } from '@devexpress/dx-react-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ClientTaskDisplay from '../../components/clienttaskdisplay';
import { getControlledConfig } from '../../components/clienttaskdisplay/defaultconfig';
import { ClientTask } from '../../api/models';

const mapStateToProps = (state: MyTypes.RootState) => ({
    data: commonFeatureSelectors.getClientTaskData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {

            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            getClientTaskPage: commonFeatureActions.getClientTaskPage,
        },
        dispatch
    )


const styles = (theme: any) => ({
    container: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    }
})

type Props = {
    container: string,
    formControl: String
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>



function ClientTaskPage2 (props: RealProps) {
    const { container, history, data } = props;
        // const { addedRows, page, perPage } = this.state

    const [addedRows, setAddedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState('initial');

    useEffect(()=> {
        props.updateAppBarConfig({ title: 'Client Portal Requests' })
        
    })

    useEffect(()=> {
        // load something
        console.log('on page or per Page changed')
        getNext() 
    }, [page, perPage, statusFilter])

    const onCurrentPageChange = (currentPage: number) => {
        console.log('onChange Current Page', currentPage)
        setPage(currentPage);
    }

    const getNext = () => {
        console.log('Client Task get Next', statusFilter)
        const filters: Array<String> = []
        if (statusFilter != null && statusFilter != '') {
            filters.push(`status=${statusFilter}`)
        }
        props.getClientTaskPage({
            page: page + 1,
            perPage: perPage,
            filters
        })
    }

    const onPageSizeChange = (pageSize: number) => {
        setPerPage(pageSize);
    }



    return (
        <div className={container}>
            <Paper>
                <Grid container direction='row'>
                    <Grid item xs={2}>
                        <Button
                            style={{margin: '1em'}}
                            onClick={getNext}
                            variant="contained"
                            color="primary"
                        ><RefreshIcon /> Refresh </Button>
                    </Grid>        
                    <Grid item xs={2}>
                        <FormControl >
                            <InputLabel htmlFor="status-simple">Status</InputLabel>
                            <Select
                                value={statusFilter}
                                onChange={(event)=> {setStatusFilter(event.target.value)}}
                                inputProps={{
                                name: 'status',
                                id: 'status-simple',
                                }}
                            >
                                <MenuItem value={'initial'}>Initial</MenuItem>
                                <MenuItem value={''}>None</MenuItem>
                                <MenuItem value={'done'}>Done</MenuItem>
                                <MenuItem value={'rejected'}>Rejected</MenuItem>
                                <MenuItem value={'failed'}>Failed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
            </Paper>
            
            
            <ClientTaskDisplay
                {...getControlledConfig(history)}
                page={page}
                perPage={perPage}
                onPageSizeChange={onPageSizeChange}
                data={data.cached != null ? data.cached!!.items : Array<ClientTask>()}
                onCurrentPageChange={onCurrentPageChange}
                totalCount={data.cached != null ? data.cached.total : undefined}
                >
            </ClientTaskDisplay>
        </div>
    )
}


// class ClientTaskPage extends React.Component<RealProps> {
//     state = {
//         page: 0,
//         perPage: 10,
//         addedRows: []
//     }
    
//     componentDidMount() {
//         this.props.updateAppBarConfig({ title: 'Client Portal Requests' }) 
//         this.getNext()
//     }

//     onCurrentPageChange = (currentPage: number) => {
//         console.log('onChange Current Page', currentPage)
//         this.setState({
//             page: currentPage
//         }, ()=> {
//             this.getNext()
//         })
//     }

//     getNext = () => {
//         console.log('Geographic Location get Next')
//         // TODO get something
//         this.props.getPaginatedGeographicLocation({
//             page: this.state.page + 1,
//             perPage: this.state.perPage
//         })
//     }

//     onPageSizeChange = (pageSize: number) => {
//         this.setState( { perPage: pageSize }, ()=> { this.getNext() })
//     }

//     onCommitEditing = (changeSet: ChangeSet) => {
//         console.log('onCommitEditing: changeSet: ', changeSet)
//     }

//     onAddedRowsChange = (rows: any[]) => {
//         console.log('onAddedRowsChange', rows)
        
//         let newArray = rows
//         // newArray.push({id: 1, latitude: '1', longitude: '12'})
//         this.setState({
//             addedRows: newArray
//         })
//     }

//     render() {
//         const { container, history, geographicLocationsData } = this.props;
//         const { addedRows, page, perPage } = this.state
        
//         return (
//             <div className={container}>
//                 <Button
//                     style={{margin: '1em'}}
//                     onClick={this.getNext}
//                     variant="contained"
//                     color="primary"
//                 ><RefreshIcon /> Refresh </Button>
                
//                 <div>Client Portal Requests</div>
//             </div>
//         )
//     }
// }


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientTaskPage2)))