import { RootState, Services } from 'MyTypes';
import { Epic } from 'redux-observable';
import { tap, filter, mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs'
import * as constants from './constants';
import { CommonAction } from './reducer';
import * as adminApis from '../../api/admin'
import { isOfType } from 'typesafe-actions';
import { commonFeatureActions } from '.';
import { from } from 'rxjs';
import { Pagination, UserEntity, Game, Spot, Business } from '../../api';
import { notUndefined } from '../../common/MyRxOperators';
import { AxiosError } from 'axios'


const handleAxiosError = (error: AxiosError) => {
    console.log('axios error', error)
    let message = 'unknownError'
    if (error.response != null) {
        message = `${JSON.stringify(error.response.data)}`
    }
    return of(commonFeatureActions.showMessage(
        {
            message
        }
    ))
}

export const patchQRPeriod: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_QR_PERIOD)),
        mergeMap(action => {
            const { payload } = action

            let id = payload.id
            let args = payload.args
            return from(
                adminApis.patchQRPeriod(id, args)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Patched Mixed Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                    
                })
            )
        })
    )
}

export const patchMixedBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_MIXED_BANNER)),
        mergeMap(action => {
            const { payload } = action

            let id = payload.id
            let args = payload.args
            return from(
                adminApis.patchMixedBanner(id, args)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Patched Mixed Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const patchRuleCondition: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_RULE_CONDITION)),
        mergeMap(action => {
            const { payload } = action

            let id = payload.id
            let args = payload.args
            return from(
                adminApis.patchRuleCondition(id, args)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Patched Rule Condition id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}


export const createRuleCondition: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_RULE_CONDITION)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createRuleCondition(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Rule Condition id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const patchScanRule: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_SCAN_RULE)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.patchScanRule(payload.id, payload.args)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Patched Scan Rule id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createScanRule: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_SCAN_RULE)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createScanRule(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Scan Rule id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createMixedBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_MIXED_BANNER)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createMixedBanner(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Mixed Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createTag: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_TAG)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createTag(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Tag id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createArticle: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_ARTICLE)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createArticle(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Article id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const getSpotRelatedIds: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_SPOT_RELATED_IDS)),
        mergeMap(action => {
            return from(
            adminApis.getSpotRelatedIds(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.cacheSpotRelatedIds(message),
                        commonFeatureActions.showMessage({
                            message: 'Got Spot Related Ids'
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchQRCodeEntity: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    
    return action$.pipe(
        filter(isOfType(constants.PATCH_QR_CODE_ENTITY)),
        mergeMap(action => {
            return from(
            adminApis.patchQRCodeEntity(action.payload.qrCodeEntityId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched QR Code Entity'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchPrize: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_PRIZE)),
        mergeMap(action => {
            return from(
            adminApis.patchPrize(action.payload.prizeId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched Prize'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchCouponBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_COUPON_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.patchCouponBanner(action.payload.couponBannerId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched Coupon Banner'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const patchGameBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_GAME_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.patchGameBanner(action.payload.gameBannerId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched Game Banner'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchPrizeBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_PRIZE_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.patchPrizeBanner(action.payload.prizeBannerId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched Prize Banner'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const patchSpotBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_SPOT_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.patchSpotBanner(action.payload.spotBannerId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched SpotBanner'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const patchMajorBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_MAJOR_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.patchMajorBanner(action.payload.majorBannerId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched MajorBanner'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchSpot: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_SPOT)),
        mergeMap(action => {
            return from(
            adminApis.patchSpot(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched Spots'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchCoupon: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_COUPON)),
        mergeMap(action => {
            return from(
            adminApis.patchCoupon(action.payload.couponId, action.payload.args),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Patched Coupons'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const removeTagsFromQRCodeEntity: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.REMOVE_TAGS_FROM_QR_CODE_ENTITY)),
        mergeMap(action => {
            return from(
            adminApis.removeTagsFromQRCodeEntity(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Removed Tags from QR Code Entity'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const addTagsToQRCodeEntity: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.ADD_TAGS_TO_QR_CODE_ENTITY)),
        mergeMap(action => {
            return from(
            adminApis.addTagToQRCodeEntity(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Added Tag to QR Code Entity'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const removeTagsFromSpot: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.REMOVE_TAGS_REMOVE_SPOT)),
        mergeMap(action => {
            return from(
            adminApis.removeTagsFromSpot(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Removed Tags from Spot'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const addTagsToSpot: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.ADD_TAGS_TO_SPOT)),
        mergeMap(action => {
            return from(
            adminApis.addTagToSpot(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.showMessage({
                    message: 'Added Tag to Spot'
                })),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getClientTaskPage: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_CLIENT_TASK_PAGE)),
        mergeMap(action => {
            return from(
            adminApis.getClientTaskPage(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cacheClientTaskPage(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getMixedBannerPage: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_MIXED_BANNER_PAGE)),
        mergeMap(action => {
            return from(
            adminApis.getMixedBannerPage(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cacheMixedBannerPage(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const getPaginatedCreditReward: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_CREDIT_REWARD)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedCreditReward(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedCreditReward(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedPrizeBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_PRIZE_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedPrizeBanner(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedPrizeBanner(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedPrize: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_PRIZE)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedPrize(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedPrize(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const getPaginatedQRCodeEntity: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_QR_CODE_ENTITY)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedQRCodeEntity(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedQRCodeEntity(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedCoupon: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_COUPON)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedCoupon(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedCoupon(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedRewardBundle: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_REWARD_BUNDLE)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedRewardBundle(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedRewardBundle(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedSpotBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_SPOT_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedSpotBanner(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedSpotBanner(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedMajorBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_MAJOR_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedMajorBanner(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedMajorBanner(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedCouponBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_COUPON_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedCouponBanner(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedCouponBanner(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedGameBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_GAME_BANNER)),
        mergeMap(action => {
            return from(
            adminApis.getPaginatedGameBanner(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedGameBanner(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedPhoneNumber: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_PHONE_NUMBER)),
        mergeMap(action => {
            return from(
                adminApis.getPaginatedPhoneNumber(action.payload),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedPhoneNumber(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const createQRCodeEntity: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_QR_CODE_ENTITY)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createQRCodeEntity(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created QR Code Entity id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}


export const createCoupon: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_COUPON)),
        mergeMap(action => {
            const { payload } = action
            return from(
                adminApis.createCoupon(payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Coupon id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createBusiness: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_BUSINESS)),
        mergeMap(action => {
            const { names, descriptions, businessIconId, alias, websites } = action.payload
            return from(
                adminApis.createBusiness(
                    names, descriptions, businessIconId, alias, websites)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Business id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createRewardBundle: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_REWARD_BUNDLE)),
        mergeMap(action => {
            const { actionUrl, couponIds, creditRewardId, toBeCollected } = action.payload
            return from(
                adminApis.createRewardBundle(creditRewardId, couponIds, actionUrl, toBeCollected)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Reward Bundle id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}


export const createSpotBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_SPOT_BANNER)),
        mergeMap(action => {
            const { spotId, imageId, isCurrent } = action.payload
            return from(
                adminApis.createSpotBanner(spotId, imageId, isCurrent)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Spot Banner Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createPrizeBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_PRIZE_BANNER)),
        mergeMap(action => {
            const args = action.payload
            return from(
                adminApis.createPrizeBanner(args)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Prize Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createMajorBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_MAJOR_BANNER)),
        mergeMap(action => {
            const { active, imageId, url } = action.payload
            return from(
                adminApis.createMajorBanner(imageId, active, url)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created MajorBanner Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createCouponBanner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_COUPON_BANNER)),
        mergeMap(action => {
            const { imageId, couponId, isCurrent } = action.payload
            return from(
                adminApis.createCouponBanner(couponId, imageId, isCurrent)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Coupon Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}

export const createGameBAnner: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_GAME_BANNER)),
        mergeMap(action => {
            const { gameId, imageId, published, url } = action.payload
            return from(
                adminApis.createGameBanner(published, gameId, imageId, url)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Game Banner id: ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error);
                })
            )
        })
    )
}


export const createPhoneNumber: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_PHONE_NUMBER)),
        mergeMap(action => {
            const { areaCode, countryCode, subscriberNumber } = action.payload
            return from(
                adminApis.createPhoneNumber(countryCode, areaCode, subscriberNumber)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created PhoneNumber id: ${message.id} -  ${message.countryCode} (${message.areaCode}) ${message.subscriberNumber}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedTag: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_TAG)),
        mergeMap(action => {
            return from(
                adminApis.getPaginatedTag(action.payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.cachePaginatedTag(message)
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const getPaginatedGeographicLocation: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_GEOGRAPHIC_LOCATION)),
        mergeMap(action => {
            const { page, perPage } = action.payload
            return from(
                adminApis.getPaginatedGeographicLocation(page, perPage)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.cachePaginatedGeographicLocation(message)
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const createLocation: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.CREATE_LOCATION)),
        mergeMap(action => {
            const { latitude, longitude, name, description } = action.payload
            return from(
                adminApis.createLocation(latitude, longitude, name, description)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [
                        commonFeatureActions.showMessage({
                            message: `Created Geographic Location ${message.id}`,
                            type: "dialog"
                        })
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchBusiness: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_BUSINESS)),
        mergeMap(action => {
            return from(
                adminApis.patchBusiness(action.payload.businessId, action.payload.args)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return commonFeatureActions.showMessage({
                        message: `Updateed Business ${action.payload.businessId}`
                    })
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const patchShippingOrder: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.PATCH_SHIPPING_ORDER)),
        mergeMap(action => {
            return from(
                adminApis.patchShippingOrder(action.payload.shippingOrderId, action.payload.status)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => {
                    return [commonFeatureActions.showMessage({
                        message: `Updateed Shipping Order ${action.payload.shippingOrderId}`
                    }), 
                    commonFeatureActions.onShippingOrderEdited([message])
                    ]
                }),
                mergeMap(commands => {
                    return from(commands)
                }),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedCouponOwnerships : Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST)),
        mergeMap(action => {
            return from(
                adminApis.getPaginatedUserCouponOwnerships(action.payload.userId, action.payload.page, action.payload.perPage),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedUserCouponOwnership(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getUserShippingOrders: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST)),
        mergeMap(action => {
            return from(
                adminApis.getPaginatedUserShippingOrders(action.payload.userId, action.payload.page, action.payload.perPage),
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cachePaginatedUserShippingOrders(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getUser: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_USER_REQUEST)),
        mergeMap(action => {
            return from(
                adminApis.getUserById(action.payload)
            ).pipe(
                map(response => response.data.message),
                filter(notUndefined),
                map(message => commonFeatureActions.cacheUser(message)),
                catchError( (error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedBusinesses: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_BUSINESSES_REQUEST)),
        mergeMap(action => {
            const { page, perPage } = action.payload
            return from (
                adminApis.getPaginatedBusiness(
                    page,
                    perPage
                )
            ).pipe(
                map(response => {
                    return  commonFeatureActions.cachePaginatedBusinesses(response.data.message as Pagination<Business>)
                }),
                catchError((error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}

export const getPaginatedSpots: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_SPOTS_REQUEST)),
        tap(action => {
            logger.log(`Get spots page: ${action.payload.page} perpage: ${action.payload.perPage}`)
        }),
        mergeMap(action => {
            const { page, perPage } = action.payload
            return from (
                adminApis.getPaginatedSpots(
                    page,
                    perPage
                )
            ).pipe(
                map(response => {
                    return  commonFeatureActions.cachePaginatedSpots(response.data.message as Pagination<Spot>)
                }),
                catchError((error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}


export const getPaginatedGames: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_PAGINATED_GAMES_REQUEST)),
        tap(action => {
            logger.log(`Get games page: ${action.payload.page} perpage: ${action.payload.perPage}`)
        }),
        mergeMap(action => {
            const { page, perPage } = action.payload
            return from (
                adminApis.getPaginatedGames(
                    page,
                    perPage
                )
            ).pipe(
                map(response => {
                    return  commonFeatureActions.cachePaginatedGames(response.data.message as Pagination<Game>)
                }),
                catchError((error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}
    



export const getUsers: Epic<CommonAction, CommonAction, RootState, Services> = (
    action$,
    state$,
    { logger }
) => {
    return action$.pipe(
        filter(isOfType(constants.GET_USERS_REQUEST)),
        tap(action => {
            logger.log(`Get users page: ${action.payload.page} perpage: ${action.payload.perPage}`)

        }),
        mergeMap(action => {
            const { page, perPage } = action.payload
            return from (
                adminApis.getPaginatedUsers(
                    page,
                    perPage
                )
            ).pipe(
                map(response => {
                    // if (response.data.message != null)
                    return  commonFeatureActions.cacheUsersResult(response.data.message as Pagination<UserEntity>)
                    // else return ignoreElements()
                }),
                catchError((error: AxiosError) => {
                    return handleAxiosError(error)
                })
            )
        })
    )
}
    
