export const SHOW_MESSAGE = 'commonfeature/SHOW_MESSAGE'
export const CONSUME_MESSAGE = 'commonfeature/CONSUME_MESSAGE'
export const CONSUME_ALL_MESSAGES = 'commonfeature/CONSUME_ALL_MESSAGES'

// normally this is only used by UserPage
export const GET_USERS_REQUEST = 'commonfeature/GET_USERS_REQUEST'

export const CACHE_USERS_RESULT = 'commonfeature/CACHE_USERS_RESULT'

export const ERROR_GET_USERS = 'commonfeature/ERROR_GET_USERS'

export const CACHE_PAGINATED_GAMES = 'commonfeature/CACHE_PAGINATED_GAMES'

export const GET_PAGINATED_GAMES_REQUEST = 'commonfeature/GET_PAGINATED_GAMES_REQUEST'

export const ERROR_GET_PAGINATED_GAMES = 'commonfeature/GET_PAGINATED_GAMES'

export const GET_PAGINATED_SPOTS_REQUEST = 'commonfeature/GET_PAGINATED_SPOTS_REQUEST'

export const ERROR_GET_PAGINATED_SPOTS = 'commonfeature/ERROR_GET_PAGINATED_SPOTS'

export const CACHED_PAGINATED_SPOTS = 'commonfeature/CACHED_PAGINATED_SPOTS'


export const GET_PAGINATED_BUSINESSES_REQUEST = 'commonfeature/GET_PAGINATED_BUSINESSES_REQUEST'
export const ERROR_GET_PAGINATED_BUSINESSES = 'commonfeature/ERROR_GET_PAGINATED_BUSINESSES'
export const CACHE_PAGINATED_BUSINESSES = 'commonfeature/CACHE_PAGINATED_BUSINESSES'

export const GET_USER_REQUEST = 'commonfeature/GET_USER_REQUEST'
export const ERROR_GET_USER = 'commonfeature/ERROR_GET_USER'
export const CACHE_USER = 'commonfeature/CACHE_USER'

export const GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST = 'commonfeature/GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST'
export const ERROR_GET_PAGINATED_USER_SHIPPING_ORDERS = 'commonfeature/ERROR_PAGINATED_GET_USER_SHIPPING_ORDERS'
export const CACHE_PAGINATED_USER_SHIPPING_ORDERS = 'commonfeature/CACHE_PAGINATED_USER_SHIPPING_ORDERS'

export const GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST = 'commonfeature/GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST'
export const ERROR_GET_PAGINATED_USER_COUPON_OWNERSHIP = 'commonfeature/ERROR_GET_PAGINATED_USER_COUPON_OWNERSHIP'
export const CACHE_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST = 'commonfeature/CACHE_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST'

export const PATCH_SHIPPING_ORDER = 'commonfeature/PATCH_SHIPPING_ORDER'

// This should be fired as new shipping order is available
export const ON_SHIPPING_ORDER_EDITED = 'commonfeature/ON_SHIPPING_ORDER_EDITED'

export const UPDATE_APP_BAR_CONFIG = 'commonfeature/UPDATE_APP_BAR_CONFIG'

export const CREATE_LOCATION = 'commonfeature/CREATE_LOCATION'

export const GET_PAGINATED_GEOGRAPHIC_LOCATION = 'commomfeature/GET_PAGINATED_GEOGRAPHIC_LOCATION'
export const CACHE_PAGINATED_GEOGRAPHIC_LOCATION = 'commonfeature/CACHE_PAGINATED_GEOGRAPHIC_LOCATION'
export const ERROR_GET_PAGINATED_GEOGRAPHIC_LOCATION = 'commonfeature/ERROR_GET_PAGINATED_GEOGRAPHIC_LOCATION'

export const CREATE_PHONE_NUMBER = 'commonfeature/CREATE_PHONE_NUMBER'

export const CREATE_GAME_BANNER = 'commonfeature/CREATE_GAME_BANNER'

export const CREATE_COUPON_BANNER = 'commonfeatuure/CREATE_COUPON_BANNER'

export const CREATE_MAJOR_BANNER = 'commonfeature/CREATE_MAJOR_BANNER'

export const CREATE_SPOT_BANNER = 'commonfeature/CREAET_SPOT_BANNER'

export const CREATE_BUSINESS = 'commonfeature/CREATE_BUSINESS'

export const CREATE_REWARD_BUNDLE = 'commonfeature/CREATE_REWARD_BUNDLE'

export const CREATE_COUPON = 'commonfeature/CREATE_COUPON'

export const CREATE_QR_CODE_ENTITY = 'commonfeature/CRAETE_QR_CODE_ENTITY'

export const GET_PAGINATED_PHONE_NUMBER = 'commomfeature/GET_PAGINATED_PHONE_NUMBER'
export const CACHE_PAGINATED_PHONE_NUMBER = 'commonfeature/CACHE_PAGINATED_PHONE_NUMBER'
export const ERROR_GET_PAGINATED_PHONE_NUMBER = 'commonfeature/ERROR_GET_PAGINATED_PHONE_NUMBER'

export const GET_PAGINATED_GAME_BANNER = 'commomfeature/GET_PAGINATED_GAME_BANNER'
export const CACHE_PAGINATED_GAME_BANNER = 'commonfeature/CACHE_PAGINATED_GAME_BANNER'
export const ERROR_GET_PAGINATED_GAME_BANNER = 'commonfeature/ERROR_GET_PAGINATED_GAME_BANNER'

export const GET_PAGINATED_COUPON_BANNER = 'commomfeature/GET_PAGINATED_COUPON_BANNER'
export const CACHE_PAGINATED_COUPON_BANNER = 'commonfeature/CACHE_PAGINATED_COUPON_BANNER'
export const ERROR_GET_PAGINATED_COUPON_BANNER = 'commonfeature/ERROR_GET_PAGINATED_COUPON_BANNER'

export const GET_PAGINATED_MAJOR_BANNER = 'commomfeature/GET_PAGINATED_MAJOR_BANNER'
export const CACHE_PAGINATED_MAJOR_BANNER = 'commonfeature/CACHE_PAGINATED_MAJOR_BANNER'
export const ERROR_GET_PAGINATED_MAJOR_BANNER = 'commonfeature/ERROR_GET_PAGINATED_MAJOR_BANNER'

export const GET_PAGINATED_SPOT_BANNER = 'commomfeature/GET_PAGINATED_SPOT_BANNER'
export const CACHE_PAGINATED_SPOT_BANNER = 'commonfeature/CACHE_PAGINATED_SPOT_BANNER'
export const ERROR_GET_PAGINATED_SPOT_BANNER = 'commonfeature/ERROR_GET_PAGINATED_SPOT_BANNER'

export const GET_PAGINATED_REWARD_BUNDLE = 'commomfeature/GET_PAGINATED_REWARD_BUNDLE'
export const CACHE_PAGINATED_REWARD_BUNDLE = 'commonfeature/CACHE_PAGINATED_REWARD_BUNDLE'
export const ERROR_GET_PAGINATED_REWARD_BUNDLE = 'commonfeature/ERROR_GET_PAGINATED_REWARD_BUNDLE'

export const GET_PAGINATED_COUPON = 'commomfeature/GET_PAGINATED_COUPON'
export const CACHE_PAGINATED_COUPON = 'commonfeature/CACHE_PAGINATED_COUPON'
export const ERROR_GET_PAGINATED_COUPON = 'commonfeature/ERROR_GET_PAGINATED_COUPON'

export const GET_PAGINATED_QR_CODE_ENTITY = 'commomfeature/GET_PAGINATED_QR_CODE_ENTITY'
export const CACHE_PAGINATED_QR_CODE_ENTITY = 'commonfeature/CACHE_PAGINATED_QR_CODE_ENTITY'
export const ERROR_GET_PAGINATED_QR_CODE_ENTITY = 'commonfeature/ERROR_GET_PAGINATED_QR_CODE_ENTITY'


export const ADD_TAGS_TO_SPOT = 'commonfeature/ADD_TAG_TO_SPOT'
export const REMOVE_TAGS_REMOVE_SPOT = 'commonfeature/REMOVE_TAGS_REMOVE_SPOT'

export const ADD_TAGS_TO_QR_CODE_ENTITY = 'commonfeature/ADD_TAGS_TO_QR_CODE_ENTITY'
export const REMOVE_TAGS_FROM_QR_CODE_ENTITY = 'commonfeature/REMOVE_TAGS_FROM_QR_CODE_ENTITY'

export const PATCH_SPOT = 'commonfeature/PATCH_SPOT'

export const GET_SPOT_RELATED_IDS = 'commonfeature/GET_SPOT_RELATED_IDS'
export const CACHE_SPOT_RELATED_IDS = 'commonfeature/CACHE_SPOT_RELATED_IDS'
export const ERROR_GET_SPOT_RELATED_IDS = 'commonfeature/ERROR_GET_SPOT_RELATED_IDS'

export const CREATE_ARTICLE = 'commonfeature/CREATE_ARTICLE'

export const PATCH_COUPON = 'commonfeature/patch_coupon'

export const PATCH_QR_CODE_ENTITY = 'commonfeature/patch_qr_code_entity'

export const PATCH_MAJOR_BANNER = 'commonfeature/patch_major_banner'

export const PATCH_SPOT_BANNER = 'commonfeature/patch_spot_banner'

export const PATCH_COUPON_BANNER = 'commonfeature/patch_coupon_banner'

export const PATCH_PRIZE_BANNER = 'commonfeatuere/patch_prize_banner'

export const GET_PAGINATED_PRIZE_BANNER = 'commomfeature/GET_PAGINATED_PRIZE_BANNER'
export const CACHE_PAGINATED_PRIZE_BANNER = 'commonfeature/CACHE_PAGINATED_PRIZE_BANNER'
export const ERROR_GET_PAGINATED_PRIZE_BANNER = 'commonfeature/ERROR_GET_PAGINATED_PRIZE_BANNER'

export const PATCH_GAME_BANNER = 'commonfeature/patch_game_banner'

export const GET_PAGINATED_PRIZE = 'commomfeature/GET_PAGINATED_PRIZE'
export const CACHE_PAGINATED_PRIZE = 'commonfeature/CACHE_PAGINATED_PRIZE'
export const ERROR_GET_PAGINATED_PRIZE = 'commonfeature/ERROR_GET_PAGINATED_PRIZE'

export const PATCH_PRIZE = 'commonfeature/patch_prize'
 
export const GET_PAGINATED_TAG = 'commomfeature/GET_PAGINATED_TAG'
export const CACHE_PAGINATED_TAG = 'commonfeature/CACHE_PAGINATED_TAG'
export const ERROR_GET_PAGINATED_TAG = 'commonfeature/ERROR_GET_PAGINATED_TAG'

export const CREATE_TAG = 'commonfeature/CREATE_TAG'

export const CREATE_PRIZE_BANNER = 'commonfeature/CREATE_PRIZE_BANNER'

export const PATCH_BUSINESS = 'commonfeature/PATCH_BUSINESS'

export const GET_PAGINATED_CREDIT_REWARD = 'commonfeature/GET_PAGINATED_CREDIT_REWARD'
export const CACHE_PAGINATED_CREDIT_REWARD = 'commonfeature/CACHE_PAGINATED_CREDIT_REWARD'
export const ERROR_GET_PAGINATED_CREDIT_REWARD = 'commonfeature/ERROR_GET_PAGINATED_CREDIT_REWARD'


export const SEND_DIALOG_COMMAND = 'commonfeature/SEND_DIALOG_COMMAND'

export const CREATE_RULE_CONDITION = 'commonfeature/CREATE_RULE_CONDITION'

export const CREATE_SCAN_RULE = 'commonfeature/CREATE_SCAN_RULE'

export const PATCH_RULE_CONDITION ='commonfeature/PATCH_RULE_CONDITION'

export const PATCH_SCAN_RULE = 'commonfeature/PATCH_SCAN_RULE'

export const GET_CLIENT_TASK_PAGE = 'commonfeature/GET_CLIENT_TASK_PAGE'
export const CACHE_CLIENT_TASK_PAGE = 'commonfeature/CACHE_CLIENT_TASK_PAGE';
export const ERROR_GET_CLIENT_TASK_PAGE = 'commonfeature/ERROR_GET_CLENT_TASK_PAGE'

export const GET_MIXED_BANNER_PAGE = 'commonfeature/GET_MIXED_BANNER_PAGE'
export const CACHE_MIXED_BANNER_PAGE = 'commonfeature/CACHE_MIXED_BANNER_PAGE'
export const ERROR_GET_MIXED_BANNER_PAGE = 'commonfeature/ERROR_GET_MIXED_BANNER_PAGE'

export const CREATE_MIXED_BANNER = 'commonfeature/CREATE_MIXED_BANNER'

export const PATCH_MIXED_BANNER ='commonfeature/PATCH_MIXED_BANNER'

export const PATCH_QR_PERIOD = 'commonfeature/PATCH_QR_PERIOD'