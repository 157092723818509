import * as H from 'history';
import { MyColumn } from '../../common/models';
import { GameBanner } from '../../api';
import React from 'react'


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal: MyColumn[] = [
        { title: 'Id', name: 'id', editable: false},
        { title: 'Prize Id', name: 'prizeId'},
        { title: 'Image Id', name: 'imageId'},
        { title: 'Tag', name: 'tag' },
        { title: 'image', name: 'image', getCellValue: (row: GameBanner) => {
            if (row.image != null) {
                return (
                    <a href={row.image.url}>Image</a>
                )
            }
        }},
    ]
    return normal
}