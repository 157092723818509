import React from 'react'
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';


type Props = {
    title: string,
    isAlertUser?: boolean
}

type State = {
    enValue?: string,
    zhValue?: string,
    zhHKValue?: string
}

class LanguageInput extends React.Component<Props, State> {
    state = {} as State

    hasUndefinedValue = () => {
        const { enValue, zhValue, zhHKValue } = this.state
        if (enValue == undefined || zhValue == undefined || zhHKValue == undefined) {
            return true
        }
        return false
    }

    getInputValues = ()=> {
        const { enValue, zhValue, zhHKValue } = this.state
        return {
            en: enValue,
            zh_Hant: zhValue,
            zh_Hant_HK: zhHKValue
        }
    }


    render() {
        const { isAlertUser } = this.props
        return (
            <div style={{
                'border': isAlertUser ? '1px solid red' : '1px solid black'
            }}>
                <Typography variant='h6'>
                    {this.props.title}
                </Typography>
                <TextField
                    id="en"
                    label="EN"
                    style={{ margin: 8 }}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                        this.setState({ enValue: event.target.value})
                    }}
                />

                <TextField
                    id="zh"
                    label="ZH"
                    style={{ margin: 8 }}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                        this.setState({ zhValue: event.target.value})
                    }}
                />

                <TextField
                    id="zh-hk"
                    label="ZH-HK"
                    style={{ margin: 8 }}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                        this.setState({ zhHKValue: event.target.value})
                    }}
                />
            </div>
        )
    }
}

export default LanguageInput