import { ChangeSet } from '@devexpress/dx-react-grid';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { UserEntity } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        marginTop: '3%',
        width: '100%'
    }
}
// type Props = {
//     classes: {
//         container: string,
//         paper: string
//     },
//     columns: Column[]
//     users?: UserEntity[] | null,
//     page?: number,
//     perPage?: number,
//     onPageSizeChange: (pageSize: number) => void,
//     onChangeCurrentPage: (page: number) => void,
//     totalCount?: number
// }

// type RealProps = RouteComponentProps & Props;


class UserDisplay extends BaseDisplay<UserEntity, BaseRealProps<UserEntity>> {

    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            console.log('UserDisplya onCommitEditig')
        }
    }
}

// class UserDisplay extends React.Component<RealProps> {

//     render() {
//         let { users, page, perPage, columns, onPageSizeChange, onChangeCurrentPage, totalCount} = this.props;
//         let { paper } = this.props.classes;
//         if (users == null) {
//             users = []
//         }
//         console.log('users', users)
//         return (
//             <Paper className={paper}>
//                 <Typography variant='h6'>
//                     User
//                 </Typography>
//                 <Grid
//                     rows={users}
//                       columns={columns}>
                    
//                     <PagingState
//                         defaultCurrentPage={0}
//                         defaultPageSize={10}
//                         currentPage={page}
//                         pageSize={perPage}
//                         onPageSizeChange={onPageSizeChange}
//                         onCurrentPageChange={onChangeCurrentPage}
//                     />
//                     <CustomPaging 
//                         totalCount={totalCount}
//                         />
//                     <Table />
//                     <TableHeaderRow />
//                     <PagingPanel 
//                         pageSizes={[1, 10, 50, 100]}
//                         />
//                 </Grid>
//             </Paper>
//         )
//     }
// }


export default withRouter(withStyles(styles)(UserDisplay))