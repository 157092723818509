import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Image } from '../../api/models';
import BaseDisplay from '../basedisplay/BaseDisplay';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

class ImageDisplay extends BaseDisplay<Image> {

}

export default withRouter(withStyles(styles)(ImageDisplay))