import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { BusinessBranch } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';

const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        paddingTop: '3%',
        marginTop: '3%',
        marginBottom: '3%'
    }
}

class BusinessBranchDisplay extends BaseDisplay<BusinessBranch, BaseRealProps<BusinessBranch>> {
    
}


export default withRouter(withStyles(styles)(BusinessBranchDisplay))