import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';


const styles = {
    top: {
        width: '100%',
        height: '100%',
        // alignSelf: 'stretch',
        // flex: '1',
        top: '0',
        // left: '0',
        // right: '0',
        // bottom: '0',
        background: 'rgba(0, 0, 0, 0.31)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    progress: {

    }
}

type Props =  {
    classes: {
        top: string
        progress: string
    }
}

class LoadingCover extends React.Component<Props> {
    render () {
        const { classes } = this.props
        return (
            <div className={classes.top} style={{position: 'absolute'}}>
                <CircularProgress className={classes.progress} />
            </div>
        )
    }
}

export default withStyles(styles)(LoadingCover);