import * as H from 'history';
import { MyColumn } from '../../common/models';
import React from 'react'
import { PayloadAC } from 'typesafe-actions';
import { Message } from '../../features/commonfeature/models';
import RuleConditionDisplay from '../rulecondtiondisplay/RuleConditionDisplay';
import { IconButton } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation'
import {getDefaultControlledConfig as getRuleConditoonConfig } from '../../components/rulecondtiondisplay/defaultconfig'
import { getColumns as getRuleConditionColumns } from '../../components/rulecondtiondisplay/columns'
import { ScanRule } from '../../api';

// TODO we need translations 

export const getColumns = (history: H.History, 
    showMessage: PayloadAC<"commonfeature/SHOW_MESSAGE", Message>|undefined=undefined , 
    navigationDeeper: boolean = true, showQRCodeEntitiyId: boolean = true): MyColumn[] => {
    let output: MyColumn[] = [
        { 'title': 'ID', name: 'id', editable: false },
        { title: 'Created at', name: 'createdAt' },
        { title: 'Start Time', name: 'startTime' },
        { title: 'End Time', name: 'endTime' },
        { title: 'Priority', name: 'priority' },
        { title: 'Reward Bundle Id', name: 'rewardBundleId' },
    ]

    if (showQRCodeEntitiyId) {
        output.push({ title: 'QR Code Entity Id', name: 'qrCodeEntityId' })
    }

    if (navigationDeeper) {
        output.splice(0, 0, 
            {
                name: 'Dialog',
                getCellValue: (row: any) => {
                    // TODO reutnr a rule condtion page
                    let _row = row as ScanRule
                    console.log('_row.ruleConditions', _row.ruleConditions)
                    let a = <RuleConditionDisplay
                                scanRuleId={row.id}
                                showAdd
                                showEdit
                                {...getRuleConditoonConfig(history)}
                                columns={getRuleConditionColumns(history)}
                                data={_row.ruleConditions}
                                />

                    return (
                        <IconButton onClick={() => {
                            if (showMessage != null) {
                                showMessage({
                                    message: 'Rule Condition',
                                    type: 'dialog',
                                    component: a
                                })
                            }
                        }}>
                            <NavigationIcon/>
                        </IconButton>
                    )
                }
            })
    }
    return output
}
