import * as H from 'history';
import { MyColumn } from '../../common/models';
import React from 'react'


export const getColumns =(history: H.History, editMode: boolean =false): MyColumn[] => {
    let normal: MyColumn[] = [
        { title: 'Id', name: 'id' },
        { title: 'Active', name: 'active', getCellValue: (row)=>{
            return row.active.toString()
        }},
        {
            title: 'Amount of Credit', name: 'amountOfCredit'
        },
        { title: 'Maximum Amount', name: 'maximumAmount' },
        { title: 'Name', name: 'name' }
    ]

    
    return normal
}