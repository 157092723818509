import { Column } from '@devexpress/dx-react-grid';
import * as H from 'history';
import { GamePrize } from '../../api';


export const getColumns = (history: H.History): Column[] => [
    { title: 'Id', name: 'id' },
    { title: 'Game Point', name: 'gamePoint'},
    { title: 'Maximum Amount', name: 'maximumAmount' },
    { name: 'Prize Id', getCellValue: (row: GamePrize) => {
        return row.prize.id
    }}
];

export default getColumns