import React from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureSelectors, commonFeatureActions } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Button, TextField, InputAdornment, IconButton, Chip } from '@material-ui/core';
import { connect } from 'react-redux';
import UserDisplay from '../../components/userdisplay';
import { Pagination, UserEntity } from '../../api/models'
import { getColumns as getUserColumns } from '../../components/userdisplay/columns'
import { getControlledConfig as getUserConfig } from '../../components/userdisplay/defaultconfig'
import RefreshIcon from '@material-ui/icons/Refresh';
import { fuzzySearchUser } from '../../api/admin'
import SearchIcon from '@material-ui/icons/Search';


const mapStateToProps = (state: MyTypes.RootState) => ({
    userData: commonFeatureSelectors.getUsersData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            getUsersRequest: commonFeatureActions.getUsersRequest,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            showMessage: commonFeatureActions.showMessage
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type MyState = {
    users: Array<UserEntity>,
    page: number,
    perPage: number,
    searchMode: boolean,
    searchTerm: string,
    searchResult?: Pagination<UserEntity>
}

class UserPage extends React.Component<RealProps> {
    state = {
        users : Array<UserEntity>(),
        page: 0,
        perPage: 10,
        searchMode: false,
        searchTerm: '',
        searchResult: null
    }

    searchRef: React.RefObject<HTMLInputElement>;

    constructor(props: RealProps) {
        super(props);
        this.searchRef = React.createRef();
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'User'
        })
        this.getNext()
    }

    getNext = ()=> {
        console.log(`UserPage getNext: page: ${this.state.page} perPage: ${this.state.perPage}`)
        const { searchMode, searchTerm } = this.state
        if (!searchMode) {
            this.props.getUsersRequest(
                {
                    page: this.state.page + 1,
                    perPage: this.state.perPage
                }
            )
        } else {
            fuzzySearchUser({
                page: this.state.page + 1,
                perPage: this.state.perPage,
                word: searchTerm
            }).then((resp) => {
                this.setState({
                    searchResult: resp.data.message
                })
            })
        }
        
    }

    componentDidUpdate() {
        // console.log('UserPage component Did update')
        // this.getNext()
    }

    onChangeCurrentPage = (currentPage: number) => {
        console.log('onChangeCurrentPage currentPage', currentPage)
        this.setState({
            page: currentPage
        }, ()=> {
            this.getNext()
        })
        
    }

    onPageSizeChange = (pageSize: number) => {
        console.log('onPageSizeChange pageSize', pageSize)
        this.setState({
            page: 0,
            perPage: pageSize
        }, ()=> {
            this.getNext()
        })
        
    }

    searchIt = () => {
        const searchValue = this!!.searchRef!!.current!!.value
        console.log('Search it !!', searchValue)

        if (searchValue == '') {
            this.props.showMessage({
                message: 'Search Term cannot be empty'
            })
            return
        }

        this.setState({
            page: 0,
            searchMode: true,
            searchTerm: searchValue
        }, ()=> {
            this.getNext()
        })
    }

    onRemoveSearchTerm = () => {
        this.setState({
            page: 0,
            searchMode: false,
            searchTerm: ''
        }, ()=> {
            this.getNext()
        })
    }

    render() {
        const { container, userData, history } = this.props;
        const { searchMode, searchResult, searchTerm, page, perPage } = this.state
        const _searchResult = searchResult as unknown as Pagination<UserEntity> | undefined;
        let users : UserEntity[] = []
        let totalCount = undefined

        if (searchMode && _searchResult != null) {
            users = _searchResult!!.items as UserEntity[]
            totalCount = _searchResult!!.total
        } else if (userData.cached != null && userData.cached.items != null) {
            users = userData.cached.items
            totalCount = userData.cached.total
            console.log('User total Count: ', totalCount)
        }
        return (
            <div className={container}>
                <div style={{
                    marginTop: '1vw',
                    marginBottom: '1vw'
                }}>
                    <Button
                        style={{margin: '1em'}}
                        onClick={this.getNext}
                        variant="contained"
                        color="primary"
                    ><RefreshIcon /> Refresh </Button>
                    <TextField
                        style={{marginLeft: '1vw'}}
                        label="Search"
                        id="searchIt"
                        inputRef={this.searchRef}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={this.searchIt}
                                    >
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }}
                        >

                    </TextField>
                    {
                        searchMode ? 
                        (
                        <Chip
                            label={searchTerm}
                            clickable
                            color="primary"
                            onDelete={this.onRemoveSearchTerm} />
                            ) 
                        :
                        null
                    }
                </div>
                <UserDisplay 
                    {...getUserConfig(history)}
                    isLoading={userData.loading}
                    columns={getUserColumns(history)} 
                    onCurrentPageChange={this.onChangeCurrentPage}
                    onPageSizeChange={this.onPageSizeChange}
                    totalCount={totalCount}
                    page={page}
                    perPage={perPage}
                    data={users}
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPage)))