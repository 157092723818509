import { withStyles } from '@material-ui/core';
import { ScanRule } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';



const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createScanRule: commonFeatureActions.createScanRule,
            patchScanRule: commonFeatureActions.patchScanRule
        },
        dispatch
    )
type ScanRuleProps = {
    qrCodeEntityId?: number | string
}


type MyProps<Data> = ScanRuleProps & BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class ScanRuleDisplay extends BaseDisplay<ScanRule, MyProps<ScanRule>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {


            const { added, changed} = changeSet
            let props = this as unknown as MyProps<ScanRule>
            if (added != null) {
                added.forEach(raw => {
                    let qrCodeEntityId = (this as any).qrCodeEntityId
                    if ( qrCodeEntityId != null) {
                        raw['qrCodeEntityId'] = qrCodeEntityId
                    }
                    props.createScanRule(raw)
                })
            }
            if (changed != null) {
                for (let rowIndex in changed) {
                    const _data = props.data
                    if (_data != null) {
                        const originalRow = _data[Number(rowIndex)]
                        const scanRuleId = originalRow.id
                        const raw = changed[rowIndex]
                        props.patchScanRule({
                            id: scanRuleId,
                            args: {...raw}
                        })
                    }
                    
                }
                
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ScanRuleDisplay)))