import { withStyles } from '@material-ui/core';
import { GameBanner } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createGameBanner: commonFeatureActions.createGameBanner,
            patchGameBanner: commonFeatureActions.patchGameBanner
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class GameBannerDisplay extends BaseDisplay<GameBanner, MyProps<GameBanner>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as MyProps<GameBanner>
            const { added, changed } = changeSet
            const data : any = (this as any).data
            if (changed != null) {
                for (let rowIndex in changed) {
                    let kv = changed[rowIndex];

                    const rowId = data[Number(rowIndex)].id;
                    console.log('game banner kv', kv);

                    (this as any).patchGameBanner({
                        gameBannerId: rowId,
                        args: { ...kv}
                    })
                }
            }
            if (added != null) {
                console.log('Adding Game Banner', added)
                added.forEach(raw => {
                    let published = raw['published'] == '1' || raw['published'] == 'true'
                    let gameId = raw['gameId']
                    let imageId = raw['imageId']
                    let url = raw['url']
                    props.createGameBanner({
                        published,
                        gameId,
                        imageId,
                        url
                    })
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(GameBannerDisplay)))