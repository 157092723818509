import { withStyles, Button } from '@material-ui/core';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
// import { Game } from '../../api/models';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { commonFeatureActions } from '../../features/commonfeature';
import { isNumber, handleApiError } from '../../utils';
import BusinessDisplay from '../../components/businessdisplay'
// import { getDefaultControlledConfig as getBusinessConfig } from '../../components/businessdisplay/defaultconfig'
import { Business, BusinessBranch } from '../../api';
import { getBusinessById } from '../../api/admin';
import { getDefaultControlledConfig as getBusinessConfig } from '../../components/businessdisplay/defaultconfig';
import BusinessBranchDisplay from '../../components/businessbranchdisplay'
import { getControlledConfig as getBusinessBranchConfig } from '../../components/businessbranchdisplay/defaultconfig'
import RefreshIcon from '@material-ui/icons/Refresh';

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig     
        },
        dispatch
    )


const styles = {
    container: {
        width: '100%'
    }
}

type Props = {
    match: match<{businessId: string;}>,
    container: string
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


type State = {
    business?: Business
}

class BusinessDetailPage extends React.Component<RealProps, State> {

    state = {

    } as State

    componentWillMount() {
        if (!isNumber(this.props.match.params.businessId)) {
            alert("Invalid Game Id")
            this.props.history.push('/')
        }
    }
    
    componentDidMount() {
        this.props.updateAppBarConfig({title: 'Business Detail'})
        if(this.idIsOk()) {
            this.fetchBusiness()
        }
    }

    idIsOk = ()=> {
        return isNumber(this.props.match.params.businessId)
    }

    fetchBusiness = ()=> {
        getBusinessById(this.props.match.params.businessId).then((resp)=> {
            const business = resp.data.message
            if (business != null) {
                this.setState({
                    business
                })
            }
        }).catch((error)=> {
            handleApiError(error, this.props.showMessage)
        })
    }

    getBusinessArray = (): Array<Business> => {
        const { business } = this.state
        if (business == undefined) {
            return []
        }
        return [business]
    }

    getBusinessBranchArray = (): Array<BusinessBranch> => {
        const { business } = this.state
        if (business == undefined) {
            return []
        }
        return business.businessBranches
    }

    render() {
        console.log('UserDetailPage props', this.props);
        const { container, 
            history, 
        } = this.props;
        return (
            <div className={container}>
                Business Detail
                <Button
                    style={{margin: '1em'}}
                    onClick={this.fetchBusiness}
                    variant="contained"
                    color="primary"
                ><RefreshIcon /> Refresh </Button>
                <BusinessDisplay
                    {...getBusinessConfig(history)}
                    data={this.getBusinessArray()}
                    />

                <BusinessBranchDisplay
                    {...getBusinessBranchConfig(history)}
                    data={this.getBusinessBranchArray()}
                    />
            </div>
        )
    }
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessDetailPage)))