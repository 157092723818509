import React, { useEffect, useState } from 'react';
import MyTypes from 'MyTypes'
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles, Typography, Button, Paper, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import MixedBannerDisplay from '../../components/mixedbannerdisplay';
import { getControlledConfig } from '../../components/mixedbannerdisplay/defaultconfig';
import { MixedBanner } from '../../api/models';

const mapStateToProps = (state: MyTypes.RootState) => ({
    data: commonFeatureSelectors.getMixedBannerTaskData(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {

            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            getMixedBannerPage: commonFeatureActions.getMixedBannerPage,
        },
        dispatch
    )


const styles = (theme: any) => ({
    container: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    }
})

type Props = {
    container: string,
    formControl: String
}

type RealProps = RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>



function MixedBannerPage (props: RealProps) {
    const { container, history, data } = props;

    const [addedRows, setAddedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState('initial');

    useEffect(()=> {
        props.updateAppBarConfig({ title: 'Mixed Banner' })
        
    })

    useEffect(()=> {
        console.log('on page or per Page changed')
        getNext() 
    }, [page, perPage, statusFilter])

    const onCurrentPageChange = (currentPage: number) => {
        console.log('onChange Current Page', currentPage)
        setPage(currentPage);
    }

    const getNext = () => {
        console.log('Client Task get Next', statusFilter)
        const filters: Array<String> = []
        if (statusFilter != null && statusFilter != '') {
            filters.push(`status=${statusFilter}`)
        }
        props.getMixedBannerPage({
            page: page + 1,
            perPage: perPage,
            filters
        })
    }

    const onPageSizeChange = (pageSize: number) => {
        setPerPage(pageSize);
    }
    return (
        
        <div className={container}>
            <Paper>
                <Grid container direction='row'>
                    <Grid item xs={2}>
                        <Button
                            style={{margin: '1em'}}
                            onClick={getNext}
                            variant="contained"
                            color="primary"
                        ><RefreshIcon /> Refresh </Button>
                    </Grid>        
                </Grid>
            </Paper>
            
            <MixedBannerDisplay
                {...getControlledConfig(history)}
                showAdd
                showEdit
                page={page}
                perPage={perPage}
                onPageSizeChange={onPageSizeChange}
                data={data.cached != null ? data.cached!!.items : Array<MixedBanner>()}
                onCurrentPageChange={onCurrentPageChange}
                totalCount={data.cached != null ? data.cached.total : undefined}
                >
            </MixedBannerDisplay>
        </div>
    )
}




export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(MixedBannerPage)))