import { withStyles } from '@material-ui/core';
import { CouponBanner } from '../../api/models';
import BaseDisplay, { BaseRealProps } from '../basedisplay/BaseDisplay';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { commonFeatureActions } from '../../features/commonfeature';


const styles = {
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginTop: '3%',
        paddingTop: '3%',
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            createCouponBanner: commonFeatureActions.createCouponBanner,
            patchCouponBanner: commonFeatureActions.patchCouponBanner
        },
        dispatch
    )

type MyProps<Data> = BaseRealProps<Data> & ReturnType<typeof mapDispatchToProps>

class CouponBannerDisplay extends BaseDisplay<CouponBanner, MyProps<CouponBanner>> {
    static defaultProps = {
        ...BaseDisplay.defaultProps,
        onCommitEditing: function(changeSet: ChangeSet) {
            const props = this as unknown as MyProps<CouponBanner>
            const { added, changed } = changeSet
            const data = (this as any).data
            if (changed != null) {
                for (let rowIndex in changed) {
                    let kv = changed[rowIndex]

                    const rowId = data[Number(rowIndex)].id;
                    console.log('coupon banner kv', kv);

                    (this as any).patchCouponBanner({
                        couponBannerId: rowId,
                        args: { ...kv }
                    });
                }
            }
            if (added != null) {
                // TODO change it to coupon banner
                console.log('Adding Coupon Banner', added)
                added.forEach(raw => {
                    let isCurrent = raw['isCurrent'] == '1' || raw['isCurrent'] == 'true'
                    let couponId = raw['couponId']
                    let imageId = raw['imageId']
                    console.log(`isCurrent: ${isCurrent} couponId: ${couponId} imageId: ${couponId}`)
                    props.createCouponBanner({
                        isCurrent,
                        couponId,
                        imageId
                    })
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(CouponBannerDisplay)))