import React, { RefObject, createRef } from 'react';
import { Paper, withStyles, TextField, Button } from '@material-ui/core';
import DropZone from '../../components/dropzone';
import LanguageInput from '../../components/languageinput';
import { uploadImage } from '../../api/admin';
import { handleApiError } from '../../utils';
import MyTypes from 'MyTypes'
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux'
import { commonFeatureActions } from '../../features/commonfeature';
import LoadingCover from '../../commonui/loadingcover'


const mapStateToProps = (state: MyTypes.RootState) => ({
    
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,    
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig    
        },
        dispatch
    )


// const absuluteType = typeof 'absolute'

/** @type {{top: React.CSSProperties}} */
const styles = {
    paper: {
        padding: '5px'
    },
    top: {
        // position: "absolute",
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.31)'
    }
}

type Props = {
    classes: {
        paper: string,
        top: string
    }
}

type State = {
    slugifiedName?: string,
    objKey?: string,
    file?: File,
    showLoading: Boolean
}

type RealProps = Props & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>


class UploadPhotoPage extends React.Component<RealProps, State> {
    state = {} as State

    nameTransInput: RefObject<LanguageInput> = createRef()

    componentDidMount() {
        this.props.updateAppBarConfig({
            title: 'Upload Page'
        })
    }

    onClickUpload = ()=> {
        // console.log('onClickUploaded', this.slugifiedName)
        const { file, slugifiedName, objKey } = this.state

        console.log('onClickUpload', slugifiedName, )
        const nameTransInput = this.nameTransInput.current

        let nameValues = undefined
        if (nameTransInput != null) {
            nameValues = nameTransInput.getInputValues()
            console.log('inputValues', nameValues)
        }

        if (objKey == null) {
            alert('Obj key cannot be null')
            return
        }

        if (file == null) {
            return
        }
        console.log(`nameValues: ${nameValues} objKey: ${objKey} slugifiedName: ${slugifiedName} file: ${file}`)
        // TODO call api
        this.setState({showLoading: true})
        uploadImage(slugifiedName, nameValues, objKey, file).then((resp)=> {
            console.log('upload image response: ', resp)
            const image = resp.data.message
            this.props.showMessage({
                message: `Uploaded Image id: ${image != null ? image.id : null}`
            })
        }).catch((error) => {
            handleApiError(error, this.props.showMessage)
        }).finally(()=> {
            this.setState({
                showLoading: false
            })
        })
    }


    onFilesAdded = (files: File[]) => {
        if (files.length > 0) {
            this.setState({
                file: files[0]
            })
        }
    } 

    render() {

        const { paper} = this.props.classes
        const { showLoading } = this.state
        return (
        <Paper className={paper}>
            {/* <div className={top} style={{position: 'absolute'}} ></div> */}
            { showLoading ? <LoadingCover/> : null }
            <h1>Upload a Photo</h1>
            
            <TextField
                id="slugified-name"
                label="Slugified Name"
                style={{ margin: 8 }}
                placeholder="caravan-is-awesome"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                    this.setState({ slugifiedName: event.target.value})
                }}
            />
            

            <LanguageInput
                title='Name'
                ref={this.nameTransInput}
                />


            <TextField
                required={true}
                id="Obj-Key"
                label="Obj Key"
                style={{ margin: 8 }}
                placeholder="/image/business/good"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                    this.setState({
                        objKey: event.target.value
                    })
                }}
                // InputProps={{
                //     ref: (ref: any) => {console.log('obj key ref', ref)}
                // }}
                
                // onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {
                //     this.setState({ slugifiedName: event.target.value})
                // }}
            />

            <DropZone
                onFileAdded={this.onFilesAdded}
                multiple={false}
            />

            <Button
                onClick={this.onClickUpload}>
                Upload
            </Button>
        </Paper>
        )
    }
}



export default connect(
    mapStateToProps,
    mapDispatchToProps)
    ( withStyles(styles)(UploadPhotoPage))